import React, { useState, useEffect, useRef, useMemo } from "react";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import { registerLicense } from "@syncfusion/ej2-base";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Sort,
  Filter,
  Edit,
  Toolbar,
  CommandColumn,
  Inject,
  ColumnMenu,
} from "@syncfusion/ej2-react-grids";

import { USStates } from "./../datasource";
import { onAuthStateChanged, getAuth } from "firebase/auth";
import partnerProfileService from "../../service/partner/PartnerProfileService";
import "./../style.css";
import {
  FaEdit,
  FaChevronDown,
  FaChevronUp,
  FaSave,
  FaTimes,
  FaPlus,
} from "react-icons/fa";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import {
  DropDownListComponent,
  CheckBoxSelection,
} from "@syncfusion/ej2-react-dropdowns";
import { MultiSelectComponent } from "@syncfusion/ej2-react-dropdowns";
import { formatDistanceToNow } from "date-fns";
import Employees from "../Employees";
import userService from "../../service/UserService";
import countiesData from "../../counties_list.json";
import { updateDoc, doc } from "firebase/firestore";
import { db, auth } from "../../firebase";

const PartnerProfile = ({ className = "" }) => {
  const [data, setData] = useState(null);
  const [userId, setUserId] = useState(null);
  const [partnerProfile, setPartnerProfile] = useState([]);
  const [partnerMembers, setPartnerMembers] = useState([]);
  const [partnerDisasters, setPartnerDisasters] = useState([]);
  const [disastersinvolvement, setDisastersinvolvement] = useState([]);
  const gridRefMembers = useRef(null);
  const gridRefDisasters = useRef(null);
  const [lastEditedTime, setLastEditedTime] = useState(null);
  const [lastPartnerEditedTime, setLastPartnerEditedTime] = useState(null);
  const [tempFormData, setTempFormData] = useState({});
  const [membersFormData, setMembersFormData] = useState(null);
  const [tempMembersFormData, setTempMembersFormData] = useState({});
  const [disastersFormData, setDisastersFormData] = useState(null);
  const [tempDisastersFormData, setTempDisastersFormData] = useState({});
  const [isEditMode, setIsEditMode] = useState(false);
  const [lastMembersEditedTime, setLastMembersEditedTime] = useState(null);
  const [disastersLastEditedTime, setDisastersLastEditedTime] = useState(null);
  const [servicesProvided, setServicesProvided] = useState([]);
  const [willingToPartner, setWillingToPartner] = useState(null);
  const [disasterUpdatedTime, setDisasterUpdatedTime] = useState(null);
  const [partner, setPartner] = useState({});
  const [isPersonalInfoVisible, setIsPersonalInfoVisible] = useState(true);
  const [counties, setCounties] = useState(
    countiesData.map((cd, i) => ({ id: i + 1, name: cd.County }))
  );
  const [requiredFields, setRequiredFields] = useState([]);
  const [isDisasterVisible, setIsDisasterVisible] = useState(true);

  const services = [
    { id: 1, name: "Funding" },
    { id: 2, name: "Debris Removal" },
    { id: 3, name: "Case Management" },
    { id: 5, name: "House Repair" },
    { id: 6, name: "House Rebuilds" },
    { id: 7, name: "Volunteer Labor" },
    { id: 8, name: "Spriritual/Mental Care" },
    { id: 6, name: "Donation of Goods" },
  ];

  const fields = { text: "name", value: "name" };

  const yesNoOptions = [
    { text: "Yes", value: "Yes" },
    { text: "No", value: "No" },
  ];

  const changeWilling = (e) => {
    setWillingToPartner(e.itemData.value);
  };

  useEffect(() => {
    const auth = getAuth();

    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUserId(currentUser.uid);
      } else {
        setData(null);
        console.log("No user is logged in");
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (userId) {
      fetchData();
    }
  }, [userId]);

  const getPartnerByUserId = async () => {
    const p = await partnerProfileService.getPartnerByUserId(userId);
    setPartner(p);
  };

  const fetchData = async () => {
    try {
      // Fetch partner profile data
      const userData = await userService.getUserById(userId);
      console.log("User Data:", userData);

      const partnerData = await partnerProfileService.getPartnerProfile(
        "users",
        userData.id,
        "organizations",
        userData.partner_id
      );

      if (partnerData) {
        // Set partner profile data
        setData(partnerData);
        setTempFormData(partnerData);
        setDisasterUpdatedTime(new Date(tempFormData.lastUpdatedDisasters));
        console.log("Time ", partnerData.lastUpdatedDisasters);

        // Handle last edited time
        if (partnerData.updated_at) {
          setLastEditedTime(partnerData.updated_at.toDate());
        } else {
          setLastEditedTime(null); // Handle case where updated_at is missing
        }
        console.log("Partner data: ", partnerData);

        // Set members data
        const membersData = partnerData.members || {};
        setMembersFormData(membersData);
        setTempMembersFormData(membersData);

        // Set disasters data
        const disastersData = partnerData.disasters || {};
        setDisastersFormData(disastersData);
        setTempDisastersFormData(disastersData);

        // Handle last partner edited time
        if (membersData.updated_at) {
          setLastPartnerEditedTime(membersData.updated_at.toDate());
        } else {
          setLastPartnerEditedTime(null);
        }
        console.log("Members data: ", membersData);

        // Fetch partner members data
        const partnerMembersData = await partnerProfileService.getMembers(
          userId
        );
        setPartnerMembers(partnerMembersData);

        // Fetch partner disasters data
        const partnerDisastersData = await partnerProfileService.getDisasters(
          userId
        );
        setPartnerDisasters(partnerDisastersData);
        console.log("Disasters data: ", partnerDisastersData);

        // get data from partners table instead of user
        await getPartnerByUserId();
        console.log(partner);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  const startEditing = (event) => {
    event.preventDefault();
    setTempFormData({ ...data });
    setIsEditMode(true);
    console.log("Edit");
  };

  const saveChanges = async (event) => {
    event.preventDefault();

    const {
      address2,
      a501c3,
      ein,
      county,
      profile_completed,
      name_of_hub,
      ...rest
    } = tempFormData;

    const checkRequiredFields =
      Object.values(rest).some((val) => !val) ||
      !county.length ||
      (a501c3 === "Yes" ? !ein : !a501c3);

    if (checkRequiredFields) {
      let requiredFields = Object.entries(tempFormData)
        .filter(([key]) => key !== "address2")
        .map(([key, val]) =>
          ((key === "county" || key === "servicesProvided") &&
            key !== "name_of_hub" &&
            !val.length) ||
          !val
            ? key
            : null
        )
        .filter((val) => !!val);

      if (!tempFormData["servicesProvided"]) {
        requiredFields = [...requiredFields, "servicesProvided"];
      }

      console.log("Required Fields", requiredFields);

      setRequiredFields(() => requiredFields);
      return;
    }

    console.log("Valid");

    setRequiredFields(() => []);

    const currentTime = new Date();
    const updatedData = {
      ...tempFormData,
      updated_at: currentTime,
    };

    console.log("Saving changes with data: ", updatedData);

    try {
      //Update in users collection
      await partnerProfileService.updateProfileComplete("users", userId);
      if (data.id && data.organization) {
        await partnerProfileService.updatePartnerProfile(
          "users",
          userId,
          "organizations",
          data.id,
          updatedData
        );
        console.log("data.id", data.id);
      }
      //Update in partners collection
      if (data.id) {
        await updateDoc(doc(db, "partners", data.id), updatedData);
      }

      // Check if all required fields are filled before marking profile as complete
      const requiredFields = [
        "name_of_org",
        "first_name",
        "last_name",
        "mobile_number",
        "address1",
        "city",
        "state",
        "zip",
        "county",
        "area_serviced_county",
        "servicesProvided",
        "a501c3",
      ];
      const isProfileComplete = requiredFields.every(
        (field) => updatedData[field]
      );
      if (isProfileComplete) {
        console.log("Profile is complete");
        await partnerProfileService.updateProfileComplete("users", userId, {
          profile_completed: true,
        });
      } else {
        console.log("Profile is not complete");
        await partnerProfileService.updateProfileComplete("users", userId, {
          profile_completed: false,
        });
      }
      setData(updatedData);
      setIsEditMode(false);
      setLastEditedTime(currentTime);
      console.log("Changes saved successfully.");
    } catch (error) {
      console.error("Error updating profile: ", error);
    }
  };

  const inputBlur = async (event) => {
    const currentTime = new Date();
    const updatedData = {
      ...tempFormData,
      updated_at: currentTime,
    };

    console.log("Saving changes with data: ", updatedData);

    try {
      await partnerProfileService.updatePartnerProfile(
        "users",
        userId,
        "organizations",
        data.id,
        updatedData
      );
      setData(updatedData);
      setLastEditedTime(currentTime);
      console.log("Changes saved successfully.");
    } catch (error) {
      console.error("Error updating profile: ", error);
    }
  };

  const onShowRequiredFields = useMemo(
    () => (fields) => {
      return !!requiredFields.find((rf) => rf === fields);
    },
    [requiredFields]
  );

  const cancelEditing = (event) => {
    event.preventDefault();
    setTempFormData({ ...data });
    setIsEditMode(false);
    setRequiredFields(() => []);
  };

  const generateUniqueID = () => {
    return "_" + Math.random().toString(36).substr(2, 9);
  };

  const handleAddPartnerProfile = () => {
    if (gridRefMembers.current) {
      gridRefMembers.current.addRecord();
    }
  };

  const handleAddDisaster = () => {
    if (gridRefDisasters.current) {
      gridRefDisasters.current.addRecord();
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTempFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const actionBegin = async (args) => {
    if (args.requestType === "save") {
      if (args.action === "add") {
        // Ensure memberID is set and status is true
        const newMemberData = {
          ...args.data,
          memberId: args.data.memberId || generateUniqueID(),
          is_deleted: false, // Set status to true when adding
        };
        console.log("Adding new member:", newMemberData);
        await partnerProfileService.addMember(userId, newMemberData);
      } else if (args.action === "edit") {
        await partnerProfileService.updateMember(
          userId,
          args.data.id,
          args.data
        );
      }
      setLastMembersEditedTime(new Date());
      fetchData(); // Refresh the data after adding or editing
    } else if (args.requestType === "delete") {
      // Set status to false when deleting
      const updatedData = {
        ...args.data[0],
        is_deleted: true,
      };
      await partnerProfileService.updateMember(
        userId,
        updatedData.id,
        updatedData
      );
      setLastMembersEditedTime(new Date());
      fetchData(); // Refresh the data after deleting
    } else if (args.requestType === "beginEdit" || args.requestType === "add") {
      if (args.dialog) {
        const dialog = args.dialog;
        dialog.header =
          args.requestType === "beginEdit" ? "Edit Member" : "Add New Member";

        // Set the header styles
        if (dialog.element) {
          const headerContent = dialog.element.querySelector(
            ".e-dlg-header-content"
          );
          const header = dialog.element.querySelector(".e-dlg-header");
          if (headerContent) {
            headerContent.style.backgroundColor = "#348BFF";
            headerContent.style.color = "#FFFFFF";
          }
          if (header) {
            header.style.color = "#FFFFFF";
          }

          // Style the save and cancel buttons
          setTimeout(() => {
            const saveButton = dialog.element.querySelector(".e-primary");
            const cancelButton = dialog.element.querySelector(
              ".e-btn:not(.e-primary)"
            );

            if (saveButton) {
              saveButton.style.backgroundColor = "#FFFFFF";
              saveButton.style.color = "#348BFF";
              saveButton.style.border = "none";
            }

            if (cancelButton) {
              cancelButton.style.backgroundColor = "#FFFFFF";
              cancelButton.style.color = "#348BFF";
              cancelButton.style.border = "1px solid #348BFF";
            }
          }, 0);
        }
      }
    }
  };

  const actionBeginDisaster = async (args) => {
    if (args.requestType === "save") {
      if (args.action === "add") {
        // Ensure disasterId is set and status is true
        const newDisasterData = {
          ...args.data,
          disasterId: args.data.disasterId || generateUniqueID(),
          is_deleted: false, // Set status to true when adding
        };
        console.log("Adding new disaster:", newDisasterData);
        await partnerProfileService.addDisasters(userId, newDisasterData);
      } else if (args.action === "edit") {
        await partnerProfileService.updateDisasters(
          userId,
          args.data.id,
          args.data
        );
      }
      setDisastersLastEditedTime(new Date());
      fetchData(); // Refresh the data after adding or editing
    } else if (args.requestType === "delete") {
      // Set status to false when deleting
      const updatedData = {
        ...args.data[0],
        is_deleted: true,
      };
      await partnerProfileService.updateDisasters(
        userId,
        updatedData.id,
        updatedData
      );
      setDisastersLastEditedTime(new Date());
      fetchData(); // Refresh the data after deleting
    } else if (args.requestType === "delete") {
      // Set status to false when deleting
      await partnerProfileService.deleteDisaster(userId, args.data[0].id);
      setDisastersLastEditedTime(new Date());
    } else if (args.requestType === "beginEdit" || args.requestType === "add") {
      if (args.dialog) {
        const dialog = args.dialog;
        dialog.header =
          args.requestType === "beginEdit"
            ? "Edit Disaster Info"
            : "Add Disaster Info";

        // Set the header styles
        if (dialog.element) {
          const headerContent = dialog.element.querySelector(
            ".e-dlg-header-content"
          );
          const header = dialog.element.querySelector(".e-dlg-header");
          if (headerContent) {
            headerContent.style.backgroundColor = "#348BFF";
            headerContent.style.color = "#FFFFFF";
          }
          if (header) {
            header.style.color = "#FFFFFF";
          }

          // Style the save and cancel buttons
          setTimeout(() => {
            const saveButton = dialog.element.querySelector(".e-primary");
            const cancelButton = dialog.element.querySelector(
              ".e-btn:not(.e-primary)"
            );

            if (saveButton) {
              saveButton.style.backgroundColor = "#FFFFFF";
              saveButton.style.color = "#348BFF";
              saveButton.style.border = "none";
            }

            if (cancelButton) {
              cancelButton.style.backgroundColor = "#FFFFFF";
              cancelButton.style.color = "#348BFF";
              cancelButton.style.border = "1px solid #348BFF";
            }
          }, 0);
        }
      }
    }
  };

  const sortSettings = {
    allowSorting: true,
  };
  const filterSettings = {
    type: "CheckBox",
  };

  const togglePersonalInfo = (event) => {
    event.preventDefault();
    setIsPersonalInfoVisible(!isPersonalInfoVisible);
  };

  const toggleDisaster = () => {
    setIsDisasterVisible(!isDisasterVisible);
  };

  const columnMenuItems = ["SortAscending", "SortDescending", "Filter"];

  const pageSettings = { pageSize: 10 };

  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const isNewlyRegistered = localStorage.getItem("newlyRegistered");
    if (isNewlyRegistered === "true") {
      setShowPopup(true);
      localStorage.removeItem("newlyRegistered"); // Remove the flag after showing the popup
    }
  }, []);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const dialogContent = () => {
    return (
      <div style={{ textAlign: "center", padding: "20px" }}>
        <h2
          style={{
            fontSize: "24px",
            fontWeight: "normal",
            color: "#1e1919",
            marginBottom: "10px",
          }}
        >
          Welcome to your Partner Profile!
        </h2>
        <p style={{ fontSize: "16px", color: "#637381", marginBottom: "20px" }}>
          Please complete your profile to maximize our partnership benefits.
        </p>
        <button
          onClick={handleClosePopup}
          className="btn-default"
          style={{
              padding: "10px 20px",
              cursor: "pointer",
          }}
        >
          Got it!
        </button>
      </div>
    );
  };

  return (
    <div
      className={`box-border flex w-[1648px] max-w-full flex-col items-start justify-start gap-2.5 px-2.5 py-[15px] text-left text-xs leading-[normal] tracking-[normal] text-darkslateblue ${className}`}
    >
      <div id="dialog-container"></div>
      <div className="flex flex-row items-start justify-start gap-1.5 py-0 pl-0 pr-5">
        <a className="relative inline-block min-w-[77px] text-left  text-xs font-medium leading-[16px] text-dodgerblue [text-decoration:none]">
          Partner Profile
        </a>
      </div>
      <section className="flex max-w-full flex-col items-start justify-start self-stretch bg-white">
        <header className="flex flex-row items-center justify-between self-stretch">
          <div>
            <h2 className="text-sm font-bold text-blue-500">
              PERSONAL INFORMATION
            </h2>
          </div>
          <div className="flex items-center gap-2">
            {isEditMode ? (
              <>
                <button
                  type="button"
                  className="btn-default flex cursor-pointer items-center gap-1 px-3 py-1.5"
                  onClick={saveChanges}
                >
                  <FaSave className="h-3 w-3" />
                  Save
                </button>
                <button
                  type="button"
                  className="btn-cancel flex cursor-pointer  gap-1 px-3 py-1.5"
                  onClick={cancelEditing}
                >
                  <FaTimes className="h-3 w-3" />
                  Cancel
                </button>
              </>
            ) : (
              <button
                type="button"
                className="btn-default flex cursor-pointer gap-1 px-3 py-1.5"
                onClick={startEditing}
              >
                <FaEdit className="h-3 w-3" />
                Edit
              </button>
            )}
            <button
              type="button"
              className="flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-gray-200"
              onClick={togglePersonalInfo}
            >
              <span
                className={`transition-transform duration-300 ease-in-out ${
                  isPersonalInfoVisible ? "rotate-0" : "rotate-180"
                }`}
              >
                <FaChevronDown />
              </span>
            </button>
          </div>
        </header>
        {isPersonalInfoVisible && (
        <div
          className={`flex w-full max-w-full flex-col items-start justify-start gap-0.5 text-left text-xs leading-[normal] tracking-[normal] text-gray-500 ${className}`}
        >
          <div className="flex max-w-full flex-col items-start justify-start gap-1.5 self-stretch">
            <div className="relative inline-block min-w-[107px] leading-[20px] text-gray1-200">
              <label htmlFor="organizationName">Organization Name</label>
              <p className="absolute right-[-15px] top-[4px] text-[26px] text-red-500">
                *
              </p>
            </div>
            <TextBoxComponent
              readOnly={!isEditMode}
              id="organizationName"
              type="text"
              cssClass="e-outline"
              style={{ backgroundColor: isEditMode ? "" : "#f3f4f6" }}
              value={tempFormData.name_of_org || ""}
              blur={inputBlur}
              onChange={(e) =>
                setTempFormData({
                  ...tempFormData,
                  name_of_org: e.target.value,
                })
              }
            />
            {onShowRequiredFields("name_of_org") && (
              <div className="relative w-[100px] font-inter leading-[12px] text-red-500">
                Required Field
              </div>
            )}
          </div>
          <div className="flex w-full gap-5">
            <div className="flex flex-1 flex-col items-start justify-start gap-1.5 self-stretch">
              <div className="relative inline-block min-w-[107px] leading-[20px] text-gray1-200">
                <label htmlFor="organizationName">
                  Contact Person First Name
                </label>
                <p className="absolute right-[-15px] top-[4px] text-[26px] text-red-500">
                  *
                </p>
              </div>
              <TextBoxComponent
                readOnly={!isEditMode}
                id="organizationName"
                type="text"
                cssClass="e-outline"
                style={{ backgroundColor: isEditMode ? "" : "#f3f4f6" }}
                value={tempFormData.first_name || ""}
                blur={inputBlur}
                onChange={(e) =>
                  setTempFormData({
                    ...tempFormData,
                    first_name: e.target.value,
                  })
                }
              />
              {onShowRequiredFields("first_name") && (
                <div className="relative w-[100px] font-inter leading-[12px] text-red-500">
                  Required Field
                </div>
              )}
            </div>
            <div className="flex flex-1 flex-col items-start justify-start gap-1.5 self-stretch">
              <div className="relative inline-block min-w-[107px] leading-[20px] text-gray1-200">
                <label htmlFor="organizationName">
                  Contact Person Last Name
                </label>
                <p className="absolute right-[-15px] top-[4px] text-[26px] text-red-500">
                  *
                </p>
              </div>
              <TextBoxComponent
                readOnly={!isEditMode}
                id="organizationName"
                type="text"
                cssClass="e-outline"
                style={{ backgroundColor: isEditMode ? "" : "#f3f4f6" }}
                value={tempFormData.last_name || ""}
                blur={inputBlur}
                onChange={(e) =>
                  setTempFormData({
                    ...tempFormData,
                    last_name: e.target.value,
                  })
                }
              />
              {onShowRequiredFields("last_name") && (
                <div className="relative w-[100px] font-inter leading-[12px] text-red-500">
                  Required Field
                </div>
              )}
            </div>
          </div>
          <div className="flex max-w-full flex-col items-start justify-start gap-1.5 self-stretch">
            <div className="relative inline-block min-w-[81px] leading-[20px] text-gray1-200">
              <div className="relative inline-block leading-[20px] text-gray1-200">
                <label htmlFor="officeNumber">Contact Number</label>
                <p className="absolute right-[-15px] top-[4px] text-[26px] text-red-500">
                  *
                </p>
              </div>
            </div>
            <TextBoxComponent
              readOnly={!isEditMode}
              style={{ backgroundColor: isEditMode ? "" : "#f3f4f6" }}
              id="officeNumber"
              type="number"
              cssClass="e-outline"
              value={tempFormData.mobile_number || ""}
              blur={inputBlur}
              onChange={(e) =>
                setTempFormData({
                  ...tempFormData,
                  mobile_number: e.target.value,
                })
              }
            />
            {onShowRequiredFields("mobile_number") && (
              <div className="relative w-[100px] font-inter leading-[12px] text-red-500">
                Required Field
              </div>
            )}
          </div>
          <div className="flex w-full gap-5">
            <div className="flex flex-1 flex-col items-start justify-start gap-1.5 self-stretch">
              <div className="relative inline-block text-gray1-200">
                <label htmlFor="address1">Area Serviced (State)</label>
                <p className="absolute right-[-15px] top-[-4px] text-[26px] text-red-500">
                  *
                </p>
              </div>
              <DropDownListComponent
                readonly={!isEditMode}
                floatLabelType="Auto"
                dataSource={USStates}
                fields={{ text: "name", value: "abbreviation" }}
                value={tempFormData.state || ""}
                style={{ backgroundColor: isEditMode ? "" : "#f3f4f6" }}
                name="state"
                change={(e) => {
                  setTempFormData({ ...tempFormData, state: e.value });
                  inputBlur();
                  const selectedState = e.value;
                  const getState = USStates.find(
                    (s) => s.abbreviation === selectedState
                  ).name;
                  const filteredCounties = countiesData
                    .filter((cd) => cd.State === getState)
                    .map((cd, i) => ({ id: i + 1, name: cd.County }));
                  setCounties(() => filteredCounties);
                }}
                cssClass="e-outline"
              />
              {onShowRequiredFields("state") && (
                <div className="relative w-[100px] font-inter leading-[12px] text-red-500">
                  Required Field
                </div>
              )}
            </div>
            <div className="flex flex-1 flex-col items-start justify-start gap-1.5 self-stretch">
              <div className="relative inline-block text-gray1-200">
                <label htmlFor="address1">Area Serviced (County)</label>
                <p className="absolute right-[-15px] top-[-4px] text-[26px] text-red-500">
                  *
                </p>
              </div>
              <MultiSelectComponent
                readonly={!isEditMode}
                dataSource={counties}
                fields={fields}
                value={tempFormData.area_serviced_county || ""}
                change={(e) => {
                  setTempFormData({
                    ...tempFormData,
                    area_serviced_county: e.value,
                  });
                }}
                mode="CheckBox"
                selectAllText="Select All"
                unSelectAllText="Unselect All"
                showSelectAll={true}
                cssClass={`e-outline ${isEditMode ? "" : "disabled-multiselect"}`}
                style={{
                  fontWeight: isEditMode ? "normal" : "bold",
                  maxHeight: "150px", // Set a fixed height
                  overflowY: "auto",  // Enable vertical scrolling
                }}
                showDropDownIcon={true}
                enableSelectionOrder={false}
              >
                <Inject services={[CheckBoxSelection]} />
              </MultiSelectComponent>
              {onShowRequiredFields("county") && (
                <div className="relative w-[100px] font-inter leading-[12px] text-red-500">
                  Required Field
                </div>
              )}
            </div>
          </div>
          <div className="flex w-full gap-5">
            <div className="flex flex-1 flex-col items-start justify-start gap-1.5 self-stretch">
              <div className="relative inline-block text-gray1-200">
                <label htmlFor="address1">
                  Are you a state or a national nonprofit
                </label>
                <p className="absolute right-[-15px] top-[-4px] text-[26px] text-red-500">
                  *
                </p>
              </div>
              <DropDownListComponent
                readonly={!isEditMode}
                floatLabelType="Auto"
                dataSource={yesNoOptions}
                fields={{ text: "text", value: "value" }}
                value={tempFormData.state_or_national_nonprofit || ""}
                style={{ backgroundColor: isEditMode ? "" : "#f3f4f6" }}
                cssClass="e-outline"
                onChange={(e) => {
                  setTempFormData((prev) => ({
                    ...prev,
                    state_or_national_nonprofit: e.value,
                  }));
                  inputBlur();
                }}
              />
              {onShowRequiredFields("state_or_national_nonprofit") && (
                <div className="relative w-[100px] font-inter leading-[12px] text-red-500">
                  Required Field
                </div>
              )}
            </div>
            <div className="flex flex-1 flex-col items-start justify-start gap-1.5 self-stretch">
              <div className="relative inline-block text-gray1-200">
                <label htmlFor="address1">Are you a 501c3</label>
                <p className="absolute right-[-15px] top-[-4px] text-[26px] text-red-500">
                  *
                </p>
              </div>
              <DropDownListComponent
                readonly={!isEditMode}
                floatLabelType="Auto"
                dataSource={yesNoOptions}
                fields={{ text: "text", value: "value" }}
                value={tempFormData.a501c3 || ""}
                style={{ backgroundColor: isEditMode ? "" : "#f3f4f6" }}
                cssClass="e-outline"
                onChange={(e) => {
                  setTempFormData((prev) => ({ ...prev, a501c3: e.value }));
                  inputBlur();
                }}
              />
              {onShowRequiredFields("a501c3") && (
                <div className="relative w-[100px] font-inter leading-[12px] text-red-500">
                  Required Field
                </div>
              )}
            </div>
            {tempFormData.a501c3 === "Yes" && (
              <div className="flex flex-1 flex-col items-start justify-start gap-1.5 self-stretch">
                <div className="relative inline-block text-gray1-200">
                  <label htmlFor="ein">EIN (Number Only)</label>
                  <p className="absolute right-[-15px] top-[-4px] text-[26px] text-red-500">
                    *
                  </p>
                </div>
                <TextBoxComponent
                  readOnly={!isEditMode}
                  floatLabelType="Auto"
                  id="ein"
                  type="number"
                  cssClass="e-outline"
                  style={{ backgroundColor: isEditMode ? "" : "#f3f4f6" }}
                  value={tempFormData.ein || ""}
                  onChange={(e) => {
                    setTempFormData((prev) => ({
                      ...prev,
                      ein: e.target.value,
                    }));
                  }}
                />
                {onShowRequiredFields("ein") && (
                  <div className="relative w-[100px] font-inter leading-[12px] text-red-500">
                    Required Field
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="flex max-w-full flex-col items-start justify-start gap-1.5 self-stretch">
            <div className="relative inline-block text-gray1-200">
              <label htmlFor="address1">Services Provided</label>
              <p className="absolute right-[-15px] top-[-4px] text-[26px] text-red-500">
                *
              </p>
            </div>
            <MultiSelectComponent
              readonly={!isEditMode}
              dataSource={services}
              fields={fields}
              value={tempFormData.servicesProvided || ""}
              change={(e) =>
                setTempFormData({
                  ...tempFormData,
                  servicesProvided: e.value,
                })
              }
              mode="CheckBox"
              selectAllText="Select All"
              unSelectAllText="Unselect All"
              showSelectAll={true}
              cssClass={`e-outline ${isEditMode ? "" : "disabled-multiselect"}`}
              style={{ fontWeight: isEditMode ? "normal" : "bold" }}
            >
              <Inject services={[CheckBoxSelection]} />
            </MultiSelectComponent>
            {onShowRequiredFields("servicesProvided") && (
              <div className="relative w-[100px] font-inter leading-[12px] text-red-500">
                Required Field
              </div>
            )}
          </div>
          <div className="flex max-w-full flex-col items-start justify-start gap-1.5 self-stretch">
            <div
              className="mb-1 py-2 text-xs font-bold text-black"
              style={{ color: "rgb(25, 56, 97)" }}
            >
              BUSINESS ADDRESS
            </div>
            <div className="relative inline-block  text-gray1-200">
              <label htmlFor="address1">Address 1</label>
              <p className="absolute right-[-15px] top-[-4px] text-[26px] text-red-500">
                *
              </p>
            </div>
            <TextBoxComponent
              readOnly={!isEditMode}
              floatLabelType="Auto"
              id="address1"
              type="text"
              cssClass="e-outline"
              style={{
                backgroundColor: isEditMode ? "" : "#f3f4f6",
              }}
              value={tempFormData.address1 || ""}
              blur={inputBlur}
              onChange={(e) =>
                setTempFormData({
                  ...tempFormData,
                  address1: e.target.value,
                })
              }
            />
            {onShowRequiredFields("address1") && (
              <div className="relative w-[100px] font-inter leading-[12px] text-red-500">
                Required Field
              </div>
            )}
          </div>
          <div className="flex max-w-full flex-col items-start justify-start gap-1.5 self-stretch">
            <div className="relative inline-block min-w-[55px] leading-[20px] text-gray1-200">
              <label htmlFor="address2">Address 2</label>
            </div>
            <TextBoxComponent
              readOnly={!isEditMode}
              floatLabelType="Auto"
              id="address1"
              type="text"
              cssClass="e-outline"
              value={tempFormData.address2 || ""}
              style={{ backgroundColor: isEditMode ? "" : "#f3f4f6" }}
              blur={inputBlur}
              onChange={(e) =>
                setTempFormData({
                  ...tempFormData,
                  address2: e.target.value,
                })
              }
            />
          </div>
          <div className="flex max-w-full flex-row flex-wrap items-center justify-start gap-5 self-stretch">
            <div className="mq391:min-w-full flex min-w-[351px] max-w-full flex-1 flex-col items-start justify-start gap-1.5">
              <div className="relative inline-block min-w-[22px] leading-[20px] text-gray1-200">
                <label htmlFor="city">City</label>
                <p className="absolute right-[-15px] top-[4px] text-[26px] text-red-500">
                  *
                </p>
              </div>
              <TextBoxComponent
                readOnly={!isEditMode}
                floatLabelType="Auto"
                className="bg-gray-200"
                cssClass="e-outline"
                id="city"
                type="text"
                value={tempFormData.city || ""}
                style={{ backgroundColor: isEditMode ? "" : "#f3f4f6" }}
                blur={inputBlur}
                onChange={(e) =>
                  setTempFormData({ ...tempFormData, city: e.target.value })
                }
              />
              {onShowRequiredFields("city") && (
                <div className="relative w-[100px] font-inter leading-[12px] text-red-500">
                  Required Field
                </div>
              )}
            </div>
            <div className="mq391:min-w-full flex min-w-[351px] max-w-full flex-1 flex-col items-start justify-start gap-1.5">
              <div className="relative inline-block min-w-[76px] leading-[20px] text-gray1-200">
                <label htmlFor="state">Select State</label>
                <p className="absolute right-[-15px] top-[4px] text-[26px] text-red-500">
                  *
                </p>
              </div>
              <DropDownListComponent
                readonly={!isEditMode}
                floatLabelType="Auto"
                dataSource={USStates}
                fields={{ text: "name", value: "abbreviation" }}
                value={tempFormData.state || ""}
                style={{ backgroundColor: isEditMode ? "" : "#f3f4f6" }}
                name="state"
                change={(e) => {
                  setTempFormData({ ...tempFormData, state: e.value });
                  inputBlur();
                }}
                cssClass="e-outline"
              />
              <div className="relative hidden w-[68px] font-inter leading-[12px]">
                Sample text
              </div>
            </div>
            <div className="mq391:min-w-full flex min-w-[351px] max-w-full flex-1 flex-col items-start justify-start gap-1.5 text-gray1-200">
              <div className="relative inline-block min-w-[18px] leading-[20px]">
                <label htmlFor="zip">Zip</label>
                <p className="absolute right-[-15px] top-[4px] text-[26px] text-red-500">
                  *
                </p>
              </div>
              <TextBoxComponent
                readOnly={!isEditMode}
                floatLabelType="Auto"
                className="bg-gray-200"
                cssClass="e-outline"
                id="country"
                type="text"
                value={tempFormData.zip || ""}
                style={{ backgroundColor: isEditMode ? "" : "#f3f4f6" }}
                blur={inputBlur}
                onChange={(e) =>
                  setTempFormData({ ...tempFormData, zip: e.target.value })
                }
              />
              {onShowRequiredFields("zip") && (
                <div className="relative w-[100px] font-inter leading-[12px] text-red-500">
                  Required Field
                </div>
              )}
            </div>
            <div className="mq391:min-w-full flex min-w-[351px] max-w-full flex-1 flex-col items-start justify-start gap-1.5 text-gray1-200">
              <div className="relative inline-block min-w-[40px] leading-[20px]">
                <label htmlFor="county">County</label>
                <p className="absolute right-[-15px] top-[4px] text-[26px] text-red-500">
                  *
                </p>
              </div>
              <TextBoxComponent
                readOnly={!isEditMode}
                floatLabelType="Auto"
                className="bg-gray-200"
                cssClass="e-outline"
                id="county"
                type="text"
                value={tempFormData?.county?.[0] || ""}
                style={{ backgroundColor: isEditMode ? "" : "#f3f4f6" }}
                blur={inputBlur}
                onChange={(e) =>
                  setTempFormData({
                    ...tempFormData,
                    county: e.target.value,
                  })
                }
              />
              {onShowRequiredFields("county") && (
                <div className="relative w-[100px] font-inter leading-[12px] text-red-500">
                  Required Field
                </div>
              )}
            </div>
          </div>
        </div>
        )}
      </section>
      <section className="flex max-w-full flex-col items-start justify-start self-stretch bg-white pb-4 pt-4">
        <div className="flex max-w-full flex-col items-start justify-start self-stretch bg-white">
          <div
            className={`mq280:flex-wrap mb-2 flex w-full max-w-full flex-row items-start justify-between gap-5 text-left text-xs leading-[normal] tracking-[normal] text-dodgerblue ${className}`}
          >
              <div className="flex w-full flex-col items-start justify-start">
                <h2 className="text-sm font-bold text-blue-500">
                    Last 3 disasters assisted in (if applicable)
                </h2>
                <div className="relative self-stretch font-medium leading-[21px] text-silver-200">
                  {/* {data.lastUpdatedDisasters &&
                    `Last Updated: ${tempDisastersFormData.lastUpdatedDisasters}`}
                  {console.log("ahah ", data.lastUpdatedDisasters)} */}
                </div>
              </div>
              <div className="flex items-center gap-2 mb-1">
                <button
                  type="button"
                  className="flex cursor-pointer gap-1 px-3 py-1.5 btn-default"
                  onClick={handleAddDisaster}
                >
                  <FaPlus className="h-3 w-3" />
                  Add
                </button>
                <button
                  type="button"
                  className="flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-gray-200 transition-all duration-300 ease-in-out hover:bg-gray-300"
                  onClick={toggleDisaster}
                >
                  <span
                    className={`transition-transform duration-300 ease-in-out ${
                      isDisasterVisible ? "rotate-0" : "rotate-180"
                    }`}
                  >
                    <FaChevronDown />
                  </span>
                </button>
              </div>
          </div>
          {isDisasterVisible && (
          <div className="flex max-w-full flex-col items-start justify-start self-stretch bg-white">
            <GridComponent
              dataSource={partnerDisasters.filter(
                (disaster) => disaster.is_deleted !== true
              )}
              editSettings={{
                allowEditing: true,
                allowAdding: true,
                allowDeleting: true,
                showDeleteConfirmDialog: true,
                mode: "Dialog",
              }}
              ref={gridRefDisasters}
              actionBegin={actionBeginDisaster}
              allowFiltering={true}
              filterSettings={filterSettings}
              allowSorting={true}
              sortSettings={sortSettings}
              allowPaging={true}
              pageSettings={pageSettings}
              showColumnMenu={true}
              columnMenuItems={columnMenuItems}
              cssClass="wrap-header-grid"
            >
              <ColumnsDirective>
                <ColumnDirective
                  field="disasterId"
                  isPrimaryKey={true}
                  isIdentity={true}
                  visible={false}
                />
                <ColumnDirective
                  field="year"
                  format="N0"
                  headerText="Year"
                  validationRules={{ required: true }}
                  width="100"
                />
                <ColumnDirective
                  field="month"
                  headerText="Month"
                  validationRules={{ required: true }}
                  width="130"
                />
                <ColumnDirective
                  field="location"
                  headerTemplate={() => (
                      <div style={{ lineHeight: '1.2', whiteSpace: 'normal',  padding: '0 8px 0 0px' }}>
                        Location of Disaster
                      </div>
                  )}
                  validationRules={{ required: true }}
                />
                <ColumnDirective
                  field="reference"
                  headerTemplate={() => (
                      <div style={{ lineHeight: '1.2', whiteSpace: 'normal',  padding: '0 8px 0 0px' }}>
                        Reference Contact Person
                      </div>
                  )}
                  validationRules={{ required: true }}
                />
                <ColumnDirective
                  field="phone"
                  headerTemplate={() => (
                      <div style={{ lineHeight: '1.2', whiteSpace: 'normal',  padding: '0 8px 0 0px' }}>
                        Contact Person Phone
                      </div>
                  )}
                  validationRules={{ required: true }}
                />
                <ColumnDirective
                  field="email"
                  headerTemplate={() => (
                      <div style={{ lineHeight: '1.2', whiteSpace: 'normal',  padding: '0 8px 0 0px' }}>
                        Contact Person Email
                      </div>
                  )}
                  validationRules={{ required: true }}
                />
                <ColumnDirective
                  field="services"
                  headerTextAlign="Left"
                  headerTemplate={() => (
                    <div style={{ lineHeight: '1.2', whiteSpace: 'normal', padding: '0 8px 0 0px' }}>
                      Services Rendered
                    </div>
                  )}
                  validationRules={{ required: true }}
                />
                <ColumnDirective
                  field="comment"
                  headerText="Comment"
                  validationRules={{ required: false }}
                />
                <ColumnDirective
                  headerText="Actions"
                  width="100"
                  commands={[
                    {
                      type: "Edit",
                      buttonOption: {
                        content: '<i class="fas fa-edit"></i>',
                        cssClass: "e-outline custom-button",
                      },
                    },
                    {
                      type: "Delete",
                      buttonOption: {
                        content: '<i class="fas fa-trash-alt"></i>',
                        cssClass: "e-outline custom-button",
                      },
                    },
                  ]}
                />
              </ColumnsDirective>
              <Inject
                services={[Page, Sort, Filter, Edit, CommandColumn, ColumnMenu]}
              />
            </GridComponent>
          </div>
          )}
        </div>
      </section>
      <div className="box-border flex max-w-full flex-col items-start justify-start gap-[10px] self-stretch border-solid border-gray-200 bg-white px-[19px] pb-5 pt-[18px] text-left text-xs text-darkslateblue">
        {/* <Employees
          data={{ org: partner, orgId: partner?.id, orgType: "partner" }}
        /> */}
        <DialogComponent
          width="400px"
          isModal={true}
          visible={showPopup}
          close={handleClosePopup}
          header={null}
          content={dialogContent}
          showCloseIcon={true}
          closeOnEscape={true}
          target={document.body}
          cssClass="welcome-dialog"
        />
      </div>
    </div>
  );
};

export default PartnerProfile;
