import PropTypes from "prop-types";
import { ReactComponent as LibraryIcon } from "../icons/home/library.svg";
import { ReactComponent as MapsIcon } from "../icons/home/maps.svg";
import { ReactComponent as JobOpportunitiesIcon } from "../icons/home/job-opportunities.svg";
import { ReactComponent as VolunteerOpportunitiesIcon } from "../icons/home/volunteer-opportunities.svg";
import { registerLicense, Internationalization } from "@syncfusion/ej2-base";
import React, { useState, useEffect, useRef } from "react";
import {
  ColumnDirective,
  ColumnsDirective,
  CommandColumn,
  GridComponent,
  Toolbar,
  Filter,
  ColumnMenu,
} from "@syncfusion/ej2-react-grids";
import { Inject, Page, Sort, Edit } from "@syncfusion/ej2-react-grids";
import { collection, getDocs, addDoc } from "firebase/firestore";
import { db } from "../firebase";
import { getAuth } from "firebase/auth";
import { doc, getDoc, Query, updateDoc, setDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { ReactComponent as AnalyticsIcon } from "../icons/home/analytics.svg";
import { CheckBoxComponent } from "@syncfusion/ej2-react-buttons";
import {
  AccumulationChartComponent,
  AccumulationSeriesCollectionDirective,
  AccumulationSeriesDirective,
  PieSeries,
  AccumulationLegend,
  AccumulationDataLabel,
  AccumulationTooltip,
  ChartComponent,
  SeriesCollectionDirective,
  SeriesDirective,
  ColumnSeries,
  Category,
  Legend,
  DataLabel,
  Tooltip,
} from "@syncfusion/ej2-react-charts";
import DisasterMgtService from "../service/citizen/DisasterMgtService";
import DisasterImpactGraph from "./DashboardGraphs/DisasterImpactGraph";
import InjuryGraph from "./DashboardGraphs/InjuryGraph";
import HomeDamageGraph from "./DashboardGraphs/HomeDamageGraph";
import HomeDestroyedGraph from "./DashboardGraphs/HomeDestroyedGraph";
import HomeDamageTypedGraph from "./DashboardGraphs/HomeDamageTypedGraph";

// Syncfusion license key
registerLicense(
  "Ngo9BigBOggjHTQxAR8/V1NBaF5cXmZCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWXxceXRcQmZdV0R/XUM="
);

const sortSettings = {
  allowSorting: true,
};
const filterSettings = {
  type: "CheckBox",
};

const Home = ({ className = "" }) => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [activeData, setActiveData] = useState([]);
  const [editing, setEditing] = useState({
    allowDeleting: true,
    allowAdding: true,
    allowEditing: true,
    mode: "Dialog",
    showDeleteConfirmDialog: true,
    dialog: { cssClass: "custom-dialog" },
  });
  const [toolbarOptions, setToolbarOptions] = useState([
    "Add",
    "Edit",
    "Delete",
  ]); // Initialize toolbar options
  const [userRole, setUserRole] = useState("");
  const [loading, setLoading] = useState(true);
  const [totalResponses, setTotalResponses] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalValueSum, setTotalValue] = useState(0);
  const [immediateAssistance, setImmediateAssistance] = useState(0);
  const [recoveryAssistance, setRecoveryAssistance] = useState(0);
  const [disasterNames, setDisasterNames] = useState([]);
  const [selectedDisaster, setSelectedDisaster] = useState("");
  const [userId, setUserId] = useState(null);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const [checkboxStates, setCheckboxStates] = useState({
    show_disaster_impact_graph: false,
    show_injury_graph: false,
    show_home_damage_graph: false,
    show_homes_destroyed_graph: false,
    show_home_damage_typed_graph: false,
  });

  useEffect(() => {
    const fetchUserRole = async () => {
      const auth = getAuth();
      const user = auth.currentUser;
      if (user) {
        const userId = user.uid;
        const userDoc = await getDoc(doc(db, "users", userId));
        if (userDoc.exists()) {
          const role = userDoc.data().user_role;
          setUserRole(role);
          if (role === "center") {
            setEditing({
              allowEditing: false,
              allowAdding: false,
              allowDeleting: false,
              mode: "Dialog",
              dialog: { cssClass: "custom-dialog" },
            });
            setToolbarOptions([]);
          }
        }
      }
    };

    const fetchData = async () => {
      try {
        // Fetch declared disaster data
        const querySnapshot = await getDocs(
          collection(db, "declared-disaster")
        );
        const fetchedData = querySnapshot.docs.map((doc) => {
          const activeData = doc.data();
          return {
            ...activeData,
            disaster_id: doc.id,
            disaster_date: activeData.disaster_date
              ? activeData.disaster_date.toDate().toLocaleDateString("en-US", {
                  month: "2-digit",
                  day: "2-digit",
                  year: "numeric",
                })
              : null,
          };
        });
        setActiveData(fetchedData);

        // Fetch survey responses data for charts
        const surveySnapshot = await getDocs(
          collection(db, "survey_responses")
        );
        setTotalResponses(surveySnapshot.size);
        // Initialize variables to store tally for charts
        let disasterImpactCount = { affected: 0, notAffected: 0 };
        let injuryCount = {
          noInjury: 0,
          minorInjury: 0,
          medicalTreatment: 0,
          visitedHospital: 0,
          admitted: 0,
        };
        let homeDamageCount = {
          noDamage: 0,
          slightDamage: 0,
          limitedDamage: 0,
          severeDamage: 0,
          totalDestruction: 0,
        };
        let homesDestroyedCount = { damaged: 0, destroyed: 0, undamaged: 0 };
        let typeOfHomeDamageCount = {
          "Check All": 0,
          Siding: 0,
          Flooring: 0,
          Cabinets: 0,
          "Shed/garage": 0,
          Roof: 0,
          Foundation: 0,
          "Floor Joists": 0,
          HVAC: 0,
          Driveway: 0,
          Gutters: 0,
          Ceiling: 0,
          Drywall: 0,
          Electrical: 0,
          "Yard/Uprooted trees": 0,
          Other: 0,
        };

        surveySnapshot.forEach((doc) => {
          const data = doc.data().responses;

          // Disaster Impact
          if (data["Was the impacted address your primary address?"] === "No") {
            disasterImpactCount.notAffected += 1;
          } else if (
            data["Was the impacted address your primary address?"] === "Yes"
          ) {
            disasterImpactCount.affected += 1;
          }

          // Injury Data Mapping
          if (data["Injury"] === "No Injury") {
            injuryCount.noInjury += 1;
          } else if (data["Injury"] === "Minor Injury") {
            injuryCount.minorInjury += 1;
          } else if (data["Injury"] === "Sought medical treatment") {
            injuryCount.medicalTreatment += 1;
          } else if (data["Injury"] === "Visited Hospital") {
            injuryCount.visitedHospital += 1;
          } else if (data["Injury"] === "Admitted") {
            injuryCount.admitted += 1;
          }

          // Home Damage
          switch (data["Home Damage"]) {
            case "No Damage":
              homeDamageCount.noDamage += 1;
              break;
            case "Slight Damage":
              homeDamageCount.slightDamage += 1;
              break;
            case "Limited Damage":
              homeDamageCount.limitedDamage += 1;
              break;
            case "Severe Damage":
              homeDamageCount.severeDamage += 1;
              break;
            case "Total Destruction":
              homeDamageCount.totalDestruction += 1;
              break;
            default:
              break;
          }

          // Homes Destroyed
          switch (data["The impacted home:"]) {
            case "Has minor damage":
              homesDestroyedCount.undamaged += 1;
              break;
            case "Has major damage":
              homesDestroyedCount.damaged += 1;
              break;
            case "Is destroyed":
              homesDestroyedCount.destroyed += 1;
              break;
            default:
              break;
          }

          // Type of Home Damage
          const homeDamageMap = data["There is visible damage to the home's:"];

          if (homeDamageMap) {
            Object.keys(typeOfHomeDamageCount).forEach((type) => {
              if (homeDamageMap[type] === true) {
                typeOfHomeDamageCount[type] += 1;
              }
            });
          }
        });

        // Now update the state for each graph
        setDisasterImpactData(disasterImpactCount);
        setInjuryData(injuryCount);
        setHomeDamageData(homeDamageCount);
        setHomesDestroyedData(homesDestroyedCount);
        setTypeOfHomeDamageData(typeOfHomeDamageCount);

        setLoading(false); // Set loading to false after all data is fetched
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchUserRole();
    fetchData();
  }, []);

  useEffect(() => {
    const loadData = async () => {
      try {
        const {
          allRecords,
          totalRecords,
          totalValueSum,
          immediateAssistanceSum,
          recoveryAssistanceSum,
        } = await DisasterMgtService.fetchDisasterData();
        setData(allRecords);
        setTotalRecords(totalRecords);
        setTotalValue(totalValueSum);
        setImmediateAssistance(immediateAssistanceSum);
        setRecoveryAssistance(recoveryAssistanceSum);
      } catch (error) {
        console.error("Error loading disaster data:", error);
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, []);

  useEffect(() => {
    const fetchUserCheckboxStates = async () => {
      const auth = getAuth();
      const user = auth.currentUser;

      if (user) {
        setUserId(user.uid);
        const userDocRef = doc(db, "users", user.uid);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          const userData = userDoc.data();
          setCheckboxStates({
            ...checkboxStates,
            ...userData, // Merge with the existing state
          });
        }
      }
    };

    fetchUserCheckboxStates();
  }, []);

  const handleCheckboxChange = async (field, checked) => {
    setCheckboxStates((prev) => ({
      ...prev,
      [field]: checked,
    }));

    if (userId) {
      const userDocRef = doc(db, "users", userId);
      await updateDoc(userDocRef, {
        [field]: checked,
      });
    }
  };

  const [disasterImpactData, setDisasterImpactData] = useState({
    affected: 0,
    notAffected: 0,
  });
  const [injuryData, setInjuryData] = useState({
    noInjury: 0,
    minorInjury: 0,
    medicalTreatment: 0,
    visitedHospital: 0,
    admitted: 0,
  });
  const [homeDamageData, setHomeDamageData] = useState({
    noDamage: 0,
    slightDamage: 0,
    limitedDamage: 0,
    severeDamage: 0,
    totalDestruction: 0,
  });
  const [homesDestroyedData, setHomesDestroyedData] = useState({
    damaged: 0,
    destroyed: 0,
    undamaged: 0,
  });
  const [typeOfHomeDamageData, setTypeOfHomeDamageData] = useState({
    "Check All": 0,
    Siding: 0,
    Flooring: 0,
    Cabinets: 0,
    "Shed/garage": 0,
    Roof: 0,
    Foundation: 0,
    "Floor Joists": 0,
    HVAC: 0,
    Driveway: 0,
    Gutters: 0,
    Ceiling: 0,
    Drywall: 0,
    Electrical: 0,
    "Yard/Uprooted trees": 0,
    Other: 0,
  });

  const actionComplete = async (args) => {
    if (args.requestType === "delete") {
      const deletedItems = Array.isArray(args.data) ? args.data : [args.data];
      const deletedIds = deletedItems.map((item) => item.disaster_id);
      console.log("Deleting IDs:", deletedIds);
      const updatedData = data.filter(
        (item) => !deletedIds.includes(item.disaster_id)
      );
      setData(updatedData);
    } else if (args.requestType === "save") {
      console.log("Save action triggered", args.data);
      const updatedItem = args.data;
      const updatedData = data.map((item) =>
        item.disaster_id === updatedItem.disaster_id ? updatedItem : item
      );
      setData(updatedData);
    } else if (args.requestType === "beginEdit" || args.requestType === "add") {
      const dialog = args.dialog;
      if (dialog) {
        // Check if dialog is not null
        dialog.header =
          args.requestType === "beginEdit"
            ? "Edit Record of " + args.rowData["disasterID"]
            : "New Disaster";

        // Set the header styles
        const headerContent = dialog.element.querySelector(
          ".e-dlg-header-content"
        );
        const header = dialog.element.querySelector(".e-dlg-header");
        if (headerContent && header) {
          // Ensure elements exist before accessing
          headerContent.style.backgroundColor = "#348BFF";
          headerContent.style.color = "#FFFFFF";
          header.style.color = "#FFFFFF";
        }

        // Style the save and cancel buttons
        setTimeout(() => {
          const saveButton = dialog.element.querySelector(".e-primary");
          const cancelButton = dialog.element.querySelector(
            ".e-btn:not(.e-primary)"
          );

          if (saveButton) {
            saveButton.style.backgroundColor = "#FFFFFF";
            saveButton.style.color = "#348BFF";
            saveButton.style.border = "none";
          }

          if (cancelButton) {
            cancelButton.style.backgroundColor = "#FFFFFF";
            cancelButton.style.color = "#348BFF";
            cancelButton.style.border = "1px solid #348BFF";
          }
        }, 0);
      }
    } else if (args.requestType === "add") {
      const auth = getAuth();
      const user = auth.currentUser;
      if (user) {
        const newDisaster = {
          ...args.data,
          center_id: user.uid,
        };
        await addDoc(collection(db, "declared-disaster"), newDisaster);
        const querySnapshot = await getDocs(
          collection(db, "declared-disaster")
        );
        const fetchedData = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          disaster_id: doc.id,
        }));
        setData(fetchedData);
      }
    }
  };

  const toolbarClick = (args) => {
    if (args.item.id === "Grid_add") {
    } else if (args.item.id === "Grid_edit") {
    } else if (args.item.id === "Grid_delete") {
    } else if (args.item.id === "Grid_update") {
    } else if (args.item.id === "Grid_cancel") {
    }
  };

  const queryCellInfo = (args) => {
    if (args.column.field === "disaster_name") {
      let bgColor, textColor;
      switch (args.data.disasterName) {
        case "Fire":
          bgColor = "#FFF6E6";
          textColor = "#FDA502";
          break;
        case "Typhoon":
          bgColor = "#DCF2FF";
          textColor = "#1565C0";
          break;
        case "Flood":
          bgColor = "#BED8FF";
          textColor = "#0E47A1";
          break;
        case "Earthquake":
          bgColor = "#F4EDE8";
          textColor = "#8B4514";
          break;
        default:
          bgColor = "";
          textColor = "";
      }

      if (bgColor) {
        args.cell.innerHTML = `<div style="
          background-color: ${bgColor};
          color: ${textColor};
          padding: 5px 10px;
          border-radius: 15px;
          display: inline-block;
          font-weight: bold;
          text-align: center;
        ">${args.data.disaster_name}</div>`;
      }
    }

    if (args.column.field === "disaster_status") {
      let bgColor, textColor;
      switch (args.data.disaster_status) {
        case "Active":
          bgColor = "#DEF7EC"; // Green
          textColor = "#299D91"; // White
          break;
        case "Inactive":
          bgColor = "#FDE8E8"; // Red
          textColor = "#E4626F"; // White
          break;
        default:
          bgColor = "";
          textColor = "";
      }

      if (bgColor) {
        args.cell.innerHTML = `<div style="
          background-color: ${bgColor};
          color: ${textColor};
          padding: 5px 10px;
          border-radius: 15px;
          display: inline-block;
          font-weight: bold;
          text-align: center;
        ">${args.data.disaster_status}</div>`;
      }
    }

    if (args.column.field === "alert_level") {
      let bgColor, textColor;
      switch (args.data.alert_level) {
        case "Level 1":
          bgColor = "#FFEDBC"; // Light Yellow
          textColor = "#F4B000"; // Dark Orange
          break;
        case "Level 2":
          bgColor = "#FAE2CC"; // Light Orange
          textColor = "#E46E00"; // Dark Orange
          break;
        case "Level 3":
          bgColor = "#F1D5D5"; // Light Red
          textColor = "#CD1A1A"; // Dark Red
          break;
        default:
          bgColor = "";
          textColor = "";
      }

      if (bgColor) {
        args.cell.innerHTML = `<div style="
          background-color: ${bgColor};
          color: ${textColor};
          padding: 5px 10px;
          border-radius: 15px;
          display: inline-block;
          font-weight: bold;
          text-align: center;
        ">${args.data.alert_level}</div>`;
      }
    }
  };

  const intl = new Internationalization();

  const formatDate = (date) => {
    if (date) {
      return intl.formatDate(new Date(date), { format: "MM/dd/yyyy" });
    }
    return "";
  };

  const formattedData = data.map((item) => ({
    ...item,
    disasterDate: formatDate(item.disasterDate),
  }));

  const getCurrentFormattedDate = () => {
    const currentDate = new Date();
    return currentDate.toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    });
  };

  const datepickerparams = {
    params: {
      value: new Date(),
      format: "MM/dd/yyyy",
      showClearButton: false,
      showTodayButton: true,
    },
  };

  const toolbarStyle = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  };

  const columnMenuItems = ["SortAscending", "SortDescending", "Filter"];

  const pageSettings = { pageSize: 10 };

  const chartColorPalette = [
    "#EF4444",
    "#F59E0B",
    "#3B82F6",
    "#10B981",
    "#F97316",
    "#8B5CF6",
  ];

  const ChartCard = ({ title, children }) => (
    <div className="min-w-[300px] flex-1 rounded-md border border-solid border-gainsboro-200 bg-white p-5 shadow-lg">
      <h2 className="mb-4 flex items-center justify-between text-sm font-bold text-blue-500">
        {title}
        <span className="text-gray-400">⋮</span>
      </h2>
      {children}
    </div>
  );

  const ChartCardLong = ({ title, children }) => (
    <div className="w-fit flex-1 rounded-md border border-solid border-gainsboro-200 bg-white p-5 shadow-lg">
      <h2 className="mb-4 flex items-center justify-between text-sm font-bold text-blue-500">
        {title}
        <span className="text-gray-400">⋮</span>
      </h2>
      {children}
    </div>
  );

  useEffect(() => {
    const fetchDisasterNames = async () => {
      try {
        const querySnapshot = await getDocs(
          collection(db, "declared-disaster")
        );
        const names = querySnapshot.docs.map((doc) => doc.data().disaster_name);
        setDisasterNames(names);
      } catch (error) {
        console.error("Error fetching disaster names:", error);
      }
    };

    fetchDisasterNames();
  }, []);

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const formattedImmediateAssistance = formatter.format(immediateAssistance);
  const formattedRecoveryAssistance = formatter.format(recoveryAssistance);

  if (loading) {
    return <div className=""></div>;
  }

  return (
    <div
      className={`m-0 box-border flex w-[1648px] max-w-full flex-col items-start justify-start gap-2.5 px-2.5 py-[15px] leading-[normal] tracking-[normal] ${className}`}
    >
      <div id="dialog-container"></div>
      <div className="flex flex-row items-start justify-start gap-1.5 py-0 pl-0 pr-5">
        <a className="relative inline-block min-w-[100px] text-left  text-xs font-medium leading-[16px] text-dodgerblue [text-decoration:none]">
          Dashboard
        </a>
      </div>
      {/* Report Generator Start */}
      <div className="w-full flex-1 rounded-mini bg-white">
        <section
          className={` flex w-full max-w-full flex-col items-start justify-start gap-1 text-left  ${className}`}
        >
          <div className="flex w-full items-center justify-between gap-2.5">
            <select
              value={selectedDisaster}
              onChange={(e) => setSelectedDisaster(e.target.value)}
              className="relative text-[20px] font-extrabold leading-normal text-blue-500 [text-decoration:none]"
            >
              {disasterNames.map((name, index) => (
                <option key={index} value={name}>
                  {name}
                </option>
              ))}
            </select>
            <select className="relative text-[20px] font-extrabold leading-normal text-blue-500 [text-decoration:none]">
              <option value="yearly">Yearly</option>
              <option value="monthly">Monthly</option>
              <option value="daily">Daily</option>
            </select>
          </div>

          <div className="flex w-full flex-col items-start justify-start gap-5">
            <div className="box-border flex w-full flex-row flex-wrap items-start justify-start gap-5">
              {/* Card: People Affected */}
              <div className="box-border flex flex-1 flex-row items-start justify-between gap-4 rounded-md border border-gray-200 bg-white p-5 shadow-lg">
                <div className="flex flex-col items-start justify-start gap-2">
                  <p className="text-[15px] font-bold text-blue-500">
                    People Affected
                  </p>
                  <div className="text-[20px] font-semibold text-darkslategray-300">
                    {totalRecords}
                  </div>
                </div>
              </div>

              {/* Card: Immediate Response Assistance Received */}
              <div className="box-border flex flex-1 flex-row items-start justify-between gap-4 rounded-md border border-gray-200 bg-white p-5 shadow-lg">
                <div className="flex flex-col items-start justify-start gap-2">
                  <p className="text-[15px] font-bold text-blue-500">
                    Immediate Response Assistance Received
                  </p>
                  <div className="text-[20px] font-semibold text-darkslategray-300">
                    {formattedImmediateAssistance}
                  </div>
                </div>
              </div>

              {/* Card: Recovery Assistance Received */}
              <div className="box-border flex flex-1 flex-row items-start justify-between gap-4 rounded-md border border-gray-200 bg-white p-5 shadow-lg">
                <div className="flex flex-col items-start justify-start gap-2">
                  <p className="text-[15px] font-bold text-blue-500">
                    Recovery Assistance Received
                  </p>
                  <div className="text-[20px] font-semibold text-darkslategray-300">
                    {formattedRecoveryAssistance}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Dropdown Checkbox */}
        <div className="mt-4 flex w-full items-center justify-end">
          <div className="relative">
            <button
              onClick={() => setSidebarOpen(!sidebarOpen)}
              className="btn-default flex cursor-pointer gap-1 px-3 py-1.5"
            >
              {sidebarOpen ? "Close Settings" : "Open Settings"}
            </button>

            {/* Dropdown Content */}
            {sidebarOpen && (
              <div
                className="absolute right-0 mt-2 w-72 rounded-md border border-solid border-gainsboro-200 bg-white p-5 shadow-lg"
                style={{ zIndex: 1 }}
              >
                <div className="">
                  <div className=" flex flex-col gap-4">
                    <CheckBoxComponent
                      label="Show Disaster Impact Graph"
                      checked={checkboxStates.show_disaster_impact_graph}
                      change={(e) =>
                        handleCheckboxChange(
                          "show_disaster_impact_graph",
                          e.checked
                        )
                      }
                    />
                    <CheckBoxComponent
                      label="Show Injury Graph"
                      checked={checkboxStates.show_injury_graph}
                      change={(e) =>
                        handleCheckboxChange("show_injury_graph", e.checked)
                      }
                    />
                    <CheckBoxComponent
                      label="Show Home Damage Graph"
                      checked={checkboxStates.show_home_damage_graph}
                      change={(e) =>
                        handleCheckboxChange(
                          "show_home_damage_graph",
                          e.checked
                        )
                      }
                    />
                    <CheckBoxComponent
                      label="Show Homes Destroyed Graph"
                      checked={checkboxStates.show_homes_destroyed_graph}
                      change={(e) =>
                        handleCheckboxChange(
                          "show_homes_destroyed_graph",
                          e.checked
                        )
                      }
                    />
                    <CheckBoxComponent
                      label="Show Type of Home Damage Graph"
                      checked={checkboxStates.show_home_damage_typed_graph}
                      change={(e) =>
                        handleCheckboxChange(
                          "show_home_damage_typed_graph",
                          e.checked
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Graphs */}
        <section className="mx-auto mt-4 w-full">
          <div className="flex flex-wrap justify-between gap-5">
            {checkboxStates.show_disaster_impact_graph && (
              <div className="min-w-[400px] max-w-full flex-1">
                <DisasterImpactGraph disasterImpactData={disasterImpactData} />
              </div>
            )}
            {checkboxStates.show_injury_graph && (
              <div className="min-w-[350px] max-w-full flex-1">
                <InjuryGraph injuryData={injuryData} />
              </div>
            )}
            {checkboxStates.show_home_damage_graph && (
              <div className="min-w-[350px] max-w-full flex-1">
                <HomeDamageGraph homeDamageData={homeDamageData} />
              </div>
            )}
            {checkboxStates.show_homes_destroyed_graph && (
              <div className="min-w-[350px] max-w-full flex-1">
                <HomeDestroyedGraph homesDestroyedData={homesDestroyedData} />
              </div>
            )}
            {checkboxStates.show_home_damage_typed_graph && (
              <div className="min-w-[350px] max-w-full flex-1">
                <HomeDamageTypedGraph
                  typeOfHomeDamageData={typeOfHomeDamageData}
                />
              </div>
            )}
          </div>
        </section>
      </div>
      {/* Report Generator End */}

      {/* Active Disaster Grid Start */}
      <section className="mt-4 w-full rounded-md border border-gray-200 bg-white">
        <h1 className="mb-3 text-base font-bold text-blue-500">
          ACTIVE DISASTERS
        </h1>

        <GridComponent
          dataSource={activeData}
          allowSorting={true}
          sortSettings={sortSettings}
          actionComplete={actionComplete}
          toolbarClick={toolbarClick}
          filterSettings={filterSettings}
          allowFiltering={true}
          queryCellInfo={queryCellInfo}
          allowPaging={true}
          pageSettings={pageSettings}
          showColumnMenu={true}
          columnMenuItems={columnMenuItems}
        >
          <ColumnsDirective>
            <ColumnDirective
              field="disaster_id"
              headerText="Disaster ID"
              isPrimaryKey={true}
              visible={false}
            />
            <ColumnDirective
              field="disaster_name"
              headerText="Disaster"
              width="150"
              textAlign="Left"
              editType="stringedit"
            />
            <ColumnDirective
              field="disaster_status"
              headerText="Status"
              width="100"
              textAlign="Left"
              editType="dropdownedit"
              edit={{
                params: {
                  dataSource: [
                    { text: "Active", value: "Active" },
                    { text: "Inactive", value: "Inactive" },
                  ],
                  fields: { text: "text", value: "value" },
                  query: new Query(),
                },
              }}
            />
            <ColumnDirective
              field="disaster_date"
              headerText="Date"
              width="100"
              format="MM/dd/yyyy"
              editType="datepickeredit"
              edit={datepickerparams}
            />
            <ColumnDirective
              field="location"
              headerText="Location"
              width="100"
              editType="stringedit"
            />
          </ColumnsDirective>
          <Inject
            services={[
              Page,
              Sort,
              Edit,
              CommandColumn,
              Toolbar,
              Filter,
              ColumnMenu,
            ]}
          />
        </GridComponent>
      </section>
      {/* Active Disaster Grid End */}
    </div>
  );
};

export default Home;
