import React, { useState } from "react";
import { registerLicense } from "@syncfusion/ej2-base";
import { Inject } from "@syncfusion/ej2-react-grids";
import {
  AccumulationChartComponent,
  AccumulationSeriesCollectionDirective,
  AccumulationSeriesDirective,
  PieSeries,
  AccumulationLegend,
  AccumulationDataLabel,
  AccumulationTooltip,
} from "@syncfusion/ej2-react-charts";

// Syncfusion license key
registerLicense(
  "Ngo9BigBOggjHTQxAR8/V1NBaF5cXmZCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWXxceXRcQmZdV0R/XUM="
);

const DisasterImpactGraph = ({
  disasterImpactData = { affected: 0, notAffected: 0 },
}) => {
  const chartColorPalette = [
    "#EF4444",
    "#F59E0B",
    "#3B82F6",
    "#10B981",
    "#F97316",
    "#8B5CF6",
  ];

  const ChartCard = ({ title, children }) => (
    <div className="min-w-[350px] max-w-full flex-1 rounded-md border border-solid border-gainsboro-200 bg-white p-5 shadow-lg">
      <h2 className="mb-4 flex items-center justify-between text-sm font-bold text-blue-500">
        {title}
        <span className="text-gray-400">⋮</span>
      </h2>
      {children}
    </div>
  );

  return (
    <div className="w-full">
      <ChartCard title="Disaster Impact">
        <AccumulationChartComponent
          id="disaster-impact"
          tooltip={{ enable: true }}
          height="300px"
          legendSettings={{ visible: true, position: "Bottom" }}
          style={{ zIndex: 0 }}
        >
          <Inject
            services={[
              PieSeries,
              AccumulationLegend,
              AccumulationDataLabel,
              AccumulationTooltip,
            ]}
          />
          <AccumulationSeriesCollectionDirective>
            <AccumulationSeriesDirective
              dataSource={[
                { x: "Affected", y: disasterImpactData.affected },
                {
                  x: "Not Affected",
                  y: disasterImpactData.notAffected,
                },
              ]}
              xName="x"
              yName="y"
              innerRadius="40%"
              dataLabel={{
                visible: true,
                position: "Outside",
                name: "x",
              }}
              palettes={chartColorPalette}
            />
          </AccumulationSeriesCollectionDirective>
        </AccumulationChartComponent>
      </ChartCard>
    </div>
  );
};
export default DisasterImpactGraph;
