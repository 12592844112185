import { React, useState } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import placeholderImage from "../../icons/Opportunities/opportunities.png";
import { useEffect } from "react";
import { db } from "../../firebase";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import {
  addDoc,
  collection,
  updateDoc,
  where,
  query,
  getDocs,
  doc,
  getFirestore,
  getDoc,
  setDoc,
} from "firebase/firestore";
import {
  FaEdit,
  FaChevronDown,
  FaChevronUp,
  FaSave,
  FaTimes,
  FaPlus,
} from "react-icons/fa";
import {
  TextBoxComponent,
  TextAreaComponent,
} from "@syncfusion/ej2-react-inputs";
import { NumericTextBoxComponent } from "@syncfusion/ej2-react-inputs";
import {
  DatePickerComponent,
  TimePickerComponent,
} from "@syncfusion/ej2-react-calendars";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { SwitchComponent } from "@syncfusion/ej2-react-buttons";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";

const PartnerJobOpportunities = ({ className = "", onNavigateBack }) => {
  const [userData, setUserData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [confirmationType, setConfirmationType] = useState("");
  const [currentJobId, setCurrentJobId] = useState(null);

  const handleEditModalClose = () => {
    setIsEditModalOpen(false);
    setSelectedJob(null);
  };

  const handleAddSubmit = async () => {
    fetchJobs();
  };

  const handleEditSubmit = async (updatedJob) => {
    const updatedJobs = jobs.map((j) =>
      j.job.id === updatedJob.id ? { job: updatedJob } : j
    );
    setJobs(updatedJobs);
    setIsEditModalOpen(false);
  };

  const openConfirmationModal = (type, jobId) => {
    setConfirmationType(type);
    setCurrentJobId(jobId);
    setIsConfirmationModalOpen(true);
  };

  const closeConfirmationModal = () => {
    setIsConfirmationModalOpen(false);
    setConfirmationType("");
    setCurrentJobId(null);
  };

  const handleDeleteJob = async () => {
    try {
      await updateDoc(doc(db, "opportunities", currentJobId), {
        is_deleted: true,
      });
      setJobs(jobs.filter((job) => job.job.id !== currentJobId));
      console.log(`Job with ID ${currentJobId} deleted successfully.`);
    } catch (error) {
      console.error("Error deleting job:", error);
    } finally {
      closeConfirmationModal();
    }
  };

  const handleToggleClose = async (jobId) => {
    try {
      const jobToUpdate = jobs.find((job) => job.job.id === jobId);
      if (!jobToUpdate) {
        throw new Error("Job not found");
      }
      const updatedCloseStatus = !jobToUpdate.job.closed;
      const jobDocRef = doc(db, "opportunities", jobId);
      await updateDoc(jobDocRef, { closed: updatedCloseStatus });

      fetchJobs();
    } catch (error) {
      console.error("Error toggling job close status:", error);
    }
  };

  const handleJobClick = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const formatCurrency = (amount) => {
    if (isNaN(amount)) return "$0.00";
    return `$${amount.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`;
  };

  const convertToDate = (timestamp) =>
    timestamp && timestamp.toDate
      ? timestamp.toDate().toLocaleDateString()
      : "N/A";

  const convertToTime = (timestamp) =>
    timestamp && timestamp.toDate
      ? timestamp
          .toDate()
          .toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })
      : "N/A";

  const db = getFirestore();

  const fetchJobs = async () => {
    if (!userData.id) return;

    try {
      setIsLoading(true);
      const jobsQuery = query(
        collection(db, "opportunities"),
        where("user_id", "==", userData.id),
        where("type", "==", "job"),
        where("is_deleted", "==", false)
      );

      const querySnapshot = await getDocs(jobsQuery);
      const fetchedJobs = querySnapshot.docs.map((doc) => {
        const data = doc.data();

        return {
          job: data,
        };
      });

      setJobs(fetchedJobs);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching jobs:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const auth = getAuth();

    const fetchUserData = async (user) => {
      if (user) {
        try {
          const userDocRef = doc(db, "users", user.uid);
          const userDocSnap = await getDoc(userDocRef);
          if (userDocSnap.exists()) {
            const userData = userDocSnap.data();
            setUserData(userData);
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
    };

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchUserData(user);
      } else {
        setUserData({});
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    fetchJobs();
  }, [userData]);

  const JobCard = ({ job, fetchJobs }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isPublished, setIsPublished] = useState(job.published || false);
    const [applicationsCount, setApplicationsCount] = useState(0);

    const fetchApplicationsCount = async () => {
      try {
        const applicationsRef = collection(
          db,
          "opportunities",
          job.id,
          "applications"
        );
        const applicationsSnapshot = await getDocs(applicationsRef);
        setApplicationsCount(applicationsSnapshot.size);
      } catch (error) {
        console.error("Error fetching applications count:", error);
      }
    };

    useEffect(() => {
      fetchApplicationsCount();
    }, [job.id]);

    const handleTogglePublished = async (checked) => {
      setIsPublished(checked);

      try {
        const jobDocRef = doc(db, "opportunities", job.id);
        await updateDoc(jobDocRef, { published: checked });
        console.log(
          `Job ${checked ? "published" : "unpublished"} successfully.`
        );
      } catch (error) {
        console.error("Error updating published status:", error);
      }
    };

    return (
      <>
        <div
          className={`flex w-[330px] flex-col items-end justify-start gap-[10px] rounded-mini border bg-white px-0 pb-[26px] pt-0 shadow-lg ${
            selectedJob === job ? "border-blue-500" : "border-gainsboro-200"
          } `}
          style={{
            border:
              selectedJob === job ? "2px solid #467ba4" : "1px solid gainsboro",
          }}
        >
          <div className="relative h-[190px] self-stretch">
            <img
              className="absolute left-0 top-0 z-[1] h-full w-full rounded-b-none rounded-t-mini object-cover"
              alt={job.title}
              src={job.backgroundImage || placeholderImage}
            />
          </div>
          <div className="flex flex-row items-start justify-end self-stretch py-0 pl-[26px] pr-[22px]">
            <div className="flex flex-1 flex-row items-start justify-between gap-5">
              <div className="flex flex-col items-start justify-start gap-2">
                <b className="relative z-[1] inline-block min-w-[58px]">
                  {job.title}
                </b>
                <div className="relative z-[1] inline-block min-w-[57px] font-medium">
                  Applications: {applicationsCount}
                </div>
              </div>
              <div className="flex flex-col items-start justify-start px-0 pb-0 pt-px text-right text-xs">
                <div className="flex flex-col items-end justify-start gap-2">
                  <div className="relative z-[1] inline-block min-w-[71px] font-medium">
                    {convertToDate(job.date)}
                  </div>
                  <div className="relative z-[1] inline-block min-w-[107px] whitespace-nowrap font-medium">
                    {convertToTime(job.startTime)} -{" "}
                    {convertToTime(job.endTime)}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-row items-start justify-end self-stretch py-0 pl-[26px] pr-[21px] text-center text-white">
            <div
              onClick={() => setSelectedJob(job)}
              className="bg-header-panel z-[1] flex flex-1 cursor-pointer flex-row items-start justify-start rounded-8xs border-[1px] border-solid hover:bg-opacity-90"
            >
              <b className="relative z-[1] flex h-10 flex-1 items-center justify-center text-[#FDB841]">
                View
              </b>
            </div>
          </div>
          <div className="flex flex-row items-start justify-end self-stretch py-0 pl-[26px] pr-[21px] text-center text-white">
            <div
              onClick={() => handleToggleClose(job.id)}
              className="bg-header-panel z-[1] flex flex-1 cursor-pointer flex-row items-start justify-start rounded-8xs border-[1px] border-solid hover:bg-opacity-90"
            >
              <b className="relative z-[1] flex h-10 flex-1 items-center justify-center text-[#FDB841]">
                {job.closed ? "Open Job" : "Close Job"}
              </b>
            </div>
          </div>
          <div className="flex w-full items-center justify-between pl-[26px] pr-[21px]">
            <div className="flex space-x-2">
              <p>Published:</p>
              <SwitchComponent
                cssClass="e-switch-toggle"
                checked={isPublished}
                change={(e) => handleTogglePublished(e.checked)}
              />
            </div>
            <div className="flex space-x-3">
              <button
                className="btn-cancel flex cursor-pointer gap-1 px-3 py-1.5"
                type="button"
                onClick={() => openConfirmationModal("delete", job.id)}
              >
                Delete
              </button>
              <button
                className="btn-default flex cursor-pointer gap-1 px-3 py-1.5"
                type="button"
                onClick={() => {
                  setSelectedJob(job);
                  setIsEditModalOpen(true);
                }}
              >
                Edit
              </button>
            </div>
          </div>
        </div>
      </>
    );
  };

  const JobDetailsModal = ({ job, onClose }) => {
    if (!job) return null;

    return (
      <>
        <div className="fixed right-0 top-10 z-30 flex h-[100vh] w-[800px] items-center justify-end p-2">
          <div className="w-[800px] overflow-hidden rounded-lg bg-white shadow-xl">
            <div className="bg-header-panel sticky top-0 z-0 rounded-t-lg p-4">
              <div className="flex items-center justify-between">
                <h1 className="flex-1 text-start text-[20px] font-bold text-[#FDB841]">
                  {job.title}
                </h1>
                <button
                  onClick={onClose}
                  className="bg-header-panel cursor-pointer p-2 text-[#FDB841] hover:text-gray-200"
                >
                  <svg
                    className="h-4 w-4"
                    fill="#FDB841"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </div>

            <div className="h-[80vh] overflow-y-auto">
              <div className="flex flex-col p-6">
                <div className="">
                  <div className="w-full">
                    <div className="relative h-[250px] self-stretch">
                      <img
                        className="absolute left-0 top-0 z-[1] h-full w-full rounded-b-none rounded-t-mini object-cover"
                        alt={job.title}
                        src={job.backgroundImage || placeholderImage}
                      />
                    </div>
                    <div className="mb-3">
                      <div className="mb-4 flex items-center gap-2 text-[#467ba4]">
                        <svg
                          className="h-5 w-5"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8l-6-6z" />
                        </svg>
                        <p className="text-xl font-bold">JOB OVERVIEW</p>
                      </div>

                      <div className="rounded-lg  bg-white px-6">
                        <p className=" text-left text-black">{job.overview}</p>
                      </div>
                    </div>
                    <div className="mb-3">
                      <div className="flex justify-between rounded-lg  bg-white px-6">
                        <div className="flex items-center gap-3">
                          <div className="text-[#467ba4]">
                            <svg
                              className="h-8 w-8"
                              fill="currentColor"
                              viewBox="0 0 24 24"
                            >
                              <path d="M11.8 10.9c-2.27-.59-3-1.2-3-2.15 0-1.09 1.01-1.85 2.7-1.85 1.78 0 2.44.85 2.5 2.1h2.21c-.07-1.72-1.12-3.3-3.21-3.81V3h-3v2.16c-1.94.42-3.5 1.68-3.5 3.61 0 2.31 1.91 3.46 4.7 4.13 2.5.6 3 1.48 3 2.41 0 .69-.49 1.79-2.7 1.79-2.06 0-2.87-.92-2.98-2.1h-2.2c.12 2.19 1.76 3.42 3.68 3.83V21h3v-2.15c1.95-.37 3.5-1.5 3.5-3.55 0-2.84-2.43-3.81-4.7-4.4z" />
                            </svg>
                          </div>
                          <div>
                            <p className="text-sm text-black">SALARY</p>
                            <p className="font-bold text-black">
                              {formatCurrency(job.salary)}
                            </p>
                          </div>
                        </div>

                        <div className="flex items-center gap-3">
                          <div className="text-[#467ba4]">
                            <svg
                              className="h-8 w-8"
                              fill="currentColor"
                              viewBox="0 0 24 24"
                            >
                              <path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z" />
                              <path d="M12.5 7H11v6l5.25 3.15.75-1.23-4.5-2.67z" />
                            </svg>
                          </div>
                          <div>
                            <p className="text-sm text-black">WORKING HOURS</p>
                            <p className="font-bold text-black">
                              {convertToTime(job.startTime)} -{" "}
                              {convertToTime(job.endTime)}
                            </p>
                          </div>
                        </div>

                        <div className="flex items-center gap-3">
                          <div className="text-[#467ba4]">
                            <svg
                              className="h-8 w-8"
                              fill="currentColor"
                              viewBox="0 0 24 24"
                            >
                              <path d="M19 3h-1V1h-2v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V8h14v11z" />
                            </svg>
                          </div>
                          <div>
                            <p className="text-sm text-black">DATE POSTED</p>
                            <p className="font-bold text-black">
                              {convertToDate(job.date)}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mb-3">
                      <div className="mb-4 flex items-center gap-2 text-[#467ba4]">
                        <svg
                          className="h-5 w-5"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8l-6-6z" />
                        </svg>
                        <p className="text-xl font-bold">SKILL REQUIREMENTS</p>
                      </div>

                      <div className="rounded-lg  bg-white px-6">
                        <p className="text-left text-black">
                          {job.skillRequirements}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="w-full">
                    <div className="mb-4 flex items-center gap-2 text-[#467ba4]">
                      <svg
                        className="h-5 w-5"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path d="M12 7V3H2v18h20V7H12zM6 19H4v-2h2v2zm0-4H4v-2h2v2zm0-4H4V9h2v2zm0-4H4V5h2v2zm4 12H8v-2h2v2zm0-4H8v-2h2v2zm0-4H8V9h2v2zm0-4H8V5h2v2zm10 12h-8v-2h2v-2h-2v-2h2v-2h-2V9h8v10zm-2-8h-2v2h2v-2zm0 4h-2v2h2v-2z" />
                      </svg>
                      <p className="text-xl font-bold">ABOUT THE EMPLOYER</p>
                    </div>

                    <div className="rounded-lg  bg-white px-6">
                      <div className="space-y-1 text-left text-black">
                        {[
                          {
                            label: "Company Name",
                            value: job.companyName,
                          },
                          { label: "Industry", value: job.industry },
                          {
                            label: "Company Address",
                            value: job.companyAddress,
                          },
                          { label: "Company Email", value: job.companyEmail },
                          {
                            label: "Company Contact Number",
                            value: job.companyContactNumber,
                          },
                          {
                            label: "Job Application URL",
                            value: job.companyWebsite,
                          },
                        ].map((item, index) => (
                          <div
                            key={index}
                            className="flex items-center justify-between"
                          >
                            <p className="w-1/2 text-sm text-black">
                              {item.label}
                            </p>
                            <p className="w-1/2 rounded bg-gray-200 p-2 font-medium text-black">
                              {item.value}
                            </p>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="max-h-[100vh] w-[800px] overflow-hidden rounded-lg bg-white shadow-xl"></div>
      </>
    );
  };

  const AddJobModal = ({ isOpen, onClose, onSubmit }) => {
    const [formData, setFormData] = useState({
      title: "",
      overview: "",
      date: new Date(),
      startTime: "",
      endTime: "",
      skillRequirements: "",
      companyName: "",
      industry: "",
      companyAddress: "",
      companyEmail: "",
      companyWebsite: "",
      companyContactNumber: "",
      salary: "",
      type: "job",
      center_id: userData.center_id,
      user_id: userData.id,
      is_deleted: false,
      published: false,
      closed: false,
      backgroundImage: "",
    });

    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    };
    const [isUploading, setIsUploading] = useState(false);

    const handleFileUpload = (e) => {
      const file = e.target.files[0];
      if (!file) return;

      setIsUploading(true);
      const storage = getStorage();
      const storageRef = ref(storage, `backgroundImages/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        null,
        (error) => {
          console.error("File upload error:", error);
          setIsUploading(false);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          setFormData({ ...formData, backgroundImage: downloadURL });
          setIsUploading(false);
          console.log("File uploaded successfully:", downloadURL);
        }
      );
    };

    const handleSubmit = async (e) => {
      e.preventDefault();

      const requiredFields = Object.keys(formData);
      const emptyFields = requiredFields.filter(
        (key) =>
          typeof formData[key] === "string" && formData[key].trim() === ""
      );

      if (emptyFields.length > 0) {
        alert(`Please fill in all required fields: ${emptyFields.join(", ")}`);
        return;
      }

      try {
        const jobDocRef = doc(collection(db, "opportunities"));
        const jobId = jobDocRef.id;
        const jobData = { ...formData, id: jobId };
        await setDoc(jobDocRef, jobData);
        onSubmit(jobData);
        setFormData({
          title: "",
          overview: "",
          date: new Date(),
          startTime: "",
          endTime: "",
          skillRequirements: "",
          companyName: "",
          industry: "",
          companyAddress: "",
          companyEmail: "",
          companyWebsite: "",
          companyContactNumber: "",
          salary: "",
          type: "job",
          center_id: userData.center_id,
          user_id: userData.id,
          is_deleted: false,
          published: false,
          closed: false,
          backgroundImage: "",
        });

        onClose();
        fetchJobs();
      } catch (error) {
        console.error("Error adding job:", error);
      }
    };

    if (!isOpen) return null;

    return (
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
        <div>
          <div className="bg-header-panel flex w-[800px] justify-between rounded-t-lg p-4">
            <p className="font-semibold text-[#FDB841]">
              Add New Job Opportunity
            </p>
            <button
              onClick={onClose}
              className="bg-header-panel cursor-pointer text-[#FDB841] hover:text-gray-200"
            >
              <svg
                className="h-4 w-4"
                fill="#FDB841"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div className="max-h-[80vh] w-[800px] overflow-y-auto rounded-b-lg bg-white p-5 shadow-lg">
            <form onSubmit={handleSubmit}>
              <div className="mb-4 space-y-4">
                <div>
                  <p className="mb-1 text-sm text-[#467BA4]">Job Title</p>
                  <TextBoxComponent
                    cssClass="e-outline"
                    name="title"
                    value={formData.title}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div>
                  <p className="mb-1 text-sm text-[#467BA4]">Job Overview</p>
                  <textarea
                    className="w-full resize-y rounded-md border border-gray-300 p-2 font-inter focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
                    rows={2}
                    name="overview"
                    value={formData.overview}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div>
                  <p className="mb-1 text-sm text-[#467BA4]">
                    Skill Requirements
                  </p>
                  <textarea
                    className="w-full resize-y rounded-md border border-gray-300 p-2 font-inter focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
                    rows={2}
                    name="skillRequirements"
                    value={formData.skillRequirements}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div>
                  <div className="space-y-2">
                    <div className="flex gap-4">
                      <div className="form-control w-1/2">
                        <p className="mb-1 text-sm text-[#467BA4]">
                          Start Job Time
                        </p>
                        <TimePickerComponent
                          cssClass="e-outline"
                          name="startTime"
                          value={formData.startTime}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              startTime: e.target.value,
                            })
                          }
                          required
                        />
                      </div>
                      <div className="form-control w-1/2">
                        <p className="mb-1 text-sm text-[#467BA4]">
                          End Job Time
                        </p>
                        <TimePickerComponent
                          cssClass="e-outline"
                          name="endTime"
                          value={formData.endTime}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              endTime: e.target.value,
                            })
                          }
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <label className="mb-1 block text-sm font-medium text-[#467BA4]">
                    Salary
                  </label>
                  <NumericTextBoxComponent
                    cssClass="e-outline"
                    name="salary"
                    value={formData.salary}
                    format="C2"
                    decimals={2}
                    change={(e) =>
                      setFormData({ ...formData, salary: e.value })
                    }
                    required
                    showSpinButton={false}
                  />
                  <div className="space-y-2 text-sm text-[#467BA4]">
                    {[
                      { label: "Company Name", name: "companyName" },
                      { label: "Industry", name: "industry" },
                      { label: "Company Address", name: "companyAddress" },
                      { label: "Company Email", name: "companyEmail" },
                      {
                        label: "Company Contact Number",
                        name: "companyContactNumber",
                      },
                      { label: "Job Application URL", name: "companyWebsite" },
                    ].map((field, index) => (
                      <div key={index} className="form-control">
                        <label className="text-gray-700 mb-1 block text-sm font-medium">
                          {field.label}
                        </label>
                        <TextBoxComponent
                          cssClass="e-outline"
                          name={field.name}
                          value={formData[field.name]}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    ))}
                  </div>

                  <div>
                    <p className="mb-1 text-sm text-[#467BA4]">
                      Background Image
                    </p>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleFileUpload}
                      className="text-gray-900 block w-full cursor-pointer rounded-lg border border-gray-300 text-sm focus:outline-none"
                    />
                    {isUploading && (
                      <p className="text-sm text-blue-500">
                        Uploading image...
                      </p>
                    )}
                    {formData.backgroundImage && (
                      <img
                        src={formData.backgroundImage}
                        alt="Background Preview"
                        className="mt-2 h-40 w-full rounded object-cover"
                      />
                    )}
                  </div>
                </div>
              </div>

              <div className="flex justify-end space-x-4">
                <button
                  className="btn-cancel flex cursor-pointer gap-1 px-3 py-1.5"
                  type="button"
                  onClick={onClose}
                >
                  Cancel
                </button>
                <button
                  className="btn-default flex cursor-pointer gap-1 px-3 py-1.5"
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  };

  const EditJobModal = ({ isOpen, onClose, job, onSubmit }) => {
    const [formData, setFormData] = useState({ ...job });
    const [isUploading, setIsUploading] = useState(false);

    useEffect(() => {
      if (job) {
        setFormData({
          ...job,
          startTime: job.startTime ? job.startTime.toDate() : null,
          endTime: job.endTime ? job.endTime.toDate() : null,
        });
      }
    }, [job]);

    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    };

    const handleDateChange = (field, date) => {
      setFormData({ ...formData, [field]: date });
    };

    const handleFileUpload = (e) => {
      const file = e.target.files[0];
      if (!file) return;

      setIsUploading(true);
      const storage = getStorage();
      const storageRef = ref(storage, `backgroundImages/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        null,
        (error) => {
          console.error("File upload error:", error);
          setIsUploading(false);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          setFormData({ ...formData, backgroundImage: downloadURL });
          setIsUploading(false);
          console.log("File uploaded successfully:", downloadURL);
        }
      );
    };

    const handleSubmit = async (e) => {
      e.preventDefault();

      try {
        const jobDocRef = doc(db, "opportunities", formData.id);
        const updatedData = {
          ...formData,
          startTime: formData.startTime ? formData.startTime : null,
          endTime: formData.endTime ? formData.endTime : null,
        };

        await updateDoc(jobDocRef, updatedData);
        onSubmit(updatedData);
        onClose();
      } catch (error) {
        console.error("Error updating job:", error);
      }
    };

    if (!isOpen || !job) return null;

    return (
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
        <div>
          <div className="bg-header-panel flex w-[800px] justify-between rounded-t-lg p-4">
            <p className="font-semibold text-[#FDB841]">Edit Job Opportunity</p>
            <button
              onClick={onClose}
              className="bg-header-panel cursor-pointer text-[#FDB841] hover:text-gray-200"
            >
              <svg
                className="h-4 w-4"
                fill="#FDB841"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div className="max-h-[80vh] w-[800px] overflow-y-auto rounded-b-lg bg-white p-5 shadow-lg">
            <form onSubmit={handleSubmit}>
              <div className="mb-4 space-y-4">
                <div>
                  <p className="mb-1 text-sm text-[#467BA4]">Job Title</p>
                  <TextBoxComponent
                    cssClass="e-outline"
                    name="title"
                    value={formData.title}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div>
                  <p className="mb-1 text-sm text-[#467BA4]">Job Overview</p>
                  <textarea
                    className="w-full resize-y rounded-md border border-gray-300 p-2 font-inter focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
                    rows={2}
                    name="overview"
                    value={formData.overview}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div>
                  <p className="mb-1 text-sm text-[#467BA4]">
                    Skill Requirements
                  </p>
                  <textarea
                    className="w-full resize-y rounded-md border border-gray-300 p-2 font-inter focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
                    rows={2}
                    name="skillRequirements"
                    value={formData.skillRequirements}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div>
                  <div className="space-y-2">
                    <div className="flex gap-4">
                      <div className="form-control w-1/2">
                        <p className="mb-1 text-sm text-[#467BA4]">
                          Start Job Time
                        </p>
                        <TimePickerComponent
                          cssClass="e-outline"
                          name="startTime"
                          value={formData.startTime}
                          onChange={(e) =>
                            handleDateChange("startTime", e.value)
                          }
                          required
                        />
                      </div>
                      <div className="form-control w-1/2">
                        <p className="mb-1 text-sm text-[#467BA4]">
                          End Job Time
                        </p>
                        <TimePickerComponent
                          cssClass="e-outline"
                          name="endTime"
                          value={formData.endTime}
                          onChange={(e) => handleDateChange("endTime", e.value)}
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <label className="mb-1 block text-sm font-medium text-[#467BA4]">
                    Salary
                  </label>
                  <NumericTextBoxComponent
                    cssClass="e-outline"
                    name="salary"
                    value={formData.salary}
                    format="C2"
                    decimals={2}
                    change={(e) =>
                      setFormData({ ...formData, salary: e.value })
                    }
                    required
                    showSpinButton={false}
                  />
                </div>
                <div className="space-y-2 text-sm text-[#467BA4]">
                  {[
                    { label: "Company Name", name: "companyName" },
                    { label: "Industry", name: "industry" },
                    { label: "Company Address", name: "companyAddress" },
                    { label: "Company Email", name: "companyEmail" },
                    {
                      label: "Company Contact Number",
                      name: "companyContactNumber",
                    },
                    { label: "Job Application URL", name: "companyWebsite" },
                  ].map((field, index) => (
                    <div key={index} className="form-control">
                      <label className="text-gray-700 mb-1 block text-sm font-medium">
                        {field.label}
                      </label>
                      <TextBoxComponent
                        cssClass="e-outline"
                        name={field.name}
                        value={formData[field.name]}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  ))}
                </div>
                <div>
                  <p className="mb-1 text-sm text-[#467BA4]">
                    Background Image
                  </p>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleFileUpload}
                    className="text-gray-900 block w-full cursor-pointer rounded-lg border border-gray-300 text-sm focus:outline-none"
                  />
                  {isUploading && (
                    <p className="text-sm text-blue-500">Uploading image...</p>
                  )}
                  {formData.backgroundImage && (
                    <img
                      src={formData.backgroundImage}
                      alt="Background Preview"
                      className="mt-2 h-40 w-full rounded object-cover"
                    />
                  )}
                </div>
              </div>
              <div className="flex justify-end space-x-4">
                <button
                  className="btn-cancel flex cursor-pointer gap-1 px-3 py-1.5"
                  type="button"
                  onClick={onClose}
                >
                  Cancel
                </button>
                <button
                  className="btn-default flex cursor-pointer gap-1 px-3 py-1.5"
                  type="submit"
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  };

  const ConfirmationModal = ({ type, onConfirm, onCancel }) => {
    return (
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
        <div>
          <div className="bg-header-panel flex w-[800px] justify-between rounded-t-lg p-4">
            <p className="font-semibold text-[#FDB841]">
              {type === "delete"
                ? "Delete Job Opportunity"
                : "Close Job Opportunity"}
            </p>
          </div>
          <div className="max-h-[80vh] w-[800px] overflow-y-auto rounded-b-lg bg-white p-5 shadow-lg">
            <div>
              <p>
                Are you sure you want to{" "}
                {type === "delete"
                  ? "delete this job opportunity?"
                  : "close this job opportunity?"}
              </p>
              <div className="flex justify-end space-x-4">
                <button
                  className="btn-cancel flex cursor-pointer gap-1 px-3 py-1.5"
                  onClick={onCancel}
                >
                  Cancel
                </button>
                <button
                  className="btn-default flex cursor-pointer gap-1 px-3 py-1.5"
                  onClick={onConfirm}
                >
                  {type === "delete" ? "Delete" : "Close"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  if (isLoading) {
    return (
      <div className="absolute inset-0 z-50 flex items-center justify-center bg-opacity-10 backdrop-blur-md">
        <div className="text-2xl font-bold text-blue-500">Loading...</div>
      </div>
    );
  }

  return (
    <div
      className={`m-0 box-border flex w-full max-w-full flex-col items-start justify-start gap-2.5 px-2.5 py-[15px] leading-[normal] tracking-[normal] ${className}`}
    >
      {isConfirmationModalOpen && (
        <ConfirmationModal
          type={confirmationType}
          onConfirm={
            confirmationType === "delete" ? handleDeleteJob : handleToggleClose
          }
          onCancel={closeConfirmationModal}
        />
      )}
      <AddJobModal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        onSubmit={handleAddSubmit}
      />

      <EditJobModal
        isOpen={isEditModalOpen}
        onClose={handleEditModalClose}
        job={selectedJob}
        onSubmit={handleEditSubmit}
      />
      <div id="dialog-container"></div>
      <div className="flex w-full justify-between">
        <div className="w-full">
          <div className="flex flex-row items-start justify-start gap-1.5 py-0 pl-0 pr-5">
            <p className="relative inline-block min-w-[80px] text-left  text-xs font-medium leading-[16px] text-dodgerblue [text-decoration:none]">
              Opportunities
            </p>
            <div className="relative inline-block min-w-[5px] text-left font-mulish text-base font-semibold leading-[16px] text-gray-400">
              /
            </div>
            <p className="relative inline-block min-w-[77px] text-left  text-xs font-medium leading-[16px] text-dodgerblue [text-decoration:none]">
              Job Opportunities
            </p>
          </div>

          <div className="flex w-full justify-end">
            <button
              type="button"
              className="btn-default flex cursor-pointer gap-1 px-3 py-1.5"
              onClick={handleJobClick}
            >
              <FaPlus className="h-3 w-3" />
              Add New Job
            </button>
          </div>
          <div className="mt-4 flex w-full flex-wrap gap-6 text-sm text-darkslategray-200">
            {jobs.map((job) => (
              <JobCard job={job.job} fetchJobs={fetchJobs} />
            ))}
          </div>
        </div>
        <div className="relative">
          {selectedJob && !isEditModalOpen && (
            <JobDetailsModal
              job={selectedJob}
              onClose={() => setSelectedJob(null)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

PartnerJobOpportunities.propTypes = {
  className: PropTypes.string,
};

export default PartnerJobOpportunities;
