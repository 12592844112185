export let data = [
  {
    disasterID: 1,
    disasterName: "Fire",
    disasterStatus: "Inactive",
    disasterDate: "06/31/2024",
    disasterType: "Industrial Fire",
    alertLevel: "Level 1",
    location: "Michigan",
  },
  {
    disasterID: 2,
    disasterName: "Flood",
    disasterStatus: "Active",
    disasterDate: "06/31/2024",
    disasterType: "Flood",
    alertLevel: "Level 3",
    location: "Arizona",
  },
  {
    disasterID: 3,
    disasterName: "Typhoon",
    disasterStatus: "Inactive",
    disasterDate: "06/31/2024",
    disasterType: "Typhoon",
    alertLevel: "Level 2",
    location: "California",
  },
  {
    disasterID: 4,
    disasterName: "Earthquake",
    disasterStatus: "Active",
    disasterDate: "06/31/2024",
    disasterType: "Earthquake",
    alertLevel: "Level 1",
    location: "Hawaii",
  },
];

export const commContacts = [
  {
    contactID: 1,
    first_name: "Chris",
    last_name: "Evans",
    occupation: "Miner",
    mobileNumber: "(555-3672-24)",
    email: "chris@mail.com",
    address1: "",
    address2: "15205 North Kierland Blvd. Suite 100.",
    city: "Scottsdale",
    state: "AZ",
    zip: "85254",
  },
  {
    contactID: 2,
    first_name: "Evans",
    last_name: "Evans",
    occupation: "Factory Worker",
    mobileNumber: "(555-3672-24)",
    email: "ev@mail.com",
    address1: "",
    address2: "15205 North Kierland Blvd. Suite 100.",
    city: "Scottsdale",
    state: "AL",
    zip: "85254",
  },
  {
    contactID: 3,
    first_name: "Lovi",
    last_name: "Evans",
    occupation: "Miner",
    mobileNumber: "(555-3672-24)",
    email: "lov@mail.com",
    address1: "",
    address2: "15205 North Kierland Blvd. Suite 100.",
    city: "Scottsdale",
    state: "AZ",
    zip: "85254",
  },
];

export let localNPC = [
  {
    npcID: 1,
    primary_county: "McCracken County",
    organization: "McCracken County West Kentucky Allied",
    primary_service_provided: "Social Services",
    mobileNumber: "(555-3672-24)",
    email: "chris@mail.com",
    address1: "",
    address2: "15205 North Kierland Blvd. Suite 100.",
    city: "Scottsdale",
    state: "AZ",
    zip: "85254",
  },
  {
    npcID: 1,
    primary_county: "McCracken County",
    organization: "United Way of Paducah/McCracken County",
    primary_service_provided: "Recovery Support Leader",
    mobileNumber: "(555-3672-24)",
    email: "chris@mail.com",
    address1: "",
    address2: "15205 North Kierland Blvd. Suite 100.",
    city: "Scottsdale",
    state: "AZ",
    zip: "85254",
  },
  {
    npcID: 1,
    primary_county: "McCracken County",
    organization: "American Red Cross",
    primary_service_provided: "Red Cross Center",
    mobileNumber: "(555-3672-24)",
    email: "chris@mail.com",
    address1: "",
    address2: "15205 North Kierland Blvd. Suite 100.",
    city: "Scottsdale",
    state: "AZ",
    zip: "85254",
  },
];

export const USStates = [
  { stateID: 1, name: "Alabama", abbreviation: "AL" },
  { stateID: 2, name: "Alaska", abbreviation: "AK" },
  { stateID: 3, name: "Arizona", abbreviation: "AZ" },
  { stateID: 4, name: "Arkansas", abbreviation: "AR" },
  { stateID: 5, name: "California", abbreviation: "CA" },
  { stateID: 6, name: "Colorado", abbreviation: "CO" },
  { stateID: 7, name: "Connecticut", abbreviation: "CT" },
  { stateID: 8, name: "Delaware", abbreviation: "DE" },
  { stateID: 9, name: "Florida", abbreviation: "FL" },
  { stateID: 10, name: "Georgia", abbreviation: "GA" },
  { stateID: 11, name: "Hawaii", abbreviation: "HI" },
  { stateID: 12, name: "Idaho", abbreviation: "ID" },
  { stateID: 13, name: "Illinois", abbreviation: "IL" },
  { stateID: 14, name: "Indiana", abbreviation: "IN" },
  { stateID: 15, name: "Iowa", abbreviation: "IA" },
  { stateID: 16, name: "Kansas", abbreviation: "KS" },
  { stateID: 17, name: "Kentucky", abbreviation: "KY" },
  { stateID: 18, name: "Louisiana", abbreviation: "LA" },
  { stateID: 19, name: "Maine", abbreviation: "ME" },
  { stateID: 20, name: "Maryland", abbreviation: "MD" },
  { stateID: 21, name: "Massachusetts", abbreviation: "MA" },
  { stateID: 22, name: "Michigan", abbreviation: "MI" },
  { stateID: 23, name: "Minnesota", abbreviation: "MN" },
  { stateID: 24, name: "Mississippi", abbreviation: "MS" },
  { stateID: 25, name: "Missouri", abbreviation: "MO" },
  { stateID: 26, name: "Montana", abbreviation: "MT" },
  { stateID: 27, name: "Nebraska", abbreviation: "NE" },
  { stateID: 28, name: "Nevada", abbreviation: "NV" },
  { stateID: 29, name: "New Hampshire", abbreviation: "NH" },
  { stateID: 30, name: "New Jersey", abbreviation: "NJ" },
  { stateID: 31, name: "New Mexico", abbreviation: "NM" },
  { stateID: 32, name: "New York", abbreviation: "NY" },
  { stateID: 33, name: "North Carolina", abbreviation: "NC" },
  { stateID: 34, name: "North Dakota", abbreviation: "ND" },
  { stateID: 35, name: "Ohio", abbreviation: "OH" },
  { stateID: 36, name: "Oklahoma", abbreviation: "OK" },
  { stateID: 37, name: "Oregon", abbreviation: "OR" },
  { stateID: 38, name: "Pennsylvania", abbreviation: "PA" },
  { stateID: 39, name: "Rhode Island", abbreviation: "RI" },
  { stateID: 40, name: "South Carolina", abbreviation: "SC" },
  { stateID: 41, name: "South Dakota", abbreviation: "SD" },
  { stateID: 42, name: "Tennessee", abbreviation: "TN" },
  { stateID: 43, name: "Texas", abbreviation: "TX" },
  { stateID: 44, name: "Utah", abbreviation: "UT" },
  { stateID: 45, name: "Vermont", abbreviation: "VT" },
  { stateID: 46, name: "Virginia", abbreviation: "VA" },
  { stateID: 47, name: "Washington", abbreviation: "WA" },
  { stateID: 48, name: "West Virginia", abbreviation: "WV" },
  { stateID: 49, name: "Wisconsin", abbreviation: "WI" },
  { stateID: 50, name: "Wyoming", abbreviation: "WY" },
];

export const countries = [
  {
    countryId: "1",
    name: "United States",
    abbreviation: "US",
  },
  {
    countryId: "2",
    name: "United Kingdom",
    abbreviation: "UK",
  },
  {
    countryId: "3",
    name: "Canada",
    abbreviation: "CA",
  },
  {
    countryId: "4",
    name: "Mexico",
    abbreviation: "MX",
  },
];

export let householdMembers = [
  {
    householdMemberID: 1,
    firstName: "John",
    lastName: "Doe",
    dateOfBirth: "08/05/1996",
    relationship: "Husband",
    disability: "None",
  },
  {
    householdMemberID: 2,
    firstName: "Jane",
    lastName: "Doe",
    dateOfBirth: "12/12/1996",
    relationship: "Wife",
    disability: "Deaf",
  },
  {
    householdMemberID: 3,
    firstName: "Oliver",
    lastName: "Davis",
    dateOfBirth: "01/01/1990",
    relationship: "Kabit",
    disability: "Visually Impaired",
  },
];

export let pets = [
  {
    petID: 1,
    petName: "Snowball",
    petBreed: "Golden Retriever",
    petColor: "Golden",
    petAge: "5",
    petSize: "Large",
  },
  {
    petID: 2,
    petName: "Luna",
    petBreed: "Persian",
    petColor: "White",
    petAge: "3",
    petSize: "Small",
  },
  {
    petID: 3,
    petName: "Bubbles",
    petBreed: "Parrot",
    petColor: "Green",
    petSize: "Small",
  },
];

export const surveyQuestions = [
  {
    section: "General Survey Questions",
    questions: [
  {
    question: "Was the impacted address your primary address?",
    type: "radio",
    options: ["Yes", "No"],
  },
  {
    question: "Have you applied for FEMA assistance, if available?",
    type: "radio",
    options: ["Yes", "No"],
  },
  {
    question: "What is your current living situation?",
    type: "radio",
    options: [
      "I am safely living inside my home.",
      "I am displaced with at the residence of a relative or friend.",
      "I am displaced at a hotel or other form of temporary housing or shelter.",
      "I am homeless.",
    ],
  },
  {
    question: "Was the impacted property your primary residence?",
    type: "radio",
    options: [
      "Yes",
      "The impacted home was a second residence.",
      "The impacted home was an investment property.",
      "Rent",
      "Own",
      "Rent To Own",
    ],
  },
  {
    question: "Do you have insurance on your home or contents?",
    type: "checkbox",
    options: [
      "Homeowner's insurance",
      "Flood insurance",
      "Renter's insurance",
      "Earthquake additional coverage",
      "Other disaster related additional coverage",
      "I did not have insurance",
    ],
  },
  {
    question: "What type of structure is the impacted home?",
    type: "radio",
    options: [
      "Single family dwelling",
      "Mobile or manufactured",
      "Condo",
      "Other",
    ],
  },
  {
    question: "What is the size of the impacted home?",
    type: "radio",
    options: [
      "Under 1,000 square feet",
      "1,001-1,500 square feet",
      "1,501-2,500 square feet",
      "2,501-3,500 square feet",
      "Greater than 3,500 square feet",
    ],
  },
  {
    question: "How old is the impacted home?",
    type: "radio",
    options: [
      "Under 10 years old",
      "11-25 years old",
      "26-50 years old",
      "Over 50 years old",
    ],
  },
  {
    question: "Which of the following describes to the impacted home?",
    type: "radio",
    options: [
      "The home has a basement.",
      "The home has a crawl space.",
      "The home DOES NOT have a basement or a crawl space.",
      "Electrical, plumbing, or HVAC ductwork run underneath the home.",
    ],
  },
  {
    question: "The impacted home:",
    type: "radio",
    options: ["Is destroyed", "Has major damage", "Has minor damage"],
  },
  {
    question: "Does the impacted home have running water?",
    type: "radio",
    options: [
      "Yes, the well or public water connection is working",
      "No, public water connection is NOT working",
      "No, the well is NOT working",
    ],
  },
  {
    question: "Does the impacted home have working electric/gas?",
    type: "radio",
    options: [
      "Yes, the home has working electric/gas.",
      "No, the home does NOT have working electric/gas",
    ],
  },
  {
    question: "Is there water damage inside of the impacted home?",
    type: "radio",
    options: [
      "Yes, there was standing water greater than 12 inches in the home.",
      "Yes, there was standing water less than 12 inches in the home.",
      "Yes, the interior and contents took on water from rain or busted pipes.",
      "No, water did not come inside the home.",
    ],
  },
  {
    question: "There is visible damage to the home's:",
    type: "checkbox",
    options: [
      "Check All",
      "Siding",
      "Flooring",
      "Cabinets",
      "Shed/garage",
      "Roof",
      "Foundation",
      "Floor Joists",
      "HVAC",
      "Driveway",
      "Gutters",
      "Ceiling",
      "Drywall",
      "Electrical",
      "Yard/Uprooted trees",
      "Other",
    ],
  },
  {
    question: "Was your vehicle impacted?",
    type: "radio",
    options: [
      "Yes, my vehicle was majorly damaged.",
      "Yes, my windows were damaged.",
      "No",
    ],
  },
  {
    question: "How many vehicles were impacted?",
    type: "text",
    placeholder: "e.g. 1",
  },
  {
    question: "What type of insurance did you have on the impacted vehicle(s)?",
    type: "radio",
    options: [
      "Liability only",
      "Full coverage",
      "I did not have automobile insurance",
    ],
  },
  {
    question:
      "Do you need assistance with removal of hazardous materials from your property?",
    type: "radio",
    options: ["Yes", "No"],
  },
  {
    question: "Do you need immediate assistance with debris removal?",
    type: "radio",
    options: ["Yes", "No"],
  },
  {
    question: "Do you need immediate assistance tarping your roof?",
    type: "radio",
    options: ["Yes", "No"],
  },
  {
    question: "Do you need immediate assistance with boarding windows?",
    type: "radio",
    options: ["Yes", "No"],
  },
  {
    question: "Do you need immediate assistance with heat or cooling?",
    type: "radio",
    options: ["Yes", "No"],
  },
  {
    question: "Do you need immediate supplies such as food and water?",
    type: "radio",
    options: ["Yes", "No"],
  },
]
}
];

export const fireQuestions = [
  // {
  //   section: "Personal and Household Information",
  //   questions: [
  //     {
  //       question: "Full Name",
  //       type: "text",
  //       options: ["Enter your full name"],
  //       placeholder: "John Doe"
  //     },
  //     {
  //       question: "Address",
  //       type: "text",
  //       options: ["Enter your address"],
  //       placeholder: "123 Main St, City, State, ZIP"
  //     },
  //     {
  //       question: "Phone Number",
  //       type: "text",
  //       options: ["Enter your phone number"],
  //       placeholder: "(555) 555-5555"
  //     },
  //     {
  //       question: "Number of Household Members",
  //       type: "text",
  //       options: ["Enter number"],
  //       placeholder: "e.g., 4"
  //     },
  //     {
  //       question: "Are there children, elderly, or individuals with disabilities in the household?",
  //       type: "radio",
  //       options: ["Yes", "No"],
  //       followUp: {
  //         condition: "Yes",
  //         question: "Please specify details about household members",
  //         type: "text",
  //         placeholder: "e.g., 2 children under 10, 1 elderly person, 1 person with mobility disability"
  //       }
  //     }
  //   ]
  // },
  {
    section: "Section 1: Immediate Safety and Health Concerns",
    questions: [
      {
        question: "Is everyone in your household currently safe and accounted for?",
        type: "radio",
        options: ["Yes", "No"],
        followUp: {
          condition: "No",
          question: "If no, please specify who is missing or injured:",
          type: "text",
          placeholder: "Enter details here"
        }
      },
      {
        question: "Has anyone in your household experienced injuries (burns, smoke inhalation, etc.)?",
        type: "radio",
        options: ["Yes", "No"],
        followUp: {
          condition: "Yes",
          question: "If yes, please describe the nature of the injuries:",
          type: "text",
          placeholder: "Enter injury details"
        }
      },
      {
        question: "Do you or anyone in your household require urgent medical attention?",
        type: "radio",
        options: ["Yes", "No"],
        followUp: {
          condition: "Yes",
          question: "If yes, what kind of medical assistance is needed?",
          type: "text",
          placeholder: "Describe medical assistance needed"
        }
      },
      {
        question: "Is your home or immediate area still under threat from fire or smoke?",
        type: "radio",
        options: ["Yes", "No", "Unsure"]
      },
      {
        question: "Do you have access to clean water and food for the next 24 hours?",
        type: "radio",
        options: ["Yes", "No"]
      }
    ]
  },
  {
    section: "Section 2: Housing and Shelter Needs",
    questions: [
      {
        question: "Is your home habitable?",
        type: "radio",
        options: ["Yes", "No"],
        followUp: {
          condition: "No",
          question: "If no, please describe the extent of damage:",
          type: "text",
          placeholder: "Enter damage details"
        }
      },
      {
        question: "Do you currently have access to temporary shelter?",
        type: "radio",
        options: ["Yes (Staying with family, friends, shelter, etc.)", "No"],
        followUp: {
          condition: "No",
          question: "If no, do you need emergency shelter assistance?",
          type: "radio",
          options: ["Yes", "No"]
        }
      },
      {
        question: "If you are currently in a shelter, how long can you stay?",
        type: "radio",
        options: [
          "1 day or less",
          "2-3 days",
          "1 week or more",
          "No shelter available"
        ]
      }
    ]
  },
  {
    section: "Section 3: Access to Utilities and Essential Services",
    questions: [
      {
        question: "Do you have access to power, heating/cooling, and basic utilities in your home or temporary shelter?",
        type: "radio",
        options: ["Yes", "No"]
      },
      {
        question: "Do you have access to essential services (e.g., hospital, pharmacy, fire station)?",
        type: "radio",
        options: ["Yes", "No"]
      },
      {
        question: "Have you lost important personal documentation (e.g., ID, insurance papers, medical records)?",
        type: "radio",
        options: ["Yes", "No"],
        followUp: {
          condition: "Yes",
          question: "If yes, please specify:",
          type: "text",
          placeholder: "Enter lost documentation details"
        }
      }
    ]
  },
  {
    section: "Section 4: Financial and Recovery Resources",
    questions: [
      {
        question: "Do you have insurance coverage for fire damage to your home or personal belongings?",
        type: "radio",
        options: ["Yes", "No", "Unsure"]
      },
      {
        question: "Are you currently in need of financial assistance for recovery?",
        type: "radio",
        options: ["Yes", "No"]
      },
      {
        question: "Have you applied for or received any assistance from local or federal agencies (FEMA, Red Cross, etc.)?",
        type: "radio",
        options: ["Yes", "No"]
      },
      {
        question: "Do you have transportation to access food, supplies, or medical services?",
        type: "radio",
        options: ["Yes", "No"]
      }
    ]
  },
  {
    section: "Section 5: Emotional and Psychological Well-being",
    questions: [
      {
        question: "Is anyone in your household currently experiencing significant emotional distress or trauma?",
        type: "radio",
        options: ["Yes", "No"],
        followUp: {
          condition: "Yes",
          question: "If yes, would you like assistance or counseling services?",
          type: "radio",
          options: ["Yes", "No"]
        }
      }
    ]
  },
  {
    section: "Section 6: Additional Concerns and Needs",
    questions: [
      {
        question: "Are there any other immediate concerns or needs you would like to share?",
        type: "radio",
        options: ["Yes", "No"],
        followUp: {
          condition: "Yes",
          question: "If yes, please specify:",
          type: "text",
          placeholder: "Enter your concerns or needs"
        }
      }
    ]
  }
];

export const pastRecoveryPlan = [
  {
    categoryID: 1,
    category: "General devris removal",
    subCategory: "General",
    descOfDamage: "House burned down",
    photoAttachment: "Burned House",
    estimateToRepair: "$12.00",
    donated: "House",
    valueOfDonated: "$500.00",
    otherSource: "Insurance",
    amountOfFunding: "$12.00",
    fundingGap: "$12.00",
  },
  {
    categoryID: 2,
    category: "General devris removal",
    subCategory: "Design and Planning",
    descOfDamage: "House burned down",
    photoAttachment: "Burned House",
    estimateToRepair: "$12.00",
    donated: "House",
    valueOfDonated: "$500.00",
    otherSource: "Insurance",
    amountOfFunding: "$12.00",
    fundingGap: "$12.00",
  },
  {
    categoryID: 3,
    category: "General devris removal",
    subCategory: "Design and Planning",
    descOfDamage: "House burned down",
    photoAttachment: "Burned House",
    estimateToRepair: "$12.00",
    donated: "House",
    valueOfDonated: "$500.00",
    otherSource: "Insurance",
    amountOfFunding: "$12.00",
    fundingGap: "$12.00",
  },
  {
    categoryID: 4,
    category: "General devris removal",
    subCategory: "Design and Planning",
    descOfDamage: "House burned down",
    photoAttachment: "Burned House",
    estimateToRepair: "$12.00",
    donated: "House",
    valueOfDonated: "$500.00",
    otherSource: "Insurance",
    amountOfFunding: "$12.00",
    fundingGap: "$12.00",
  },
  {
    categoryID: 5,
    category: "General devris removal",
    subCategory: "Design and Planning",
    descOfDamage: "House burned down",
    photoAttachment: "Burned House",
    estimateToRepair: "$12.00",
    donated: "House",
    valueOfDonated: "$500.00",
    otherSource: "Insurance",
    amountOfFunding: "$12.00",
    fundingGap: "$12.00",
  },
  {
    categoryID: 6,
    category: "Tree Removal",
    subCategory: "Design and Planning",
    descOfDamage: "House burned down",
    photoAttachment: "Burned House",
    estimateToRepair: "$12.00",
    donated: "House",
    valueOfDonated: "$500.00",
    otherSource: "Insurance",
    amountOfFunding: "$12.00",
    fundingGap: "$12.00",
  },
  {
    categoryID: 7,
    category: "Tree Removal",
    subCategory: "Design and Planning",
    descOfDamage: "House burned down",
    photoAttachment: "Burned House",
    estimateToRepair: "$12.00",
    donated: "House",
    valueOfDonated: "$500.00",
    otherSource: "Insurance",
    amountOfFunding: "$12.00",
    fundingGap: "$12.00",
  },
  {
    categoryID: 8,
    category: "Tree Removal",
    subCategory: "Design and Planning",
    descOfDamage: "House burned down",
    photoAttachment: "Burned House",
    estimateToRepair: "$12.00",
    donated: "House",
    valueOfDonated: "$500.00",
    otherSource: "Insurance",
    amountOfFunding: "$12.00",
    fundingGap: "$12.00",
  },
  {
    categoryID: 9,
    category: "Tree Removal",
    subCategory: "Design and Planning",
    descOfDamage: "House burned down",
    photoAttachment: "Burned House",
    estimateToRepair: "$12.00",
    donated: "House",
    valueOfDonated: "$500.00",
    otherSource: "Insurance",
    amountOfFunding: "$12.00",
    fundingGap: "$12.00",
  },
  {
    categoryID: 10,
    category: "Tree Removal",
    subCategory: "Design and Planning",
    descOfDamage: "House burned down",
    photoAttachment: "Burned House",
    estimateToRepair: "$12.00",
    donated: "House",
    valueOfDonated: "$500.00",
    otherSource: "Insurance",
    amountOfFunding: "$12.00",
    fundingGap: "$12.00",
  },
  {
    categoryID: 11,
    category: "Tree Removal",
    subCategory: "Design and Planning",
    descOfDamage: "House burned down",
    photoAttachment: "Burned House",
    estimateToRepair: "$12.00",
    donated: "House",
    valueOfDonated: "$500.00",
    otherSource: "Insurance",
    amountOfFunding: "$12.00",
    fundingGap: "$12.00",
  },
];

export const disasterContacts = [
  {
    contactID: 1,
    name: "Chris",
    occupation: "Miner",
    organization: "Construction",
    mobile: "(555-3672-24)",
    email: "chris@mail.com",
    notes: "",
    action: "",
  },
  {
    contactID: 2,
    name: "Jane",
    occupation: "Miner",
    organization: "Construction",
    mobile: "(555-3672-24)",
    email: "jane@mail.com",
    notes: "",
    action: "",
  },
];
