import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from "react";
import {
  ColumnDirective,
  ColumnsDirective,
  CommandColumn,
  Edit,
  Filter,
  GridComponent,
  Page,
  Sort,
  Inject,
  Search,
  ColumnMenu,
} from "@syncfusion/ej2-react-grids";
import { db, auth } from "../../firebase";
import {
  getDoc,
  doc,
  collection,
  where,
  getDocs,
  query,
  updateDoc,
} from "firebase/firestore";
import { onAuthStateChanged, getAuth } from "firebase/auth";
import dataService from "../../service/data/DataService";
import { useNavigate } from "react-router-dom";

const filterSettings = {
  type: "CheckBox",
};

const sortSettings = {
  allowSorting: true,
};

const columnMenuItems = ["SortAscending", "SortDescending", "Filter"];

const pageSettings = { pageSize: 10 };

const editSettings = {
  allowEditing: true,
  allowAdding: true,
  showDeleteConfirmDialog: true,
  allowDeleting: true,
  allowEditOnDblClick: false,
  mode: "Dialog",
  dialog: { cssClass: "custom-dialog" },
};

const searchModalNavs = [
  {
    index: 0,
    label: "Citizen Search",
  },
  {
    index: 1,
    label: "Contacts",
  },
  {
    index: 2,
    label: "Disaster Recovery Partners Search",
  },
  {
    index: 3,
    label: "Local Non-Profit Coalition Search",
  },
];

const CitizenSearch = ({ className = "" }) => {
  const gridRef = useRef(null);
  const [dataSource, setDataSource] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [toggleSideModal, setToggleSideModal] = useState(true);
  const [searchModalCurrNav, setSearchModalCurrNav] = useState(0);
  const [loading, setLoading] = useState(true);

  const [userId, setUserId] = useState(null);
  const [data, setData] = useState([]);
  const [gridData, setGridData] = useState([]);

  useEffect(() => {
    const auth = getAuth();

    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUserId(currentUser.uid);
      } else {
        setData(null);
        console.log("No user is logged in");
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (userId) {
      fetchData();
    }
  }, [userId]);

  const mapCitizenData = (citizens) => {
    return citizens.map((citizen) => ({
      id: citizen.id, // Keep this for grid purposes
      name: `${citizen.first_name} ${citizen.last_name}`,
      address: `${citizen.address1}, ${citizen.address2}, ${citizen.city}, ${citizen.state}, ${citizen.zip}`,
      mobile_number: citizen.mobile_number,
      email: citizen.email,
      citizen_id: citizen.citizen_id, // Make sure this line is present
    }));
  };

  const fetchData = async () => {
    try {
      // Fetch user document
      const docRef = doc(db, "users", userId);
      const docc = await getDoc(docRef);
      console.log("Doc:", docc.data());

      const userDocRef = doc(db, "centers", docc.data().center_id);
      const userDoc = await getDoc(userDocRef);
      console.log("User Doc:", userDoc.data());

      if (userDoc.exists()) {
        setData({ id: userDoc.id, ...userDoc.data() });
        const centerId = docc.data().center_id;
        console.log("Center ID:", centerId);
        const usersRef = collection(db, "users");
        const queryRef = query(
          usersRef,
          where("center_id", "==", centerId),
          where("user_role", "==", "citizen")
        );
        const querySnapshot = await getDocs(queryRef);
        const citizens = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        const gridData = mapCitizenData(citizens);
        console.log(gridData);
        setGridData(gridData);
      } else {
        console.error("No such document!");
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  const onToggleGrid = () => {
    setToggleSideModal((prev) => !prev);
  };

  const onAddRecord = () => {
    gridRef.current.addRecord();
  };

  const onActionBegin = useCallback(
    (args) => {
      if (args.requestType === "save" && args.action === "add") {
        args.cancel = true;
        const newRecord = {
          ...args.data,
          id: (parseInt(dataSource.at(-1).id) + 1).toString(),
        };

        if (!!args.data.id) {
          const getIndexOfData = dataSource.findIndex(
            (d) => d.id === args.rowData.id
          );
          if (getIndexOfData < 0) return;
          setTimeout(() => {
            setDataSource((prev) => {
              prev.splice(getIndexOfData, 1, {
                ...newRecord,
                id: args.rowData.id,
              });
              return (prev = [...prev]);
            });
            args.cancel = false;
            gridRef.current.closeEdit();
            return args;
          }, 1000);
          return args;
        }

        // mimic the api call
        setTimeout(() => {
          args.cancel = false;
          setDataSource((prev) => {
            return (prev = [...prev, newRecord]);
          });
          gridRef.current.closeEdit();
          return args;
        }, 1000);
      }
      return args;
    },
    [dataSource]
  );

  const onActiveNav = useCallback((index) => {
    setSearchModalCurrNav(() => index);
  }, []);

  const onSearchDatabase = useCallback(
    (search) => {
      setSearchResults(() =>
        !search
          ? []
          : dataSource.filter((d) =>
              d.name.toLowerCase().includes(search.toLowerCase())
            )
      );
    },
    [dataSource]
  );

  const handleCommandClick = (args) => {
    if (args.commandColumn.type === "View") {
      handleViewAssistanceLog(args);
    } else {
      console.log("I am here 1");
      const citizenData = args.rowData;
      console.log(citizenData);
      localStorage.setItem("accessAccountEmail", citizenData.email);
      localStorage.setItem("orgUserId", userId);
      localStorage.setItem("orgType", "center");
      window.open(window.location.origin + "/accessaccount", "_blank");
    }
  };

  const navigate = useNavigate();

  const handleViewAssistanceLog = async (args) => {
    console.log("handleViewAssistanceLog called with args:", args);
    try {
      if (!userId) {
        console.error("User ID is not set. Cannot update document.");
        return;
      }

      // Get the row data directly from the args parameter
      const rowData = args.rowData;
      console.log("Full row data:", rowData);

      if (!rowData) {
        console.error("No row data available.");
        return;
      }

      console.log("Row data citizen_id:", rowData.citizen_id);
      console.log("Row data citizen_id type:", typeof rowData.citizen_id);

      const citizen_id = rowData.citizen_id;
      console.log("Extracted citizen_id:", citizen_id);

      if (!citizen_id || typeof citizen_id !== "string") {
        console.error("Invalid citizen_id: ", citizen_id);
        return;
      }

      console.log("Selected Citizen ID: ", citizen_id);

      const userDocRef = doc(db, "users", userId);
      console.log("User document reference:", userDocRef);

      try {
        await updateDoc(userDocRef, { accessed_userId: citizen_id });
        console.log(
          `Field 'accessed_userId' successfully updated with value: ${citizen_id}`
        );

        // Verify the update
        const updatedDoc = await getDoc(userDocRef);
        console.log("Updated document data:", updatedDoc.data());

        navigate("/centerassistancelog");
      } catch (error) {
        console.error("Error updating 'accessed_userId': ", error);
      }
    } catch (error) {
      console.error("Error in handleViewAssistanceLog: ", error);
    }
  };

  const renderSideModal = useMemo(() => {
    return (
      !toggleSideModal && (
        <div
          className="absolute right-0 top-0 z-10 flex h-full w-full flex-col items-end border border-solid"
          onClick={() => {
            setToggleSideModal(() => true);
          }}
        >
          <div
            className="mt-14 h-full w-[40%] space-y-[10px] bg-white p-5 shadow-2xl"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="mb-5 flex items-center space-x-[10px]">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
              >
                <path
                  opacity="0.4"
                  d="M14.6756 0.5H5.33333C1.92889 0.5 0 2.42889 0 5.83333V15.1667C0 18.5711 1.92889 20.5 5.33333 20.5H14.6756C18.08 20.5 20 18.5711 20 15.1667V5.83333C20 2.42889 18.08 0.5 14.6756 0.5Z"
                  fill="#348BFF"
                />
                <path
                  d="M5.36866 7.8689C4.91533 7.8689 4.54199 8.24223 4.54199 8.70445V15.5756C4.54199 16.0289 4.91533 16.4022 5.36866 16.4022C5.83088 16.4022 6.20421 16.0289 6.20421 15.5756V8.70445C6.20421 8.24223 5.83088 7.8689 5.36866 7.8689Z"
                  fill="#348BFF"
                />
                <path
                  d="M10.0352 4.58887C9.58183 4.58887 9.2085 4.9622 9.2085 5.42442V15.5755C9.2085 16.0289 9.58183 16.4022 10.0352 16.4022C10.4974 16.4022 10.8707 16.0289 10.8707 15.5755V5.42442C10.8707 4.9622 10.4974 4.58887 10.0352 4.58887Z"
                  fill="#348BFF"
                />
                <path
                  d="M14.6398 11.4956C14.1775 11.4956 13.8042 11.8689 13.8042 12.3312V15.5756C13.8042 16.0289 14.1775 16.4023 14.6309 16.4023C15.0931 16.4023 15.4664 16.0289 15.4664 15.5756V12.3312C15.4664 11.8689 15.0931 11.4956 14.6398 11.4956Z"
                  fill="#348BFF"
                />
              </svg>
              <p className="text-[12px] font-semibold text-[#348BFF]">
                Database
              </p>
            </div>
            <div className="flex justify-between">
              {searchModalNavs.map((nav, i) => {
                return (
                  <button
                    key={i}
                    className={`cursor-pointer bg-white px-[16px] py-[6px] text-[#348BFF] ${
                      searchModalCurrNav === nav.index &&
                      "border-b-[3px] border-solid border-[#348BFF] "
                    }`}
                    onClick={() => onActiveNav(nav.index)}
                  >
                    {nav.label}
                  </button>
                );
              })}
            </div>
            <div className="flex h-[35px] items-center overflow-hidden rounded-md border border-solid border-gray-500">
              <input
                placeholder="Search"
                className="h-full flex-1 px-3 focus:outline-none"
                onChange={(e) => onSearchDatabase(e.target.value)}
              />
              <div className="px-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="21"
                  height="22"
                  viewBox="0 0 21 22"
                  fill="none"
                >
                  <g id="Search">
                    <ellipse
                      id="Ellipse_746"
                      cx="9.59922"
                      cy="10.1532"
                      rx="8.59922"
                      ry="8.65324"
                      stroke="#DEE2E6"
                      stroke-width="2"
                    />
                    <path
                      id="Path_34202"
                      opacity="0.4"
                      d="M19.6745 21.4553C19.3405 21.4445 19.0228 21.307 18.7853 21.0705L16.7488 18.6902C16.3122 18.2909 16.2765 17.6123 16.6688 17.1689C16.8524 16.9831 17.102 16.8787 17.3624 16.8787C17.6228 16.8787 17.8725 16.9831 18.0561 17.1689L20.6172 19.2181C20.9861 19.5957 21.0999 20.1563 20.9078 20.6492C20.7157 21.1422 20.2535 21.4754 19.7279 21.5L19.6745 21.4553Z"
                      fill="#DEE2E6"
                    />
                  </g>
                </svg>
              </div>
            </div>
            <div className="flex flex-col space-y-2">
              {!!searchResults.length &&
                searchResults.map((ds) => {
                  return (
                    <div key={ds.id}>
                      <p className="font-semibold text-[#444444]">{ds.name}</p>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      )
    );
  }, [
    toggleSideModal,
    onActiveNav,
    searchModalCurrNav,
    searchResults,
    onSearchDatabase,
  ]);

  return (
    <>
      <div
        className={`m-0 box-border flex w-[1648px] max-w-full flex-col items-start justify-start gap-2.5 px-2.5 py-[15px] leading-[normal] tracking-[normal] ${className}`}
      >
        <div id="dialog-container"></div>
        <div className="flex flex-row items-start justify-start gap-1.5  text-xs text-darkslateblue">
          <a className="relative inline-block min-w-[40px] text-left  text-xs font-medium leading-[16px] text-dodgerblue [text-decoration:none]">
            Database
          </a>
          <div className="relative inline-block min-w-[5px] text-left font-mulish text-base font-semibold leading-[16px] text-gray-400">
            /
          </div>
          <a className="relative inline-block min-w-[77px] text-left  text-xs font-medium leading-[16px] text-dodgerblue [text-decoration:none]">
            Citizen Search
          </a>
        </div>
        <section className="flex max-w-full flex-col items-start justify-start self-stretch bg-white">
            <header className="mb-2 flex flex-row items-center justify-between self-stretch">
               <div>
                  <div className="relative mb-2 inline-block max-w-full font-[Inter] font-medium leading-[21px] text-darkslategray-200">
                     <p>
                        Centers only have access to citizens that have authorized their
                        information to be shared with a center.
                     </p>
                  </div>
                  <h2 className="text-sm font-bold text-blue-500">
                      CITIZEN
                  </h2>
               </div>
            </header>
            <div>
                <GridComponent
                  ref={gridRef}
                  dataSource={gridData}
                  allowSorting={true}
                  sortSettings={sortSettings}
                  editSettings={editSettings}
                  filterSettings={filterSettings}
                  allowFiltering={true}
                  actionBegin={onActionBegin}
                  toolbar={["Search"]}
                  allowPaging={true}
                  pageSettings={pageSettings}
                  showColumnMenu={true}
                  columnMenuItems={columnMenuItems}
                  commandClick={handleCommandClick}
                >
                  <ColumnsDirective>
                    <ColumnDirective field="id" isPrimaryKey visible={false} />
                    <ColumnDirective field="citizen_id" visible={false} />
                    <ColumnDirective field="name" headerText="Name" width="200" />
                    <ColumnDirective field="address" headerText="Address" />
                    <ColumnDirective
                      field="mobile_number"
                      headerText="Mobile Number"
                      width="180"
                    />
                    <ColumnDirective field="email" headerText="Email Address" width="250"/>
                    {/* <ColumnDirective
                      headerText="Manage Records"
                      width="160"
                      commands={commands}
                    /> */}
                    <ColumnDirective
                      headerText="Actions"
                      textAlign="Center"
                      width="400"
                      commands={[
                        {
                          type: "View",
                          buttonOption: {
                            content: "View Assistance Log",
                            cssClass: "e-outline custom-button",
                          },
                        },
                        {
                          type: "Access",
                          buttonOption: {
                            content: "Access Account",
                            cssClass: "e-outline custom-button",
                          },
                        },
                      ]}
                    />
                  </ColumnsDirective>
                  <Inject services={[Page, Sort, Filter, Search, ColumnMenu]} />
                </GridComponent>
            </div>
        </section>
      </div>
      {renderSideModal}
    </>
  );
};

export default CitizenSearch;
