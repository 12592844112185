import React, { useState, useRef, useEffect } from "react";
import {
  FaEdit,
  FaChevronDown,
  FaChevronUp,
  FaSave,
  FaTimes,
  FaPlus,
} from "react-icons/fa";
import {
  GridComponent,
  ColumnDirective,
  ColumnsDirective,
  Inject,
  Page,
  Edit,
  Toolbar,
  Sort,
  Filter,
  Search,
  ColumnMenu,
} from "@syncfusion/ej2-react-grids";
import { db, auth } from "../../firebase";
import {
  getDoc,
  doc,
  setDoc,
  updateDoc,
  getFirestore,
  getDocs,
  collection,
  where,
  query,
  arrayUnion,
} from "firebase/firestore";
import { onAuthStateChanged, getAuth } from "firebase/auth";
import dataService from "../../service/data/DataService";

const Partnerships = ({ className = "" }) => {
  const [userId, setUserId] = useState(null);
  const [data, setData] = useState([]);
  const [gridData, setGridData] = useState([]);
  const [approvedData, setApprovedData] = useState([]);
  const [centers, setCenters] = useState([]);
  const [centerId, setCenterId] = useState(null);

  useEffect(() => {
    const auth = getAuth();

    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUserId(currentUser.uid);
      } else {
        setData(null);
        console.log("No user is logged in");
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (userId) {
      fetchData();
    }
  }, [userId]);

  const fetchData = async () => {
    try {
      // Fetch user document
      const userDocRef = doc(db, "users", userId);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        setData({ id: userDoc.id, ...userDoc.data() });

        console.log("Center ID:", userDoc.data().center_id);
        const resp = await dataService.getPartnerForApporval(
          userDoc.data().center_id
        );
        console.log("Response:", resp);
        setCenterId(userDoc.data().center_id);
        setGridData(resp);

        const approvePartners = await dataService.getApprovedPartners(
          userDoc.data().center_id
        );
        const formattedApprovedData = approvePartners.map((partner) => ({
          ...partner,
          address: `${partner.address1}, ${partner.address2}, ${partner.city}, ${partner.state} ${partner.zip}`,
        }));
        console.log("Formatted approvePartners:", formattedApprovedData);
        setApprovedData(formattedApprovedData);
      } else {
        console.error("No such document!");
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  const sortSettings = {
    allowSorting: true,
  };

  const filterSettings = {
    type: "CheckBox",
  };
  const columnMenuItems = ["SortAscending", "SortDescending", "Filter"];

  const pageSettings = { pageSize: 10 };

  const [isGridVisible, setIsGridVisible] = useState(true);
  const toggleGrid = (event) => {
    event.preventDefault();
    setIsGridVisible(!isGridVisible);
  };
  // Toggle is_approved field
  const handleApproveClick = async (id, isApproved, partnerId) => {
    console.log("ID:", id);
    console.log("isApproved:", isApproved);
    console.log("partnerId:", partnerId);
    try {
      // Show popup for approval options
      const approvalType = await new Promise((resolve) => {
        const popup = document.createElement("div");
        popup.innerHTML = `
          <div class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <div class="bg-white p-6 rounded-lg">
              <h3 class="text-lg font-bold mb-4">Select Approval Type</h3>
              <button class="bg-blue-500 text-white px-4 py-2 rounded mr-2" onclick="resolve('local-non-profit')">
                Approve as Non-Profit Partner
              </button>
              <button class="bg-green-500 text-white px-4 py-2 rounded" onclick="resolve('disaster-recovery')">
                Approve as Disaster Recovery Partner
              </button>
            </div>
          </div>
        `;
        document.body.appendChild(popup);
        window.resolve = resolve;
      });

      // Remove the popup
      document.body.removeChild(document.body.lastChild);

      // Update the document in the 'partner-for-approval' collection
      await updateDoc(doc(db, "partner-for-approval", id), {
        is_approved: true,
        status: "Applied", // Retain the status as "Applied"
      });

      // Update the document in the 'users' collection
      await updateDoc(doc(db, "partners", partnerId), {
        is_approved: true,
        type: approvalType,
        status: "Applied", // Retain the status as "Applied"
      });

      const usersQuery = query(
        collection(db, "users"),
        where("partner_id", "==", partnerId)
      );
      const querySnapshot = await getDocs(usersQuery);
      querySnapshot.forEach(async (doc) => {
        const docData = doc.data();
        if (!docData.center_id.includes(centerId)) {
          await updateDoc(doc.ref, {
            center_id: arrayUnion(centerId),
          });
        }
      });

      console.log(
        `Application approved as ${approvalType} partner for user:`,
        partnerId
      );

      // Update the local state or grid data if needed
      setGridData((prevGridData) =>
        prevGridData.map((item) =>
          item.id === id
            ? {
                ...item,
                is_approved: true,
                type: approvalType,
                status: "Applied",
              }
            : item
        )
      );
    } catch (error) {
      console.error("Error toggling approval status:", error);
    }
  };

  // Toggle is_certified field
  const handleCertifyClick = async (id, isCertified, partnerId) => {
    try {
      const newCertifiedStatus = !isCertified;

      // Update the document in the 'partner-for-approval' collection
      await updateDoc(doc(db, "partner-for-approval", id), {
        is_certified: newCertifiedStatus,
      });

      // Update the document in the 'users' collection
      await updateDoc(doc(db, "partners", partnerId), {
        is_certified: newCertifiedStatus,
      });

      console.log(
        `Application ${
          newCertifiedStatus ? "certified" : "uncertified"
        } for user:`,
        partnerId
      );

      // Update the local state or grid data if needed
      setGridData((prevGridData) =>
        prevGridData.map((item) =>
          item.id === id ? { ...item, is_certified: newCertifiedStatus } : item
        )
      );
    } catch (error) {
      console.error("Error toggling certification status:", error);
    }
  };

  // Render the buttons in the status column
  const statusTemplate = (rowData) => {
    return (
      <div>
        <button
          onClick={() =>
            handleApproveClick(
              rowData.id,
              rowData.is_approved,
              rowData.partner_id
            )
          }
          className={`e-btn e-flat ${
            rowData.is_approved
              ? "bg-green-500 text-white"
              : "bg-red-500 text-white"
          }`}
        >
          {rowData.is_approved ? "Approved" : "Approve"}
        </button>
        <button
          onClick={() =>
            handleCertifyClick(
              rowData.id,
              rowData.is_certified,
              rowData.partner_id
            )
          }
          className={`e-btn e-flat ${
            rowData.is_certified
              ? "bg-green-500 text-white"
              : "bg-red-500 text-white"
          }`}
        >
          {rowData.is_certified ? "Certified" : "Certify"}
        </button>
      </div>
    );
  };

  return (
    <div
      className={`m-0 box-border flex w-[1648px] max-w-full flex-col items-start justify-start gap-2.5 px-2.5 py-[15px] leading-[normal] tracking-[normal] ${className}`}
    >
      <div id="dialog-container"></div>
      <div className="flex flex-row items-start justify-start gap-1.5  text-xs text-darkslateblue">
          <a className="relative inline-block min-w-[40px] text-left  text-xs font-medium leading-[16px] text-dodgerblue [text-decoration:none]">
            Partnerships
          </a>
      </div>
      <section className="flex max-w-full flex-col items-start justify-start self-stretch bg-white pb-4">
        <header className="mb-2 flex flex-row items-center justify-between self-stretch">
          <div>
            <h2 className="mb-1 text-sm font-bold text-blue-500">
              NON PROFIT PARTNERSHIPS
            </h2>
            <div className="flex max-w-full flex-col items-start justify-start self-stretch bg-white mt-2">
                <div className="relative mb-2 inline-block max-w-full font-[Inter] font-medium leading-[21px] text-darkslategray-200">
                     <p>
                        The Nonprofit Partnerships section allows you to review,
                        approve, or decline partnership requests from organizations
                        seeking to collaborate with your center. You can choose to
                        accept these partners into the Local Nonprofit Coalition for
                        year-round collaboration or designate them as Disaster Recovery
                        Partners, providing targeted support during disaster events.
                     </p>
                </div>
            </div>
          </div>
          {/* <div className="flex items-center gap-2">
            <button
              type="button"
              className="flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-gray-200 transition-all duration-300 ease-in-out hover:bg-gray-300"
              onClick={toggleGrid}
            >
              <span
                className={`transition-transform duration-300 ease-in-out ${
                  isGridVisible ? "rotate-0" : "rotate-180"
                }`}
              >
                <FaChevronDown />
              </span>
            </button>
          </div> */}
        </header>
        <h2 className="mb-1 text-sm font-bold text-blue-500">
            APPROVED NON-PROFIT PARTNERS
        </h2>
        {isGridVisible && (
          <div className="mb-8 flex flex-col gap-4 self-stretch">
            <GridComponent
              dataSource={approvedData}
              allowSorting={true}
              sortSettings={sortSettings}
              filterSettings={filterSettings}
              allowFiltering={true}
              allowPaging={true}
              pageSettings={pageSettings}
              showColumnMenu={true}
              columnMenuItems={columnMenuItems}
            >
              <ColumnsDirective>
                <ColumnDirective
                  field="name_of_org"
                  headerText="Name of Organization"
                  width="100"
                />
                <ColumnDirective
                  field="address"
                  headerText="Address"
                  width="100"
                />
                <ColumnDirective
                  field="mobile_number"
                  headerText="Mobile Number"
                  width="100"
                />
                <ColumnDirective
                  field="email"
                  headerText="Email Address"
                  width="100"
                />
              </ColumnsDirective>
              <Inject
                services={[
                  Page,
                  Edit,
                  Toolbar,
                  Sort,
                  Filter,
                  Search,
                  ColumnMenu,
                ]}
              />
            </GridComponent>
          </div>
        )}

        <h2 className="mb-1 text-sm font-bold text-blue-500">
            NON-PROFIT PARTNERSHIPS FOR APPROVAL
        </h2>
        {isGridVisible && (
          <div className="flex flex-col gap-4 self-stretch">
            <GridComponent
              dataSource={gridData}
              allowSorting={true}
              sortSettings={sortSettings}
              filterSettings={filterSettings}
              allowFiltering={true}
              toolbar={["Search"]}
              allowPaging={true}
              pageSettings={pageSettings}
              showColumnMenu={true}
              columnMenuItems={columnMenuItems}
            >
              <ColumnsDirective>
                <ColumnDirective
                  field="name_of_org"
                  headerText="Name of Organization"
                  width="100"
                />
                <ColumnDirective
                  field="address"
                  headerText="Address"
                  width="100"
                />
                <ColumnDirective
                  field="mobile_number"
                  headerText="Mobile Number"
                  width="100"
                />
                <ColumnDirective
                  field="email"
                  headerText="Email Address"
                  width="100"
                />
                <ColumnDirective
                  headerText="Status"
                  width="100"
                  template={statusTemplate}
                />
              </ColumnsDirective>
              <Inject
                services={[
                  Page,
                  Edit,
                  Toolbar,
                  Sort,
                  Filter,
                  Search,
                  ColumnMenu,
                ]}
              />
            </GridComponent>
          </div>
        )}
      </section>
    </div>
  );
};

export default Partnerships;
