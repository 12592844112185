import { db } from "../../firebase";
import {
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  deleteDoc,
  addDoc,
  serverTimestamp,
  orderBy,
  limit,
} from "firebase/firestore";

let disasterDataCache = null;

const DisasterMgtService = {

  fetchDisasterData: async () => {
    if (disasterDataCache) {
      return disasterDataCache;
    }

    const userIds = [
      "2xRe086ekZW1DBLkPDBcXHIMEKK2",
      "E07ln2AjrDh4AqSIjk9Q3ZlXyGQ2",
      "HNAF2yP5C3ceETPpEweNlDGPPRO2",
      "KmDDF5dyVLZ1s73V7GyEtpMcpLJ3",
      "MkATiJIrRAdvdZ5tg7PZiSB7gqb2",
      "SO1RpVviOOVJ3HXRsXdVYZHPly72",
      "Swm4XhKi7IdJj2xHBh7ffs3squy1",
      "UluTI2TdmmfJ3Gw3Qwyd8hE4bI52",
      "b0uOsdpsi9eyM5EMvoiakjhNyMa2",
      "mjJhuXLAeIVy3rgCzbjwNsTYCCz1",
      "pqU69Pl4CTcEzc6L3PUGuHlsX7f2",
      "sFXqoueVGmMmXVlpVwyxJMobTNv2",
      "vbDWCWdyAoYpK0qjxnuGk3GzvHi2",
    ];

    const collectionNames = ["general", "home_repair", "landscaping", "vehicle", "contents"];

    let allRecords = [];
    let totalValueSum = 0;
    let immediateAssistanceSum = 0;
    let recoveryAssistanceSum = 0;

    try {
      for (const userId of userIds) {
        for (const collectionName of collectionNames) {
          const recordsSnapshot = await getDocs(collection(db, "disaster-mgt", userId, collectionName));
          const records = recordsSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

          allRecords.push({ userId, collectionName, records });

          for (const record of records) {
            if (record.value && !record.is_deleted) {
              const value = parseFloat(record.value);
              totalValueSum += value;
              if (collectionName === "general") immediateAssistanceSum += value;
              else recoveryAssistanceSum += value;
            }
          }
        }
      }

      const totalRecords = allRecords.reduce((acc, item) => acc + item.records.length, 0);

      // Cache the result
      disasterDataCache = { allRecords, totalValueSum, immediateAssistanceSum, recoveryAssistanceSum, totalRecords };
      return disasterDataCache;
    } catch (error) {
      console.error("Error fetching disaster data:", error);
      throw error;
    }
  },

  fetchAllData: async () => {
    try {
      const mainCollectionRef = collection(db, "disaster-mgt");
      const mainSnapshot = await getDocs(mainCollectionRef);
      
      const allData = [];
      
      for (const docSnap of mainSnapshot.docs) {
        const docId = docSnap.id;

        const subcollections = ["general", "home_repair", "landscaping", "vehicle", "contents"];
  
        for (const subcollectionName of subcollections) {
          const subcollectionRef = collection(db, "disaster-mgt", docId, subcollectionName);
          const subcollectionSnapshot = await getDocs(subcollectionRef);
          
          const subcollectionData = subcollectionSnapshot.docs.map((subDoc) => ({
            id: subDoc.id,
            ...subDoc.data(),
          }));
  
          allData.push({
            docId,
            subcollectionName,
            data: subcollectionData,
          });
  
          console.log(`Fetched data from subcollection "${subcollectionName}" of document "${docId}"`, subcollectionData);
        }
      }
  
      return allData;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  },
  
  fetchRecords: async (userId, collectionName) => {
    try {
      const documentsCollectionRef = collection(
        db,
        "disaster-mgt",
        userId,
        collectionName
      );
      const documentsSnapshot = await getDocs(documentsCollectionRef);

      const documentsList = documentsSnapshot.docs.map((doc) => {
        const docData = doc.data();
        return {
          id: doc.id,
          ...docData,
        };
      });

      return documentsList;
    } catch (error) {
      console.error("Error fetching documents:", error);
      throw error;
    }
  },
  addRecord: async (userId, documentData, collectionName) => {
    try {
      console.log("addRecord called with:", { userId, documentData, collectionName });

      if (!userId) throw new Error("userId is undefined");
      if (!collectionName) throw new Error("collectionName is undefined");
      if (!documentData) throw new Error("documentData is undefined");

      const documentsCollectionRef = collection(
        db,
        "disaster-mgt",
        userId,
        collectionName
      );

      console.log("Collection reference created:", documentsCollectionRef);

      const updatedDocumentData = {
        ...documentData,
        userId: userId,
        updated_at: serverTimestamp(),
      };

      console.log("Attempting to add document with data:", updatedDocumentData);

      const docRef = await addDoc(documentsCollectionRef, updatedDocumentData);

      console.log("Document successfully added with ID:", docRef.id);
    } catch (error) {
      console.error("Error adding document: ", error);
      throw error;
    }
  },

  updateRecord: async (userId, documentId, documentData, collectionName) => {
    try {
      const documentsCollectionRef = collection(
        db,
        "disaster-mgt",
        userId,
        collectionName
      );
  
      const idField = getIdFieldForCollection(collectionName);
  
      const q = query(documentsCollectionRef, where(idField, "==", documentId));
      const querySnapshot = await getDocs(q);
  
      if (!querySnapshot.empty) {
        querySnapshot.forEach(async (doc) => {
          const documentRef = doc.ref;
  
          const updatedDocumentData = {
            ...documentData,
            userId: userId,
            updated_at: serverTimestamp(),
          };
  
          await updateDoc(documentRef, updatedDocumentData);
        });
        console.log("Document successfully updated!");
      } else {
        console.error("No document found with the given ID to update.");
        throw new Error("No document found with the given ID to update.");
      }
    } catch (error) {
      console.error("Error updating document: ", error);
      throw error;
    }
  },

  deleteRecord: async (userId, documentId, collectionName) => {
    try {
      console.log("Deleting document with ID:", documentId);
  
      if (!documentId) {
        throw new Error("documentId is undefined or null");
      }
  
      const documentsCollectionRef = collection(
        db,
        "disaster-mgt",
        userId,
        collectionName
      );
      const idField = getIdFieldForCollection(collectionName);
  
      const q = query(documentsCollectionRef, where(idField, "==", documentId));
      const querySnapshot = await getDocs(q);
  
      if (!querySnapshot.empty) {
        querySnapshot.forEach(async (doc) => {
          const documentRef = doc.ref;
          await deleteDoc(documentRef);
        });
        console.log("Document successfully deleted!");
      } else {
        console.error("No document found with the given ID to delete.");
        throw new Error("No document found with the given ID to delete.");
      }
    } catch (error) {
      console.error("Error deleting document from disaster management: ", error);
      throw error;
    }
  },

  getLastEditedTime: async (userId, collectionName) => {
    try {
      const documentsCollectionRef = collection(db, "disaster-mgt", userId, collectionName);
      const q = query(documentsCollectionRef, orderBy("last_edited", "desc"), limit(1));
      const querySnapshot = await getDocs(q);
      
      if (!querySnapshot.empty) {
        return querySnapshot.docs[0].data().last_edited.toDate();
      }
      return null;
    } catch (error) {
      console.error("Error getting last edited time:", error);
      return null;
    }
  },
};

const getIdFieldForCollection = (collectionName) => {
  switch (collectionName) {
    case "general":
      return "generalID";
    case "home_repair":
      return "homeRepairID";
    case "landscaping":
      return "landscapingID";
    case "contents":
      return "contentsID";
    case "vehicle":
      return "vehicleID";
    default:
      throw new Error(`Unknown collection name: ${collectionName}`);
  }
};

export default DisasterMgtService;
