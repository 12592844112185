import React, { useState, useEffect, useRef } from "react";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Sort,
  Filter,
  ColumnMenu,
  Edit,
  Toolbar,
  CommandColumn,
  Inject,
} from "@syncfusion/ej2-react-grids";
import { db, auth } from "../../firebase";
import {
  collection,
  getDocs,
  updateDoc,
  deleteDoc,
  addDoc,
  doc,
  serverTimestamp,
} from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import "./../style.css";

const PartnerGrantTracker = ({ className = "" }) => {
  const [partnerGrantTracker, setPartnerGrantTracker] = useState([]);
  const [partnerGrantTrackerExp, setPartnerGrantTrackerExp] = useState([]);
  const gridRef = useRef(null);
  const gridRefExp = useRef(null);
  const [userId, setUserId] = useState(null);

  const sortSettings = {
    allowSorting: true,
  };
  const filterSettings = {
    type: "CheckBox",
  };
  const columnMenuItems = ["SortAscending", "SortDescending", "Filter"];

  const pageSettings = { pageSize: 10 };

  useEffect(() => {
    const fetchGrantTracker = async () => {
      try {
        const querySnapshot = await getDocs(
          collection(db, "partner-grant-tracker")
        );
        const grantTrackerData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setPartnerGrantTracker(grantTrackerData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const fetchGrantTrackerExp = async () => {
      try {
        const querySnapshotExp = await getDocs(
          collection(db, "partner-grant-tracker-exp")
        );
        const grantTrackerExpData = querySnapshotExp.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setPartnerGrantTrackerExp(grantTrackerExpData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserId(user.uid);
        fetchGrantTracker();
        fetchGrantTrackerExp();
      } else {
        setUserId(null);
        setPartnerGrantTracker([]);
        setPartnerGrantTrackerExp([]);
      }
    });

    return () => unsubscribe();
  }, []);

  const generatePartnerGrantTrackerId = () => {
    return `CID-${Math.random().toString(36).substr(2, 9)}`;
  };

  const generatePartnerGrantTrackerExpId = () => {
    return `CID-${Math.random().toString(36).substr(2, 9)}`;
  };

  const actionBegin = (args) => {
    if (args.requestType === "save") {
      const data = args.data;

      // Prevent saving if any of the fields are empty
      if (
        !data.project ||
        !data.grantorName ||
        !data.grantAward ||
        !data.purpose ||
        !data.reporting
      ) {
        args.cancel = true;
        alert("Please fill in all required fields before saving.");
        return;
      }

      // Generate partnerGrantTrackerId if it's not present
      data.partnerGrantTrackerId =
        data.partnerGrantTrackerId || generatePartnerGrantTrackerId();
      data.last_updated = serverTimestamp();
      data.inserted_at = serverTimestamp();
      data.user_id = userId;

      if (args.action === "edit") {
        if (data.id) {
          const grantTrackerDoc = doc(db, "partner-grant-tracker", data.id);
          updateDoc(grantTrackerDoc, data).catch((error) =>
            console.error("Error saving document:", error)
          );
        }
      } else if (args.action === "add") {
        addDoc(collection(db, "partner-grant-tracker"), data).catch((error) =>
          console.error("Error adding document:", error)
        );
      }
    }
  };

  const actionBeginExp = async (args) => {
    if (args.requestType === "save") {
      const data = args.data;

      // Prevent saving if any of the fields are empty
      if (
        !data.items ||
        !data.value ||
        !data.percentage ||
        !data.purpose ||
        !data.reporting
      ) {
        args.cancel = true;
        alert("Please fill in all required fields before saving.");
        return;
      }

      // Generate partnerGrantTrackerExpId if it's not present
      data.partnerGrantTrackerExpId =
        data.partnerGrantTrackerExpId || generatePartnerGrantTrackerExpId();
      data.last_updated = serverTimestamp();
      data.inserted_at = serverTimestamp();
      data.user_id = userId;

      if (args.action === "edit") {
        if (data.id) {
          const grantTrackerExpDoc = doc(
            db,
            "partner-grant-tracker-exp",
            data.id
          );
          try {
            await updateDoc(grantTrackerExpDoc, data);
          } catch (error) {
            console.error("Error saving document:", error);
          }
        }
      } else if (args.action === "add") {
        try {
          await addDoc(collection(db, "partner-grant-tracker-exp"), data);
        } catch (error) {
          console.error("Error adding document:", error);
        }
      }
    } else if (args.requestType === "delete") {
      const data = args.data[0]; // Gets the data of the deleted row

      if (data.id) {
        try {
          const grantTrackerExpDoc = doc(
            db,
            "partner-grant-tracker-exp",
            data.id
          );
          await deleteDoc(grantTrackerExpDoc);
        } catch (error) {
          console.error("Error deleting document:", error);
          alert("Error deleting the item from the database.");
          args.cancel = true; // Cancel the delete action if the Firebase operation fails
        }
      }
    }
  };

  const actionComplete = async (args) => {
    if (args.requestType === "delete") {
      const data = args.data[0]; // Gets the data of the deleted row

      try {
        if (data.id) {
          const grantTrackerDoc = doc(db, "partner-grant-tracker", data.id);
          await deleteDoc(grantTrackerDoc);
        }
      } catch (error) {
        console.error("Error deleting document:", error);
        alert("Error deleting the item from the database.");
      }
    }
  };

  const actionCompleteExp = async (args) => {
    if (args.requestType === "delete") {
      const data = args.data[0]; // Gets the data of the deleted row

      try {
        if (data.id) {
          const grantTrackerExpDoc = doc(
            db,
            "partner-grant-tracker-exp",
            data.id
          );
          await deleteDoc(grantTrackerExpDoc);
        }
      } catch (error) {
        console.error("Error deleting document:", error);
        alert("Error deleting the item from the database.");
      }
    }
  };

  const toolbarClick = (args) => {
    if (args.item.id === "Grid_add") {
      gridRef.current.addRecord();
    }
  };

  const toolbarClickExp = (args) => {
    if (args.item.id === "Grid_add") {
      gridRefExp.current.addRecord();
    }
  };

  return (
    <div
      className={`m-0 box-border flex w-[1648px] max-w-full flex-col items-start justify-start gap-2.5 px-2.5 py-[15px] leading-[normal] tracking-[normal] ${className}`}
    >
      <div id="dialog-container"></div>
      <div className="flex flex-row items-start justify-start gap-1.5 py-0 pl-0 pr-5">
        <a className="relative inline-block min-w-[100px] text-left  text-xs font-medium leading-[16px] text-dodgerblue [text-decoration:none]">
          Grant Tracker
        </a>
      </div>
      <div>
        <div className="flex max-w-full flex-col items-start justify-start gap-[15px] self-stretch text-left text-xs text-darkslateblue">
          <GridComponent
            dataSource={partnerGrantTracker}
            allowPaging={true}
            pageSettings={{ pageSize: 5 }}
            allowSorting={true}
            ref={gridRef}
            editSettings={{
              allowEditing: true,
              allowAdding: true,
              allowDeleting: true,
              showDeleteConfirmDialog: true,
              mode: "Dialog",
            }}
            //toolbar={['Add', 'Edit', 'Delete', 'Update', 'Cancel']}
            toolbarClick={toolbarClick}
            actionBegin={actionBegin}
            actionComplete={actionComplete}
            filterSettings={filterSettings}
            allowFiltering={true}
            sortSettings={sortSettings}
            showColumnMenu={true}
            columnMenuItems={columnMenuItems}
          >
            <ColumnsDirective>
              <ColumnDirective
                field="partnerGrantTrackerId"
                isPrimaryKey={true}
                isIdentity={true}
                visible={false}
              />
              <ColumnDirective
                field="project"
                headerText="Project"
                textAlign="Left"
              />
              <ColumnDirective
                field="grantorName"
                headerText="Granter Name"
                textAlign="Left"
              />
              <ColumnDirective
                field="grantAward"
                headerText="Grant Award"
                textAlign="Left"
              />
              <ColumnDirective
                field="purpose"
                headerText="Purpose"
                textAlign="Left"
              />
              <ColumnDirective
                field="reporting"
                headerText="Reporting"
                textAlign="Left"
              />
              <ColumnDirective
                field="file"
                headerText="File"
                textAlign="Left"
              />
              <ColumnDirective
                field="notes"
                headerText="Notes"
                textAlign="Left"
              />
              <ColumnDirective
                headerText="Actions"
                textAlign="Center"
                commands={[
                  {
                    type: "Edit",
                    buttonOption: {
                      content: '<i class="fas fa-edit"></i>',
                      cssClass: "e-outline custom-button",
                    },
                  },
                  {
                    type: "Delete",
                    buttonOption: {
                      content: '<i class="fas fa-trash-alt"></i>',
                      cssClass: "e-outline custom-button",
                    },
                  },
                ]}
              />
            </ColumnsDirective>
            <Inject
              services={[
                Page,
                Sort,
                Filter,
                Edit,
                Toolbar,
                CommandColumn,
                ColumnMenu,
              ]}
            />
          </GridComponent>
        </div>
      </div>
      <div id="dialog-container" className="mt-3"></div>
      <div className="flex flex-row items-start justify-start gap-1.5 py-0 pl-0 pr-5">
        <a className="relative inline-block min-w-[100px] text-left  text-xs font-medium leading-[16px] text-dodgerblue [text-decoration:none]">
          Expenditures
        </a>
      </div>
      <div>
        <div className="flex max-w-full flex-col items-start justify-start gap-[15px] self-stretch text-left text-xs text-darkslateblue">
          <GridComponent
            dataSource={partnerGrantTrackerExp}
            allowPaging={true}
            pageSettings={{ pageSize: 5 }}
            allowSorting={true}
            ref={gridRefExp}
            editSettings={{
              allowEditing: true,
              allowAdding: true,
              allowDeleting: true,
              mode: "Dialog",
            }}
            //toolbar={['Add', 'Edit', 'Delete', 'Update', 'Cancel']}
            toolbarClick={toolbarClickExp}
            actionBegin={actionBeginExp}
            actionComplete={actionCompleteExp}
            showColumnMenu={true}
            columnMenuItems={columnMenuItems}
          >
            <ColumnsDirective>
              <ColumnDirective
                field="partnerGrantTrackerExpId"
                isPrimaryKey={true}
                isIdentity={true}
                visible={false}
              />
              <ColumnDirective
                field="items"
                headerText="Items"
                textAlign="Left"
              />
              <ColumnDirective
                field="value"
                headerText="Value"
                textAlign="Left"
              />
              <ColumnDirective
                field="percentage"
                headerText="Percentage"
                textAlign="Left"
              />
              <ColumnDirective
                field="purpose"
                headerText="Purpose"
                textAlign="Left"
              />
              <ColumnDirective
                field="reporting"
                headerText="Reporting"
                textAlign="Left"
              />
              <ColumnDirective
                field="file"
                headerText="File"
                textAlign="Left"
              />
              <ColumnDirective
                field="notes"
                headerText="Notes"
                textAlign="Left"
              />
              <ColumnDirective
                headerText="Actions"
                textAlign="Center"
                commands={[
                  {
                    type: "Edit",
                    buttonOption: {
                      content: '<i class="fas fa-edit"></i>',
                      cssClass: "e-outline custom-button",
                    },
                  },
                  {
                    type: "Delete",
                    buttonOption: {
                      content: '<i class="fas fa-trash-alt"></i>',
                      cssClass: "e-outline custom-button",
                    },
                  },
                ]}
              />
            </ColumnsDirective>
            <Inject
              services={[
                Page,
                Sort,
                Filter,
                Edit,
                Toolbar,
                CommandColumn,
                ColumnMenu,
              ]}
            />
          </GridComponent>
        </div>
      </div>
    </div>
  );
};

export default PartnerGrantTracker;
