import React, { useState, useRef, useEffect } from "react";
import { ReactComponent as CentersIcon } from "../icons/Maps/centers.svg";
import { ReactComponent as PartnershipIcon } from "../icons/Maps/partnership.svg";
import { ReactComponent as FireIcon } from "../icons/Maps/fire.svg";
import { ReactComponent as FloodIcon } from "../icons/Maps/flood.svg";
import { ReactComponent as EarthquakeIcon } from "../icons/Maps/Earthquake.svg";
import { ReactComponent as HurricaneIcon } from "../icons/Maps/hurricane.svg";
import { ReactComponent as TornadoIcon } from "../icons/Maps/tornado.svg";
import {
  Map as VISGoogleMap,
  APIProvider,
  AdvancedMarker,
  Marker,
} from "@vis.gl/react-google-maps";
import { db, auth } from "../firebase";
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  serverTimestamp,
  query,
  where,
  getDoc,
} from "firebase/firestore";
import getCoordinates from "../service/geocoding/Geocoding";
import { onAuthStateChanged, getAuth } from "firebase/auth";
import citizenProfileService from "../service/citizen/CitizenProfile";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import { config } from "../utils/config";
import { useAuth } from "../components/AuthContext";
import { useNavigate } from "react-router-dom";

const Map = (className = "") => {
  const [userId, setUserId] = useState(null);
  const [data, setData] = useState(null);
  const [coordinates, setCoordinates] = useState(null);
  const [communityContacts, setCommunityContacts] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [userRole, setUserRole] = useState(null);
  const mapRef = useRef(null);
  const [permanentInfo, setPermanentInfo] = useState(null);
  const [labelText, setLabelText] = useState("");
  const [relatedUsers, setRelatedUsers] = useState([]);
  const [hoveredMarker, setHoveredMarker] = useState(null);
  const { role } = useAuth();
  const navigate = useNavigate();

  const fetchData = async () => {
    let profileData;
    const data = await citizenProfileService.getCitizenProfile("users", userId);
    if (role === "center") {
      profileData = await citizenProfileService.getCitizenProfile(
        "centers",
        data.center_id
      );
      setLabelText(profileData.name_of_hub || "");
    } else if (role === "partner") {
      profileData = await citizenProfileService.getCitizenProfile(
        "partners",
        data.partner_id
      );
      console.log("my partner data", profileData);
      setLabelText(profileData.name_of_org || "");
    } else {
      profileData = await citizenProfileService.getCitizenProfile(
        "users",
        userId
      );
      setLabelText("");

      setUserRole(role);
    }

    if (profileData) {
      setData(profileData);

      // if (profileData.latitude && profileData.longitude) {
      //   const newCoordinates = {
      //     lat: profileData.latitude,
      //     lon: profileData.longitude,
      //   };
      //   setCoordinates(newCoordinates);
      //   setPermanentInfo({
      //     mobileNumber: profileData.mobile_number,
      //     address: `${profileData.address1}, ${profileData.city}, ${profileData.state}, ${profileData.zip}`,
      //     role: role,
      //   });
      // } else {
      try {
        const address = `${profileData.address1}, ${profileData.city}, ${profileData.state}, ${profileData.zip}`;
        const response = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
            address
          )}&key=${config.google_maps}`
        );
        const data = await response.json();

        if (data.results.length > 0) {
          const { lat, lng } = data.results[0].geometry.location;
          setCoordinates({ lat, lon: lng });

          let updateId =
            role === "center"
              ? profileData.id
              : role === "partner"
              ? profileData.id
              : userId;

          await citizenProfileService.updateCitizenProfile(
            role === "center"
              ? "centers"
              : role === "partner"
              ? "partners"
              : "users",
            updateId,
            {
              latitude: lat,
              longitude: lng,
            }
          );
          console.log("Profile data: ", profileData);
        } else {
          throw new Error("No results found");
        }
      } catch (err) {
        setShowPopup(true);
      }
    }
    if (profileData.center_id && role === "citizen") {
      await fetchUsersWithCenterId(profileData.center_id);
    } else if (role === "center") {
      await fetchUsersWithCenterId(profileData.id);
    } else if (role === "partner") {
      const partnerRef = await citizenProfileService.getCitizenProfile(
        "users",
        userId
      );
      console.log("centerId: ", partnerRef.center_id);
      await fetchUsersWithCenterId(partnerRef.center_id);
    }

    // } else {
    //   console.log("No data found for the user");
    // }

    if (mapRef.current) {
      if (coordinates) {
        mapRef.current.setCenter({
          lat: coordinates.lat,
          lng: coordinates.lon,
        });
      }
    }
  };

  // Fetch users with the same center_id from different collections
  const fetchUsersWithCenterId = async (center_id) => {
    console.log("center_id: ", center_id);
    const collectionsToSearch = ["users", "partners", "centers"];
    let relatedUsersData = [];
    let centerIdArray;

    if (role == "partner" && center_id !== null) {
      const userDocRef = doc(db, "users", userId);
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        centerIdArray = userDoc.data().center_id;
      }
    } else {
      centerIdArray = Array.isArray(center_id) ? center_id : [center_id];
    }

    for (const collectionName of collectionsToSearch) {
      let relatedUsersQuery;

      if (centerIdArray.length > 0) {
        if (collectionName === "partners") {
          relatedUsersQuery = query(
            collection(db, "users"),
            where("center_id", "array-contains-any", centerIdArray)
          );
          const snapshot = await getDocs(relatedUsersQuery);
          if (snapshot.docs.length > 0) {
            const partnerId = snapshot.docs[0].data().partner_id;
            const partnerDocRef = doc(db, "partners", partnerId);
            const partnerDocSnap = await getDoc(partnerDocRef);
            if (partnerDocSnap.exists()) {
              relatedUsersData.push({
                id: partnerDocSnap.id,
                ...partnerDocSnap.data(),
              });
            }
          }
        } else if (collectionName === "centers") {
          console.log("centers: ", centerIdArray);
          const docRef = doc(db, collectionName, centerIdArray[0]);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            relatedUsersData.push({
              id: docSnap.id,
              ...docSnap.data(),
            });
          }
        } else {
          relatedUsersQuery = query(
            collection(db, collectionName),
            where("center_id", "==", centerIdArray[0])
          );
        }

        if (relatedUsersQuery) {
          const snapshot = await getDocs(relatedUsersQuery);

          relatedUsersData = [
            ...relatedUsersData,
            ...snapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            })),
          ];
        }
      }
    }

    setRelatedUsers(relatedUsersData);
  };

  // Fetch all community contacts from Firestore
  const fetchCommunityContacts = async () => {
    const contactsQuery = query(
      collection(db, "community-contacts"),
      where("is_deleted", "==", false)
    );
    const fetchCommunityContacts = async () => {
      if (!data || !data.center_id) {
        console.log("No center_id available for the current user");
        return;
      }
  
      const contactsQuery = query(
        collection(db, "community-contacts"),
        where("is_deleted", "==", false),
        where("center_id", "==", data.center_id)
      );
  
      const contactsSnapshot = await getDocs(contactsQuery);
      const contacts = contactsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

    const updatedContacts = await Promise.all(
      contacts.map(async (contact) => {
        if (contact.latitude && contact.longitude) {
          return contact;
        } else {
          try {
            const address = `${contact.address1}, ${contact.city}, ${contact.state}, ${contact.zip}`;
            const response = await fetch(
              `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
                address
              )}&key=${config.google_maps}`
            );
            const data = await response.json();
            if (data.results.length > 0) {
              const { lat, lng } = data.results[0].geometry.location;
              const contactRef = doc(db, "community-contacts", contact.id);
              await updateDoc(contactRef, {
                latitude: lat,
                longitude: lng,
              });

              return { ...contact, latitude: lat, longitude: lng };
            } else {
              throw new Error("No results found for address");
            }
          } catch (err) {
            console.error("Error fetching coordinates:", err);
            setShowPopup(true);
            return contact;
          }
        }
      })
    );

    setCommunityContacts(
      updatedContacts.filter((contact) => contact.latitude && contact.longitude)
    );
  };
};

  const handleMapLoad = (map) => {
    mapRef.current = map;
  };

  useEffect(() => {
    const auth = getAuth();

    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUserId(currentUser.uid);
      } else {
        setData(null);
        console.log("No user is logged in");
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (userId) {
      fetchData();
      fetchCommunityContacts();
    }
  }, [userId]);

  useEffect(() => {
    // Only set map center if coordinates are available
    if (mapRef.current && coordinates) {
      mapRef.current.setCenter({
        lat: coordinates.lat,
        lng: coordinates.lon,
      });
    }
  }, [coordinates]);

  // useEffect(() => {
  //   if (mapRef.current) {
  //     if (coordinates) {
  //       mapRef.current.setCenter({
  //         lat: coordinates.lat,
  //         lng: coordinates.lon,
  //       });
  //       mapRef.current.setZoom(15);
  //     }
  //   }
  // }, [coordinates]);

  // Determine marker color based on user_role
  const getMarkerColor = (role) => {
    switch (role) {
      case "center":
        return "green";
      case "partner":
        return "yellow";
      case "citizen":
        return "blue";
      case "community-contact":
        return "pink";
      default:
        return "red";
    }
  };

  const getMarkerImage = (role) => {
    const color = getMarkerColor(role);
    return {
      url: `http://maps.google.com/mapfiles/ms/icons/${color}-dot.png`,
      scaledSize: new window.google.maps.Size(35, 35),
      labelOrigin: new window.google.maps.Point(17, -10),
    };
  };

  const handleMarkerHover = (markerData) => {
    console.log("markerData: ", markerData);
    setHoveredMarker(markerData);
  };

  const handleMarkerLeave = () => {
    setHoveredMarker(null);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const dialogContent = () => {
    return (
      <div style={{ textAlign: "center", padding: "20px" }}>
        {/* You can replace this with your own icon or image */}
        <div style={{ fontSize: "48px", marginBottom: "20px" }}>🚀</div>
        <h2
          style={{
            fontSize: "24px",
            fontWeight: "normal",
            color: "#1e1919",
            marginBottom: "10px",
          }}
        >
          No coordinates found for this address
        </h2>
        <p style={{ fontSize: "16px", color: "#637381", marginBottom: "20px" }}>
          Update your address and retry.
        </p>
        <button
          onClick={handleClosePopup}
          className="btn-default"
          style={{
            padding: "10px 20px",
            cursor: "pointer",
          }}
        >
          Got it!
        </button>
      </div>
    );
  };

  const renderMarkerInfo = () => {
    if (!hoveredMarker) return null;

    const role =
      hoveredMarker.user_role || hoveredMarker.userType?.toLowerCase();

    // Display different information based on the type of marker hovered
    if (role === "center") {
      return (
        <div>
          <p>Name of Hub: {hoveredMarker.name_of_hub || "N/A"}</p>
          <p>Address: {hoveredMarker.address || "N/A"}</p>
          <p>Email: {hoveredMarker.email || "N/A"}</p>
          <p>Mobile: {hoveredMarker.mobile_number || "N/A"}</p>
          {/* <p>Role: {role}</p> */}
        </div>
      );
    } else if (role === "partner") {
      const fullAddress = `${hoveredMarker.address1 || ""} ${
        hoveredMarker.address2 || ""
      }, ${hoveredMarker.city || ""}, ${hoveredMarker.state || ""} ${
        hoveredMarker.zip || ""
      }`;
      return (
        <div>
          <p>Name of Org: {hoveredMarker.name_of_org || "N/A"}</p>
          <p>Address: {fullAddress || "N/A"}</p>
          <p>Email: {hoveredMarker.email || "N/A"}</p>
          <p>Mobile: {hoveredMarker.mobile_number || "N/A"}</p>
          {/* <p>Role: {role}</p> */}
        </div>
      );
    } else if (role === "citizen") {
      return null;
    } else {
      const fullAddress = `${hoveredMarker.address1 || ""} ${
        hoveredMarker.address2 || ""
      }, ${hoveredMarker.city || ""}, ${hoveredMarker.state || ""} ${
        hoveredMarker.zip || ""
      }`;
      return (
        <div>
          <p>Name: {hoveredMarker.Name || "N/A"}</p>
          <p>Occupation: {hoveredMarker.occupation || "N/A"}</p>
          <p>Address: {fullAddress || "N/A"}</p>
          <p>Email: {hoveredMarker.email || "N/A"}</p>
          <p>Mobile: {hoveredMarker.mobileNumber || "N/A"}</p>
        </div>
      );
    }
  };

  return (
    <div className="relative h-[calc(100vh-64px)] w-full">
      <APIProvider apiKey={config.google_maps}>
        <VISGoogleMap
          key={
            coordinates
              ? `${coordinates.lat}-${coordinates.lon}`
              : "default-map"
          }
          mapId="world-map"
          defaultZoom={coordinates ? 15 : 4} // Adjust zoom level based on coordinates existence
          defaultCenter={
            coordinates
              ? { lat: coordinates.lat, lng: coordinates.lon }
              : { lat: 39.8, lng: -98.57 } // Center of the US
          }
          ref={mapRef}
          onLoad={handleMapLoad}
          options={{
            // Disable initial map animation to prevent flashing
            disableDefaultUI: true,
            gestureHandling: "greedy",
          }}
        >
          {/* Render current user marker */}
          {coordinates && coordinates.lat && coordinates.lon && role && (
            <Marker
              label={
                labelText
                  ? {
                      text: labelText,
                      fontSize: "16px",
                      fontWeight: "bold",
                    }
                  : null
              }
              key={role}
              position={{
                lat: coordinates.lat,
                lng: coordinates.lon,
              }}
              icon={getMarkerImage(role)}
              onMouseOver={() => handleMarkerHover(data)}
              onMouseOut={handleMarkerLeave}
            />
          )}

          {/* Render community contacts */}
          {communityContacts.map((contact) => (
            <Marker
              key={contact.id}
              label={{
                text: contact.occupation || "N/A",
                fontSize: "16px",
                fontWeight: "bold",
              }}
              position={{
                lat: contact.latitude,
                lng: contact.longitude,
              }}
              icon={getMarkerImage("community-contact")}
              onMouseOver={() => handleMarkerHover(contact)}
              onMouseOut={handleMarkerLeave}
            />
          ))}

          {/* Render related users with the same center_id */}
          {relatedUsers.map((user) => {
            const role = user.user_role || user.userType?.toLowerCase();
            const labelText =
              role === "center"
                ? user.name_of_hub
                  ? `${user.name_of_hub}`
                  : "No Hub Name"
                : role === "partner"
                ? user.name_of_org
                  ? `${user.name_of_org}`
                  : "No Org Name"
                : user.name || " ";

            return (
              <Marker
                key={user.id}
                label={{
                  text: labelText,
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
                position={{ lat: user.latitude, lng: user.longitude }}
                icon={getMarkerImage(role)}
                onMouseOver={() => handleMarkerHover(user)}
                onMouseOut={handleMarkerLeave}
              />
            );
          })}

          {/* Info box when a marker is hovered */}
          {hoveredMarker && (
            <div
              style={{
                position: "absolute",
                left: `20px`,
                top: `90px`,
                backgroundColor: "white",
                padding: "10px",
                borderRadius: "5px",
                boxShadow: "0 2px 5px rgba(0,0,0,0.3)",
              }}
            >
              {renderMarkerInfo()}
            </div>
          )}
        </VISGoogleMap>
      </APIProvider>
      {/* <div className="absolute bottom-4 left-4 right-4 z-10">
        <div className="inline-flex flex-wrap items-center justify-start gap-2 rounded-md bg-white p-2 shadow-md">
          <b className="mr-4 text-sm uppercase">LEGEND</b>
          <LegendButton icon={<CentersIcon />} text="Certified Centers" />
          <LegendButton
            icon={<PartnershipIcon />}
            text="Non-Profit Coalition Partners"
          />
          <LegendButton icon={<FireIcon />} text="Fire" bgColor="#BF0000" />
          <LegendButton icon={<FloodIcon />} text="Flood" bgColor="#1945B4" />
          <LegendButton
            icon={<EarthquakeIcon />}
            text="Earthquake"
            bgColor="#C94D26"
          />
          <LegendButton
            icon={<HurricaneIcon />}
            text="Hurricane"
            bgColor="#6222D9"
          />
          <LegendButton
            icon={<TornadoIcon />}
            text="Tornado"
            bgColor="#808080"
          />
        </div>
      </div>
      <DialogComponent
        width="400px"
        isModal={true}
        visible={showPopup}
        close={handleClosePopup}
        header={null}
        content={dialogContent}
        showCloseIcon={true}
        closeOnEscape={true}
        target={document.body}
        cssClass="welcome-dialog"
      /> */}
    </div>
  );
};

const LegendButton = ({ icon, text, bgColor }) => (
  <button className="text-darkslategray hover:bg-gray-100 flex items-center gap-2 rounded-md border border-gainsboro-200 bg-white px-3 py-1 text-sm font-semibold">
    {bgColor ? (
      <div
        className="flex h-5 w-5 items-center justify-center rounded-full"
        style={{ backgroundColor: bgColor }}
      >
        {icon}
      </div>
    ) : (
      icon
    )}
    <span>{text}</span>
  </button>
);
export default Map;
