import React, { useState, useEffect } from "react";
import { FaEdit, FaSave, FaTimes } from "react-icons/fa";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { useParams, useNavigate } from "react-router-dom";
import { db } from "../../firebase";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Page,
  Sort,
  Edit,
  CommandColumn,
  Toolbar,
  Filter,
  ColumnMenu,
} from "@syncfusion/ej2-react-grids";
import {
  AccumulationChartComponent,
  AccumulationSeriesCollectionDirective,
  AccumulationSeriesDirective,
  PieSeries,
  AccumulationLegend,
  AccumulationDataLabel,
  AccumulationTooltip,
  ChartComponent,
  SeriesCollectionDirective,
  SeriesDirective,
  ColumnSeries,
  Category,
  Legend,
  DataLabel,
  Tooltip,
} from "@syncfusion/ej2-react-charts";
import { USStates } from "../datasource";

const DisasterDetail = ({ propId  }) => { 
  const { id } = useParams();
  const disasterId = id || propId;
  const [disasterData, setDisasterData] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [tempData, setTempData] = useState({});
  const [lastEdited, setLastEdited] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDisaster = async () => {
      if (!disasterId) {
        console.error("No ID provided to DisasterDetail component.");
        return;
      }

      try {
        const docRef = doc(db, "declared-disaster", disasterId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setDisasterData(docSnap.data());
          setTempData(docSnap.data());
          setLastEdited(new Date().toLocaleString());
        } else {
          console.error("No document found with the provided ID:", disasterId);
        }
      } catch (error) {
        console.error("Error fetching disaster data:", error);
      }
    };

    fetchDisaster();
  }, [disasterId]);


  const handleEditClick = () => {
    setIsEditMode(true);
  };

  const handleCancelClick = () => {
    setIsEditMode(false);
    setTempData(disasterData);
  };

  const handleSaveClick = async () => {
    const docRef = doc(db, "declared-disaster", id);
    await updateDoc(docRef, tempData);
    setDisasterData(tempData);
    setIsEditMode(false);
    setLastEdited(new Date().toLocaleString());
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTempData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const [totalRecords, setTotalRecords] = useState(0);
  const [totalValueSum, setTotalValue] = useState(0);
  const [immediateAssistance, setImmediateAssistance] = useState(0);
  const [recoveryAssistance, setRecoveryAssistance] = useState(0);
  const [disasterImpactData, setDisasterImpactData] = useState({
    affected: 0,
    notAffected: 0,
  });
  const [injuryData, setInjuryData] = useState({
    noInjury: 0,
    minorInjury: 0,
    medicalTreatment: 0,
    visitedHospital: 0,
    admitted: 0,
  });
  const [homeDamageData, setHomeDamageData] = useState({
    noDamage: 0,
    slightDamage: 0,
    limitedDamage: 0,
    severeDamage: 0,
    totalDestruction: 0,
  });
  const [homesDestroyedData, setHomesDestroyedData] = useState({
    damaged: 0,
    destroyed: 0,
    undamaged: 0,
  });
  const [typeOfHomeDamageData, setTypeOfHomeDamageData] = useState({
    "Check All": 0,
    Siding: 0,
    Flooring: 0,
    Cabinets: 0,
    "Shed/garage": 0,
    Roof: 0,
    Foundation: 0,
    "Floor Joists": 0,
    HVAC: 0,
    Driveway: 0,
    Gutters: 0,
    Ceiling: 0,
    Drywall: 0,
    Electrical: 0,
    "Yard/Uprooted trees": 0,
    Other: 0,
  });

  const ChartCard = ({ title, children }) => (
    <div className="min-w-[300px] flex-1 rounded-md border border-solid border-gainsboro-200 bg-white p-5 shadow-lg">
      <h2 className="mb-4 flex items-center justify-between text-sm font-bold text-blue-500">
        {title}
        <span className="text-gray-400">⋮</span>
      </h2>
      {children}
    </div>
  );

  const ChartCardLong = ({ title, children }) => (
    <div className="w-fit flex-1 rounded-md border border-solid border-gainsboro-200 bg-white p-5 shadow-lg">
      <h2 className="mb-4 flex items-center justify-between text-sm font-bold text-blue-500">
        {title}
        <span className="text-gray-400">⋮</span>
      </h2>
      {children}
    </div>
  );

  const chartColorPalette = [
    "#EF4444",
    "#F59E0B",
    "#3B82F6",
    "#10B981",
    "#F97316",
    "#8B5CF6",
  ];

  const getCurrentFormattedDate = () => {
    const currentDate = new Date();
    return currentDate.toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    });
  };

  
  if (!disasterData) {
    return <p>Loading disaster details...</p>;
  }

  return (
    <div className="p-4">
      <header className="flex items-center justify-start gap-2">
        {/* <div className="flex items-center gap-4">
        <button
          type="button"
          className="flex cursor-pointer flex-row items-start justify-start gap-2 rounded-md bg-dodgerblue px-[9px] py-[5px] [border:none]"
          onClick={() => navigate("/disasterdeclaration")}
        >
          <p className="relative my-auto inline-block text-left text-xs font-semibold text-white [text-decoration:none]">
            Back
          </p>
        </button>
      </div> */}
        <p className=" text-left text-sm font-bold text-blue-600">
          DISASTER DETAILS
        </p>

        {/* <div className="mr-3 flex items-center gap-2">
          {isEditMode ? (
            <>
              <button
                type="button"
                className="flex cursor-pointer items-center gap-1 rounded bg-blue-500 px-3 py-1.5 text-xs font-semibold text-white"
                onClick={handleSaveClick}
              >
                <FaSave className="h-3 w-3" /> Save
              </button>
              <button
                type="button"
                className="flex cursor-pointer items-center gap-1 rounded bg-gray-400 px-3 py-1.5 text-xs font-semibold text-black"
                onClick={handleCancelClick}
              >
                <FaTimes className="h-3 w-3" /> Cancel
              </button>
            </>
          ) : (
            <button
              type="button"
              className="flex cursor-pointer items-center gap-1 rounded bg-blue-500 px-3 py-1.5 text-xs font-semibold text-white"
              onClick={handleEditClick}
            >
              <FaEdit className="h-3 w-3" />
              Edit
            </button>
          )}
        </div> */}
      </header>

      {/* Report Generator Start */}
      <div className="w-full flex-1 rounded-mini bg-white p-5">
        <section
          className={`text-13xl flex w-full max-w-full flex-col items-start justify-start gap-5 text-left`}
        >
          <div className="flex w-full items-center justify-between gap-2.5">
            <p className="relative  text-[20px] font-extrabold leading-normal text-blue-500 [text-decoration:none]">
              {" "}
              {disasterData?.disaster_name}{" "}
            </p>
            <select className="relative  text-[20px] font-extrabold leading-normal text-blue-500 [text-decoration:none]">
              <option value="yearly">Yearly</option>
              <option value="monthly">Monthly</option>
              <option value="daily">Daily</option>
            </select>
          </div>

          <div className="flex w-full flex-row items-start justify-end self-stretch text-sm text-blue-500">
            <div className="flex w-full max-w-full flex-col items-start justify-start gap-5">
              <div className="mq1100:pl-[33px] mq1100:pr-[33px] mq1100:box-border box-border flex w-full max-w-full flex-row items-start justify-start py-0">
                <div className="flex w-full flex-1 flex-row flex-wrap content-start items-start justify-start gap-5">
                  <div className="z-[1] box-border flex flex-1 flex-row items-start justify-start gap-[13px] rounded-md border-[1px] border-solid border-gainsboro-200 bg-white pb-6 pl-[22px] pr-3 pt-3 shadow-lg">
                    <div className="relative box-border hidden h-[158px] w-[282px] rounded-b-mini rounded-tl-none rounded-tr-mini border-[1px] border-solid border-gainsboro-200 bg-white" />
                    <div className="flex flex-1 flex-col items-start justify-start px-0 pb-0 pt-3">
                      <div className="z-[2] flex flex-col items-start justify-start gap-[9px] self-stretch py-0 pl-0 pr-[37px]">
                        <h2 className="relative m-0 inline-block min-w-[108px] font-[inherit] text-inherit font-bold text-blue-800">
                          People Affected
                        </h2>
                        <div className="text-29xl flex flex-row items-start justify-start gap-2.5 self-stretch text-darkslategray-300">
                          <div className="relative inline-block text-[40px] font-semibold text-darkslategray-300">
                            {totalRecords}
                          </div>
                          <div className="flex flex-col items-start justify-start px-0 pb-0 pt-[18.5px] text-base">
                            <h1 className="relative m-0 inline-block min-w-[54px] font-[inherit] text-inherit font-semibold">
                              People
                            </h1>
                          </div>
                        </div>
                        <div className="relative pb-2 font-semibold text-gray1-100">
                          As of {getCurrentFormattedDate()}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="box-border flex flex-1 flex-row items-start justify-start gap-[5.5px] rounded-md border-[1px] border-solid border-gainsboro-200 bg-white pb-[33px] pl-[23px] pr-[9px] pt-3 shadow-lg">
                    <div className="relative box-border hidden h-[158px] w-[273px] rounded-b-mini rounded-tl-none rounded-tr-mini border-[1px] border-solid border-gainsboro-200 bg-white" />
                    <div className="flex flex-1 flex-col items-start justify-start px-0 pb-0 pt-3">
                      <div className="z-[1] flex flex-col items-start justify-start gap-[9px] self-stretch">
                        <h2 className="relative m-0 font-[inherit] text-inherit font-bold">
                          Immediate Response Assistance Received
                        </h2>
                        <div className="relative inline-block text-[40px] font-semibold text-darkslategray-300">
                          ${immediateAssistance}
                        </div>
                        <div className="relative font-semibold text-gray1-100">
                          As of {getCurrentFormattedDate()}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="box-border flex flex-1 flex-row items-start justify-start gap-[5.5px] rounded-md border-[1px] border-solid border-gainsboro-200 bg-white pb-[33px] pl-[23px] pr-[9px] pt-3 shadow-lg">
                    <div className="relative box-border hidden h-[158px] w-[273px] rounded-b-mini rounded-tl-none rounded-tr-mini border-[1px] border-solid border-gainsboro-200 bg-white" />
                    <div className="flex flex-1 flex-col items-start justify-start px-0 pb-0 pt-3">
                      <div className="z-[1] flex flex-col items-start justify-start gap-[9px] self-stretch">
                        <h2 className="relative m-0 font-[inherit] text-inherit font-bold">
                          Recovery Assistance Received
                        </h2>
                        <div className="relative inline-block text-[40px] font-semibold text-darkslategray-300">
                          ${recoveryAssistance}
                        </div>
                        <div className="relative font-semibold text-gray1-100">
                          As of {getCurrentFormattedDate()}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="mx-auto mt-4 w-full">
          <div className="flex flex-wrap justify-between gap-5">
            <ChartCard title="Disaster Impact">
              <AccumulationChartComponent
                id="disaster-impact"
                tooltip={{ enable: true }}
                height="300px"
                legendSettings={{ visible: true, position: "Bottom" }}
              >
                <Inject
                  services={[
                    PieSeries,
                    AccumulationLegend,
                    AccumulationDataLabel,
                    AccumulationTooltip,
                  ]}
                />
                <AccumulationSeriesCollectionDirective>
                  <AccumulationSeriesDirective
                    dataSource={[
                      { x: "Affected", y: disasterImpactData.affected },
                      {
                        x: "Not Affected",
                        y: disasterImpactData.notAffected,
                      },
                    ]}
                    xName="x"
                    yName="y"
                    innerRadius="40%"
                    palettes={chartColorPalette}
                    dataLabel={{
                      visible: true,
                      position: "Outside",
                      name: "x",
                    }}
                  />
                </AccumulationSeriesCollectionDirective>
              </AccumulationChartComponent>
            </ChartCard>
            <ChartCard title="Injury">
              <ChartComponent
                id="injury"
                primaryXAxis={{ valueType: "Category" }}
                primaryYAxis={{ minimum: 0, maximum: 100, interval: 10 }}
                height="300px"
              >
                <Inject
                  services={[
                    ColumnSeries,
                    Category,
                    Legend,
                    DataLabel,
                    Tooltip,
                  ]}
                />
                <SeriesCollectionDirective>
                  <SeriesDirective
                    dataSource={[
                      { x: "No Injury", y: injuryData.noInjury },
                      { x: "Minor Injury", y: injuryData.minorInjury },
                      {
                        x: "Medical Treatment",
                        y: injuryData.medicalTreatment,
                      },
                      {
                        x: "Visited Hospital",
                        y: injuryData.visitedHospital,
                      },
                      { x: "Admitted", y: injuryData.admitted },
                    ]}
                    xName="x"
                    yName="y"
                    type="Column"
                    palettes={chartColorPalette}
                  />
                </SeriesCollectionDirective>
              </ChartComponent>
            </ChartCard>

            <ChartCard title="Home Damage">
              <ChartComponent
                id="home-damage"
                primaryXAxis={{ valueType: "Category" }}
                primaryYAxis={{ minimum: 0, maximum: 70, interval: 10 }}
                height="300px"
              >
                <Inject
                  services={[
                    ColumnSeries,
                    Category,
                    Legend,
                    DataLabel,
                    Tooltip,
                  ]}
                />
                <SeriesCollectionDirective>
                  <SeriesDirective
                    dataSource={[
                      { x: "No Damage", y: homeDamageData.noDamage },
                      { x: "Slight Damage", y: homeDamageData.slightDamage },
                      {
                        x: "Limited Damage",
                        y: homeDamageData.limitedDamage,
                      },
                      { x: "Severe Damage", y: homeDamageData.severeDamage },
                      {
                        x: "Total Destruction",
                        y: homeDamageData.totalDestruction,
                      },
                    ]}
                    xName="x"
                    yName="y"
                    type="Column"
                    palettes={chartColorPalette}
                  />
                </SeriesCollectionDirective>
              </ChartComponent>
            </ChartCard>
            <ChartCard title="Homes Destroyed">
              <AccumulationChartComponent
                id="homes-destroyed"
                tooltip={{ enable: true }}
                height="300px"
                legendSettings={{ visible: true, position: "Bottom" }}
              >
                <Inject
                  services={[
                    PieSeries,
                    AccumulationLegend,
                    AccumulationDataLabel,
                    AccumulationTooltip,
                  ]}
                />
                <AccumulationSeriesCollectionDirective>
                  <AccumulationSeriesDirective
                    dataSource={[
                      { x: "Damaged", y: homesDestroyedData.damaged },
                      { x: "Destroyed", y: homesDestroyedData.destroyed },
                      { x: "Undamaged", y: homesDestroyedData.undamaged },
                    ]}
                    xName="x"
                    yName="y"
                    innerRadius="40%"
                    palettes={chartColorPalette}
                    dataLabel={{
                      visible: true,
                      position: "Outside",
                      name: "x",
                      format: "{point.x}: {point.y}%",
                    }}
                  />
                </AccumulationSeriesCollectionDirective>
              </AccumulationChartComponent>
            </ChartCard>
          </div>
        </section>

        <section className="mt-4 w-full">
          <div className="flex justify-between gap-5">
            <ChartCardLong title="Type of Home Damage">
              <ChartComponent
                id="home-damage-type"
                primaryXAxis={{
                  valueType: "Category",
                  labelStyle: { size: "10px" },
                  enableTextWrapping: true,
                  maximumLabelWidth: 60,
                  labelIntersectAction: "Wrap",
                  majorGridLines: { width: 0 },
                  minorGridLines: { width: 0 },
                  majorTickLines: { width: 0 },
                  minorTickLines: { width: 0 },
                }}
                primaryYAxis={{
                  minimum: 0,
                  maximum: 70,
                  interval: 10,
                  labelFormat: "{value}%",
                }}
                height="350px"
                chartArea={{ border: { width: 0 } }}
                palettes={["#1e90ff"]}
                tooltip={{ enable: true }}
              >
                <Inject
                  services={[ColumnSeries, Category, DataLabel, Tooltip]}
                />
                <SeriesCollectionDirective>
                  <SeriesDirective
                    dataSource={[
                      {
                        x: "Check All",
                        y: typeOfHomeDamageData["Check All"],
                      },
                      { x: "Siding", y: typeOfHomeDamageData.Siding },
                      { x: "Flooring", y: typeOfHomeDamageData.Flooring },
                      { x: "Cabinets", y: typeOfHomeDamageData.Cabinets },
                      {
                        x: "Shed/garage",
                        y: typeOfHomeDamageData["Shed/garage"],
                      },
                      { x: "Roof", y: typeOfHomeDamageData.Roof },
                      { x: "Foundation", y: typeOfHomeDamageData.Foundation },
                      {
                        x: "Floor Joists",
                        y: typeOfHomeDamageData["Floor Joists"],
                      },
                      { x: "HVAC", y: typeOfHomeDamageData.HVAC },
                      { x: "Driveway", y: typeOfHomeDamageData.Driveway },
                      { x: "Gutters", y: typeOfHomeDamageData.Gutters },
                      { x: "Ceiling", y: typeOfHomeDamageData.Ceiling },
                      { x: "Drywall", y: typeOfHomeDamageData.Drywall },
                      { x: "Electrical", y: typeOfHomeDamageData.Electrical },
                      {
                        x: "Yard/Uprooted trees",
                        y: typeOfHomeDamageData["Yard/Uprooted trees"],
                      },
                      { x: "Other", y: typeOfHomeDamageData.Other },
                    ]}
                    xName="x"
                    yName="y"
                    type="Column"
                    palettes={chartColorPalette}
                    dataLabel={{
                      visible: true,
                      position: "Top",
                      format: "{value}%",
                      font: { size: "10px" },
                    }}
                  />
                </SeriesCollectionDirective>
              </ChartComponent>
            </ChartCardLong>
          </div>
        </section>
      </div>
      {/* Report Generator End */}

      {/* <header className="flex items-center justify-end">
        <div className="mr-3 flex items-center gap-2">
          {isEditMode ? (
            <>
              <button
                type="button"
                className="flex cursor-pointer items-center gap-1 rounded bg-blue-500 px-3 py-1.5 text-xs font-semibold text-white"
                onClick={handleSaveClick}
              >
                <FaSave className="h-3 w-3" /> Save
              </button>
              <button
                type="button"
                className="flex cursor-pointer items-center gap-1 rounded bg-gray-400 px-3 py-1.5 text-xs font-semibold text-black"
                onClick={handleCancelClick}
              >
                <FaTimes className="h-3 w-3" /> Cancel
              </button>
            </>
          ) : (
            <button
              type="button"
              className="flex cursor-pointer items-center gap-1 rounded bg-blue-500 px-3 py-1.5 text-xs font-semibold text-white"
              onClick={handleEditClick}
            >
              <FaEdit className="h-3 w-3" />
              Edit
            </button>
          )}
        </div>
      </header>

      <div className="grid grid-cols-1 gap-4">
        <div>
          <label className="text-gray-700 block text-xs font-semibold">
            Name
          </label>
          <input
            type="text"
            name="disaster_name"
            value={tempData.disaster_name || ""}
            onChange={handleInputChange}
            disabled={!isEditMode}
            className="mt-1 w-full rounded-md border p-2"
          />
        </div>

        <div>
          <label className="text-gray-700 block text-xs font-semibold">
            Description
          </label>
          <input
            type="text"
            name="description"
            value={tempData.description || ""}
            onChange={handleInputChange}
            disabled={!isEditMode}
            className="mt-1 w-full rounded-md border p-2"
          />
        </div>

        <div>
          <label className="text-gray-700 block text-xs font-semibold">
            Type
          </label>
          <select
            name="disaster_type"
            value={tempData.disaster_type || ""}
            onChange={handleInputChange}
            disabled={!isEditMode}
            className="mt-1 w-full rounded-md border p-2"
          >
            <option value="">Select Type</option>
            <option value="Fire">Fire</option>
            <option value="Flood">Flood</option>
            <option value="Tornado">Tornado</option>
            <option value="Hurricane">Hurricane</option>
            <option value="Earthquake">Earthquake</option>
          </select>
        </div>

        <div>
          <label className="text-gray-700 block text-xs font-semibold">
            State
          </label>
          <select
            name="disaster_state"
            value={tempData.disaster_state || ""}
            onChange={handleInputChange}
            disabled={!isEditMode}
            className="mt-1 w-full rounded-md border p-2"
          >
            <option value="">Select State</option>
            {USStates.map((state) => (
              <option key={state.stateID} value={state.name}>
                {state.name}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label className="text-gray-700 block text-xs font-semibold">
            Affected Counties
          </label>
          <input
            type="text"
            name="affected_counties"
            value={tempData.affected_counties || ""}
            onChange={handleInputChange}
            disabled={!isEditMode}
            className="mt-1 w-full rounded-md border p-2"
          />
        </div>

        <div>
          <label className="text-gray-700 block text-xs font-semibold">
            Date of Disaster
          </label>
          <input
            type="date"
            name="date_of_disaster"
            value={tempData.date_of_disaster || ""}
            onChange={handleInputChange}
            disabled={!isEditMode}
            className="mt-1 w-full rounded-md border p-2"
          />
        </div>

        <div>
          <label className="text-gray-700 block text-xs font-semibold">
            Date Declared
          </label>
          <input
            type="date"
            name="date_declared"
            value={tempData.date_declared || ""}
            onChange={handleInputChange}
            disabled={!isEditMode}
            className="mt-1 w-full rounded-md border p-2"
          />
        </div>

        <div>
          <label className="text-gray-700 block text-xs font-semibold">
            Declared By
          </label>
          <input
            type="text"
            name="declared_by"
            value={tempData.declared_by || ""}
            onChange={handleInputChange}
            disabled={!isEditMode}
            className="mt-1 w-full rounded-md border p-2"
          />
        </div>

        <div>
          <label className="text-gray-700 block text-xs font-semibold">
            Disaster Stage
          </label>
          <select
            name="disaster_stage"
            value={tempData.disaster_stage || ""}
            onChange={handleInputChange}
            disabled={!isEditMode}
            className="mt-1 w-full rounded-md border p-2"
          >
            <option value="">Select Stage</option>
            <option value="Response">Response</option>
            <option value="Recovery">Recovery</option>
            <option value="Relief">Relief</option>
          </select>
        </div>

        <div>
          <label className="text-gray-700 block text-xs font-semibold">
            Start Date
          </label>
          <input
            type="date"
            name="start_date"
            value={tempData.start_date || ""}
            onChange={handleInputChange}
            disabled={!isEditMode}
            className="mt-1 w-full rounded-md border p-2"
          />
        </div>

        <div>
          <label className="text-gray-700 block text-xs font-semibold">
            End Date
          </label>
          <input
            type="date"
            name="end_date"
            value={tempData.end_date || ""}
            onChange={handleInputChange}
            disabled={!isEditMode}
            className="mt-1 w-full rounded-md border p-2"
          />
        </div>

        <div>
          <label className="text-gray-700 block text-xs font-semibold">
            Declared
          </label>
          <select
            name="declared"
            value={tempData.declared || ""}
            onChange={handleInputChange}
            disabled={!isEditMode}
            className="mt-1 w-full rounded-md border p-2"
          >
            <option value="">Select Declared By</option>
            <option value="FEMA">FEMA</option>
            <option value="Red Cross">Red Cross</option>
            <option value="State">State</option>
          </select>
        </div>

        <div>
          <label className="text-gray-700 block text-xs font-semibold">
            Status
          </label>
          <select
            name="disaster_status"
            value={tempData.disaster_status || "inactive"}
            onChange={handleInputChange}
            disabled
            className="mt-1 w-full rounded-md border p-2"
          >
            <option value="active">Active</option>
            <option value="inactive">Inactive</option>
          </select>
        </div>

        <div>
          <label className="text-gray-700 block text-xs font-semibold">
            Approved By
          </label>
          <input
            type="text"
            name="approved_by"
            value={tempData.approved_by || ""}
            onChange={handleInputChange}
            disabled
            className="mt-1 w-full rounded-md border p-2"
          />
        </div>

        <div>
          <label className="text-gray-700 block text-xs font-semibold">
            Approved Date
          </label>
          <input
            type="date"
            name="approved_date"
            value={tempData.approved_date || ""}
            onChange={handleInputChange}
            disabled
            className="mt-1 w-full rounded-md border p-2"
          />
        </div>

        <div>
          <label className="text-gray-700 block text-xs font-semibold">
            Status Date
          </label>
          <input
            type="date"
            name="status_date"
            value={tempData.status_date || ""}
            onChange={handleInputChange}
            disabled={!isEditMode}
            className="mt-1 w-full rounded-md border p-2"
          />
        </div>
      </div> */}
    </div>
  );
};

export default DisasterDetail;
