import React, { useEffect, useRef, useState } from "react";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Sort,
  Filter,
  Edit,
  Toolbar,
  CommandColumn,
  Inject,
  ColumnMenu,
} from "@syncfusion/ej2-react-grids";
import partnerProfileService from "../service/partner/PartnerProfileService";
import userService from "../service/UserService";
import { Query } from "@syncfusion/ej2-data";
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
  getDoc,
  doc,
} from "firebase/firestore";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import {
  CheckBoxSelection,
  MultiSelectComponent,
} from "@syncfusion/ej2-react-dropdowns";
import CenterService from "../service/center/CenterService";
import { useAuth } from "../components/AuthContext";

const Employees = ({ className = "", ...props }) => {
  const gridRef = useRef(null);
  const gridRefSearch = useRef(null);
  const [orgId, setOrgId] = useState(null);
  const [orgType, setOrgType] = useState(null);
  const [organization, setOrganization] = useState({});
  const [employeesData, setEmployeesData] = useState([]);
  const [searchedEmployeesData, setSearchedEmployeesData] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const { role, setRoleBasedRoute } = useAuth();
  const sortSettings = {
    allowSorting: true,
  };
  const filterSettings = {
    type: "CheckBox",
  };
  const columnMenuItems = ["SortAscending", "SortDescending", "Filter"];
  const pageSettings = { pageSize: 10 };
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (props.data && props.data.orgId && props.data.orgType) {
      setOrgId(props.data.orgId);
      setOrgType(props.data.orgType);
      setOrganization(props.data.org);
      if (props.data.orgType === "center") {
        console.log("center");
        console.log("role", role);
      }
    }
  }, [props.data]);

  useEffect(() => {
    if (orgId && orgType) {
      switch (orgType) {
        case "partner":
          getPartnerEmployees();
          break;
        case "center":
          getCenterEmployees();
          break;
        default:
          setEmployeesData([]);
      }
    }
  }, [orgId, orgType]);

  const getPartnerEmployees = async () => {
    try {
      let emps = await partnerProfileService.getEmployees(orgId);
      emps = emps.filter((emp) => !emp.is_deleted);
      setEmployeesData(emps);
      console.log("emps", emps);
    } catch (error) {
      console.error("Error fetching employees", error);
    }
  };

  const getCenterEmployees = async () => {
    try {
      let emps = await CenterService.getEmployees(orgId);
      emps = emps.filter((emp) => !emp.is_deleted);
      setEmployeesData(emps);
    } catch (error) {
      console.error("Error fetching employees", error);
    }
  };

  const emailExistsAction = async (employeeData) => {
    switch (orgType) {
      case "partner":
        // get user, add organization subcollection to user, add to partner employees
        const user = await userService.getUserByEmail(employeeData.email);
        console.log("employeeData", user);
        console.log("orgId", orgId);
        const userOrg = await userService.getOrganizationById(
          employeeData.user_id,
          orgId
        );
        const newOrg = {
          ...organization,
          org_type: "partner",
          org_id: orgId,
          role: "partner",
          is_representative: employeeData.is_representative,
          is_admin: employeeData.is_admin,
        };
        if (userOrg) {
          let mergedOrg = { ...userOrg, ...newOrg };
          mergedOrg.is_deleted = false;
          await userService.updateOrganization(
            employeeData.id,
            orgId,
            mergedOrg
          );
        } else {
          await userService.createOrganizationWithOrgId(user.id, orgId, newOrg);
        }
        let emp = await partnerProfileService.getEmployeeByEmail(
          orgId,
          employeeData.email
        );
        if (emp) {
          let tmp = Object.assign(employeeData);
          delete tmp["id"];
          let newEmp = { ...emp, ...tmp };
          newEmp.is_deleted = false;
          await partnerProfileService.updateEmployee(emp.id, orgId, newEmp);
        } else {
          const newEmp = {
            ...employeeData,
            status: "active",
            user_id: user.id,
          };
          delete newEmp.id; // Delete the "id" field from newEmp
          console.log("newEmp", newEmp);
          await partnerProfileService.createEmployeeWithUserId(
            user.id,
            orgId,
            newEmp
          );
          // TODO send email
        }
        break;
      case "center":
        // TODO: create a link account if user registers.
        break;
      default:
      // do nothing
    }
  };

  const emailNotExistingAction = async (employeeData) => {
    switch (orgType) {
      case "partner":
        // Changed to save to employees subcollection for now then TODO: create a link account if user registers.
        let emp = await partnerProfileService.getEmployeeByEmail(
          orgId,
          employeeData.email
        );
        if (emp) {
          let tmp = Object.assign(employeeData);
          delete tmp["id"];
          let newEmp = { ...emp, ...tmp };
          newEmp.is_deleted = false;
          await partnerProfileService.updateEmployee(emp.id, orgId, newEmp);
        } else {
          const newEmp = {
            ...employeeData,
            status: "invited",
            id: Math.random().toString(36).substr(2, 9),
          };
          console.log("newEmp", newEmp);
          console.log("orgId", orgId);
          await partnerProfileService.createEmployee(orgId, newEmp);
          // TODO send email
        }
        break;
      case "center":
        break;
      default:
      // do nothing
    }
  };

  const editAction = async (employeeData) => {
    switch (orgType) {
      case "partner":
        // edit employee
        console.log("employeeData", employeeData);
        const user = await userService.getUserByEmail(employeeData.email);
        console.log("employeeData", user);
        console.log("orgId", orgId);
        const userOrg = await userService.getOrganizationById(user.id, orgId);
        const newOrg = {
          ...organization,
          org_type: "partner",
          org_id: orgId,
          role: "partner",
          is_representative: employeeData.is_representative,
          is_admin: employeeData.is_admin,
          default_role: employeeData.default_role,
          old_employee: true,
        };
        if (userOrg) {
          let mergedOrg = { ...userOrg, ...newOrg };
          mergedOrg.is_deleted = false;
          await userService.updateOrganization(user.id, orgId, mergedOrg);
        } else {
          await userService.createOrganizationWithOrgId(user.id, orgId, newOrg);
        }
        let emp = await partnerProfileService.getEmployeeByEmail(
          orgId,
          employeeData.email
        );
        if (emp) {
          let updatedEmp = {
            ...emp,
            default_role: employeeData.default_role,
            email: employeeData.email,
            first_name: employeeData.first_name,
            is_admin: employeeData.is_admin,
            is_representative: employeeData.is_representative,
            last_name: employeeData.last_name,
            roles: employeeData.roles, // Updated to "roles"
            user_id: user.id,
            is_deleted: false,
            old_employee: true,
          };
          await partnerProfileService.updateEmployee(emp.id, orgId, updatedEmp);
        } else {
          const newEmp = {
            default_role: employeeData.default_role,
            email: employeeData.email,
            first_name: employeeData.first_name,
            is_admin: employeeData.is_admin,
            is_representative: employeeData.is_representative,
            last_name: employeeData.last_name,
            roles: employeeData.roles, // Updated to "roles"
            user_id: user.id,
            is_deleted: false,
            old_employee: true,
          };
          console.log("newEmp", newEmp);
          await partnerProfileService.createEmployeeWithUserId(
            user.id,
            orgId,
            newEmp
          );
          // TODO send email
        }
        break;
      case "center":
        console.log("employeeData", employeeData);
        const user2 = await userService.getUserByEmail(employeeData.email);
        console.log("employeeData", 2);
        console.log("orgId", orgId);
        const userOrg2 = await userService.getOrganizationById(user2.id, orgId);
        const newOrg2 = {
          ...organization,
          org_type: "center",
          org_id: orgId,
          role: "center",
          is_representative: employeeData.is_representative,
          is_admin: employeeData.is_admin,
          default_role: employeeData.default_role,
          old_employee: true,
        };
        if (userOrg2) {
          let mergedOrg = { ...userOrg2, ...newOrg2 };
          mergedOrg.is_deleted = false;
          await userService.updateOrganization(user2.id, orgId, mergedOrg);
        } else {
          await userService.createOrganizationWithOrgId(
            user2.id,
            orgId,
            newOrg2
          );
        }
        let emp2 = await partnerProfileService.getEmployeeByEmail(
          orgId,
          employeeData.email
        );
        if (emp2) {
          let updatedEmp2 = {
            ...emp2,
            can_approve_disaster: employeeData.can_approve_disaster,
            can_declare_disaster: employeeData.can_declare_disaster,
            default_role: employeeData.default_role,
            email: employeeData.email,
            first_name: employeeData.first_name,
            is_admin: employeeData.is_admin,
            is_representative: employeeData.is_representative,
            last_name: employeeData.last_name,
            roles: employeeData.roles, // Updated to "roles"
            user_id: user2.id,
            is_deleted: false,
            old_employee: true,
          };
          await partnerProfileService.updateEmployeeCenter(
            emp2.id,
            orgId,
            updatedEmp2
          );
        } else {
          const newEmp2 = {
            can_approve_disaster: employeeData.can_approve_disaster,
            can_declare_disaster: employeeData.can_declare_disaster,
            default_role: employeeData.default_role,
            email: employeeData.email,
            first_name: employeeData.first_name,
            is_admin: employeeData.is_admin,
            is_representative: employeeData.is_representative,
            last_name: employeeData.last_name,
            roles: employeeData.roles, // Updated to "roles"
            user_id: user2.id,
            is_deleted: false,
            old_employee: true,
          };
          console.log("newEmp2", newEmp2);
          await partnerProfileService.createEmployeeWithUserIdCenter(
            user2.id,
            orgId,
            newEmp2
          );
          // TODO send email
        }
        break;
      default:
      // do nothing
    }
  };

  const deleteAction = async (employeeData) => {
    switch (orgType) {
      case "partner":
        // delete employee
        await partnerProfileService.updateEmployee(employeeData.id, orgId, {
          is_deleted: true,
        });
        const userOrg = await userService.getOrganizationById(
          employeeData.id,
          orgId
        );
        if (userOrg) {
          await userService.updateOrganization(employeeData.id, orgId, {
            is_deleted: true,
          });
        }
        break;
      case "center":
        await CenterService.updateEmployee(employeeData.id, orgId, {
          is_deleted: true,
        });
        const userOrg2 = await userService.getOrganizationById(
          employeeData.id,
          orgId
        );
        if (userOrg2) {
          await userService.updateOrganization(employeeData.id, orgId, {
            is_deleted: true,
          });
        }
        break;
      default:
      // do nothing
    }
  };

  const actionBegin = async (args) => {
    if (args.requestType === "save") {
      if (args.action === "add") {
        const exists = await userService.checkIfEmailExists(args.data.email);
        if (exists) {
          await emailExistsAction(args.data);
        } else {
          await emailNotExistingAction(args.data);
        }
        await getPartnerEmployees();
        if (gridRef.current) {
          setTimeout(() => {
            gridRef.current.refresh();
          }, 100);
        }
      } else if (args.action === "edit") {
        // Ensure roles are being saved correctly
        const selectedRoles = args.data.roles || []; // Get the selected roles from args.data
        const roleNames = selectedRoles
          .map((roleId) => {
            const role = roleDatasource.find((r) => r.id === roleId);
            return role ? role.idName : null; // Get the role name or null if not found
          })
          .filter(Boolean); // Filter out any null values

        // Update the employee data with role names
        args.data.roles = roleNames; // Update the roles in args.data
        console.log("Selected Roles:", args.data); // Log selected roles before saving

        await editAction(args.data); // Call the edit action with updated data
      }
    } else if (args.requestType === "delete") {
      await deleteAction(args.data[0]);
    }
    if (args.requestType === "beginEdit") {
      setTimeout(() => {
        if (args.dialog) {
          if (args.rowData.old_employee) {
            args.dialog.header = "Edit Employee"; // Set the desired header
          } else {
            args.dialog.header = "Add Employee"; // Set the desired header
          }
        }
      }, 0); // Delay execution to allow dialog to initialize
      setIsEditMode(true); // Set edit mode to true
      // Make columns visible again
      if (role === "center") {
        gridRef.current.columns.forEach((column) => {
          if (
            column.field === "can_declare_disaster" ||
            column.field === "can_approve_disaster" ||
            column.field === "is_representative" ||
            column.field === "is_admin"
          ) {
            column.visible = true;
          }
        });
      }
    } else if (args.requestType === "save" || args.requestType === "cancel") {
      setIsEditMode(false); // Reset edit mode on save or cancel
    }
  };

  const actionBeginSearch = async (args) => {
    if (args.requestType === "save") {
      if (args.action === "add") {
        const exists = await userService.checkIfEmailExists(args.data.email);
        if (exists) {
          await emailExistsAction(args.data);
        } else {
          await emailNotExistingAction(args.data);
        }
        if (orgType == "partner") await getPartnerEmployees();
        else if (orgType == "center") await getCenterEmployees();
        if (gridRef.current) {
          alert("refresh");
          setTimeout(() => {
            gridRef.current.refresh();
          }, 100);
        }
      } else if (args.action === "edit") {
        console.log("Edit action");
        // Ensure roles are being saved correctly
        const selectedRoles = args.data.roles || []; // Get the selected roles from args.data
        const roleNames = selectedRoles
          .map((roleId) => {
            const role = roleDatasource.find((r) => r.id === roleId);
            return role ? role.idName : null; // Get the role name or null if not found
          })
          .filter(Boolean); // Filter out any null values

        // Update the employee data with role names
        args.data.roles = roleNames; // Update the roles in args.data
        console.log("Selected Roles:", args.data); // Log selected roles before saving

        await editAction(args.data); // Call the edit action with updated data
        if (orgType == "partner") await getPartnerEmployees();
        else if (orgType == "center") await getCenterEmployees();
        if (gridRef.current) {
          //alert("refreshh");
          setTimeout(() => {
            gridRef.current.refresh();
          }, 500);
        }
        // if (gridRefSearch.current) {
        //   setSearchedEmployeesData([]);
        //   setTimeout(() => {
        //     gridRefSearch.current.refresh();
        //   }, 500);
        // }
        closeModal();
      }
    } else if (args.requestType === "delete") {
      await deleteAction(args.data[0]);
    }
    if (args.requestType === "beginEdit") {
      console.log("beginEdit");
      setTimeout(() => {
        if (args.dialog) {
          document.getElementById("search-modal").style.display = "none";
          args.dialog.header = "Add Employee";
        }
      }, 0); // Delay execution to allow dialog to initialize

      if (role === "center") {
        gridRefSearch.current.columns.forEach((column) => {
          if (
            column.field === "can_declare_disaster" ||
            column.field === "can_approve_disaster" ||
            column.field === "is_representative" ||
            column.field === "is_admin"
          ) {
            column.visible = true;
          }
        });
      }
    } else if (args.requestType === "cancel" || args.requestType === "close") {
      //alert("Dialog cancelled");
      console.log("Dialog cancelled");
      closeModal();
    }
  };

  const roleDatasource = [
    { idName: "Regular Employee", id: "Regular Employee" },
    { idName: "Navigator", id: "Navigator" },
    { idName: "Navigator Supervisor", id: "Navigator Supervisor" },
  ];

  const typeParams = {
    params: {
      allowFiltering: true,
      dataSource: roleDatasource,
      fields: { text: "idName", value: "idName" },
      query: new Query(),
    },
  };

  const handleSearch = async () => {
    const firstName = document.getElementById("firstName").value.trim();
    const lastName = document.getElementById("lastName").value.trim();
    const email = document.getElementById("email").value.trim();

    // Check if all inputs are empty
    if (!firstName && !lastName && !email) {
      await getPartnerEmployees();
      if (gridRef.current) {
        gridRef.current.refresh();
      }
      return;
    }

    const whereClauses = [];
    if (firstName) {
      whereClauses.push({
        field: "first_name",
        operator: "==",
        value: firstName.charAt(0).toUpperCase() + firstName.slice(1),
      });
    }
    if (lastName) {
      whereClauses.push({
        field: "last_name",
        operator: "==",
        value: lastName.charAt(0).toUpperCase() + lastName.slice(1),
      });
    }
    if (email) {
      whereClauses.push({ field: "email", operator: "==", value: email });
    }

    // Include data where "user_role" is "citizen"
    whereClauses.push({ field: "user_role", operator: "==", value: "citizen" });

    const db = getFirestore();
    const queryRef = query(
      collection(db, "users"),
      ...whereClauses.map((whereClause) =>
        where(whereClause.field, whereClause.operator, whereClause.value)
      )
    );

    const querySnapshot = await getDocs(queryRef);
    console.log(
      "querySnapshot",
      querySnapshot.docs.map((doc) => doc.data())
    );
    const users = []; // Initialize users array

    for (const doc of querySnapshot.docs) {
      const userData = doc.data();
      const userId = doc.id; // Get the user ID from the document

      if (orgType === "partner") {
        const partnerRef = collection(db, "partners", orgId, "employees");
        const partnerQuerySnapshot = await getDocs(partnerRef);
        const partnerUsers = partnerQuerySnapshot.docs.map((doc) => doc.id);

        // Log the IDs for debugging
        console.log("Current partner users:", partnerUsers);

        // Check if the citizen's ID already exists in the employees collection
        if (partnerUsers.includes(userId)) {
          if (
            !userData.is_deleted &&
            !employeesData.some((emp) => emp.id === userId)
          ) {
            console.log(
              "Userrr already exists in partner employees:",
              userData
            );
            users.push({ ...userData, old_employee: false });
          }
        } else {
          if (!userData.is_deleted) {
            users.push({ ...userData, old_employee: false });
          }
          console.log("User does not exist in partner employees:", userId);
        }
      } else {
        const centerRef = collection(db, "centers", orgId, "employees");
        const centerQuerySnapshot = await getDocs(centerRef);
        const centerUsers = centerQuerySnapshot.docs.map((doc) => doc.id);
        if (centerUsers.includes(userId)) {
          console.log("User already exists in center employees:", userData);
        } else {
          if (!userData.is_deleted) {
            users.push({ ...userData, old_employee: false });
          }
        }
      }
    }

    // If the search returns 0 items, make the grid empty
    if (users.length === 0) {
      setSearchedEmployeesData([]);
      if (gridRefSearch.current) {
        setTimeout(() => {
          gridRefSearch.current.refresh();
        }, 400); // Delay of 100ms before refresh
      }
    } else {
      setSearchedEmployeesData(users);
      if (gridRefSearch.current) {
        setTimeout(() => {
          gridRefSearch.current.refresh();
        }, 200); // Delay of 100ms before refresh
      }
    }
  };

  const handleAddEmployee = () => {
    setIsModalOpen(true); // Open the modal
  };

  const closeModal = () => {
    setIsModalOpen(false); // Close the modal
    setSearchedEmployeesData([]);
  };

  const roleEditTemplate = (props) => {
    // Ensure that props.roles is an array of role IDs
    const currentRole = Array.isArray(props.roles) ? props.roles : []; // Ensure it's an array

    return (
      <div>
        <label htmlFor="roles" className="mb-2 block text-xs text-gray-500">
          Roles
        </label>
        <MultiSelectComponent
          id="roles"
          dataSource={roleDatasource}
          fields={{ text: "idName", value: "id" }} // Ensure value is set to "id"
          value={currentRole} // Set the value to the current roles
          mode="CheckBox"
          selectAllText="Select All"
          unSelectAllText="Unselect All"
          showSelectAll={true}
        >
          <Inject services={[CheckBoxSelection]} />
        </MultiSelectComponent>
      </div>
    );
  };

  return (
    <div
      className={`m-0 box-border flex w-[1648px] max-w-full flex-col items-start justify-start gap-2.5 px-2.5 py-[15px] leading-[normal] tracking-[normal] ${className}`}
    >
      {isModalOpen && (
        <div
          id="search-modal"
          className="fixed inset-0 z-50 flex items-center justify-center rounded-lg bg-black bg-opacity-50"
        >
          <div className="w-9/12 rounded-lg bg-white shadow-lg">
            <div className="bg-blue flex items-center justify-between rounded-tl-lg rounded-tr-lg p-4">
              <h2 className="bg-blue  text-base font-semibold">
                Search Citizen to Add as Employee
              </h2>
              <button
                class="bg-blue cursor-pointer rounded-full text-[24px] text-white"
                onClick={closeModal}
              >
                ×
              </button>
            </div>
            <div className="p-4">
              <div className="mb-2 flex w-full justify-between">
                <div className="flex w-full gap-2">
                  <TextBoxComponent
                    id="firstName"
                    placeholder="First Name"
                    cssClass="e-outline"
                    floatLabelType="Auto"
                    className="mr-2 rounded border px-2 py-1"
                    onChange={handleSearch}
                  />
                  <TextBoxComponent
                    id="lastName"
                    placeholder="Last Name"
                    cssClass="e-outline"
                    floatLabelType="Auto"
                    className="mr-2 rounded border px-2 py-1"
                    onChange={handleSearch}
                  />
                  <TextBoxComponent
                    id="email"
                    placeholder="Email"
                    cssClass="e-outline"
                    floatLabelType="Auto"
                    className="mr-2 rounded border px-2 py-1"
                    onChange={handleSearch}
                  />
                </div>
              </div>
              <GridComponent
                height="100%"
                dataSource={searchedEmployeesData}
                ref={gridRefSearch}
                cssClass="custom-grid-dialog"
                editSettings={{
                  allowEditing: true,
                  allowAdding: true,
                  allowDeleting: true,
                  showDeleteConfirmDialog: true,
                  mode: "Dialog",
                }}
                allowFiltering={true}
                filterSettings={filterSettings}
                allowSorting={true}
                sortSettings={sortSettings}
                allowPaging={true}
                pageSettings={pageSettings}
                showColumnMenu={true}
                columnMenuItems={columnMenuItems}
                actionBegin={actionBeginSearch}
              >
                <ColumnsDirective>
                  <ColumnDirective
                    field="id"
                    headerText="ID"
                    width={0}
                    visible={false}
                  />
                  <ColumnDirective
                    field="email"
                    headerText="Email"
                    width={200}
                    validationRules={{ required: true }}
                  />
                  <ColumnDirective
                    field="first_name"
                    headerText="First Name"
                    validationRules={{ required: true }}
                  />
                  <ColumnDirective
                    field="last_name"
                    headerText="Last Name"
                    validationRules={{ required: true }}
                  />
                  <ColumnDirective
                    field="roles"
                    headerText="Roles"
                    editTemplate={roleEditTemplate}
                    template={(props) => {
                      // Check if props.role is an array before calling join
                      return Array.isArray(props.roles) &&
                        props.roles.length > 0
                        ? props.roles.join(", ")
                        : "None";
                    }}
                  />
                  <ColumnDirective
                    field="default_role"
                    headerText="Default Role"
                    editType="dropdownedit"
                    edit={typeParams}
                  />

                  <ColumnDirective
                    field="can_declare_disaster"
                    headerText="Can Declare Disaster"
                    template={(props) =>
                      props.can_declare_disaster ? "Yes" : "No"
                    }
                    editType="booleanedit"
                    visible={role === "center"}
                  />
                  <ColumnDirective
                    field="can_approve_disaster"
                    headerText="Can Approve Disaster"
                    template={(props) =>
                      props.can_approve_disaster ? "Yes" : "No"
                    }
                    editType="booleanedit"
                    visible={role === "center"}
                  />

                  <ColumnDirective
                    field="is_representative"
                    headerText="Representative"
                    template={(props) =>
                      props.is_representative ? "Yes" : "No"
                    }
                    editType="booleanedit"
                  />
                  <ColumnDirective
                    field="is_admin"
                    headerText="Admin"
                    template={(props) => (props.is_admin ? "Yes" : "No")}
                    editType="booleanedit"
                  />
                  <ColumnDirective
                    headerText="Actions"
                    width={120}
                    commands={[
                      {
                        type: "Edit",
                        buttonOption: {
                          content: `<i class="fas fa-plus"></i>`,
                          cssClass: "e-outline custom-button",
                        },
                      },
                    ]}
                  />
                </ColumnsDirective>

                <Inject services={[Page, Sort, Filter, Edit, ColumnMenu]} />
              </GridComponent>
              <div className="flex justify-end">
                <button
                  onClick={closeModal}
                  className="btn-default mt-4 rounded bg-blue-500 p-4 px-4 py-2 text-white hover:cursor-pointer hover:bg-blue-600"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div id="dialog-container"></div>
      <div className="flex flex-row items-start justify-start gap-1.5 py-0 pl-0 pr-5">
        <a className="relative inline-block min-w-[100px] text-left  text-xs font-medium leading-[16px] text-dodgerblue [text-decoration:none]">
          {orgType === "partner" ? "Partner" : "Center"}{" "}
          <span className="text-gray-400"> / </span> Employees
        </a>
      </div>
      <div className="flex max-w-full flex-col items-start justify-start self-stretch bg-white">
        <div className="mb-2 flex w-full justify-end">
          <button
            type="button"
            className="btn-default my-auto flex cursor-pointer gap-1 px-3 py-1.5"
            onClick={handleAddEmployee}
          >
            Add Employee
          </button>
        </div>
        <GridComponent
          dataSource={employeesData}
          editSettings={{
            allowEditing: true,
            allowAdding: true,
            allowDeleting: true,
            showDeleteConfirmDialog: true,
            mode: "Dialog",
          }}
          ref={gridRef}
          cssClass="custom-grid-dialog"
          actionBegin={actionBegin}
          allowFiltering={true}
          filterSettings={filterSettings}
          allowSorting={true}
          sortSettings={sortSettings}
          allowPaging={true}
          pageSettings={pageSettings}
          showColumnMenu={true}
          columnMenuItems={columnMenuItems}
        >
          <ColumnsDirective>
            <ColumnDirective
              field="id"
              headerText="ID"
              width={0}
              visible={false}
            />
            <ColumnDirective
              field="email"
              headerText="Email"
              width={250}
              validationRules={{ required: true }}
              onValueChange={async (args) => {
                console.log("Email value on change:", args.value); // Added logging
              }}
            />
            <ColumnDirective
              field="first_name"
              headerText="First Name"
              validationRules={{ required: true }}
            />
            <ColumnDirective
              field="last_name"
              headerText="Last Name"
              validationRules={{ required: true }}
            />

            <ColumnDirective
              field="roles"
              headerText="Roles"
              editTemplate={roleEditTemplate}
              template={(props) => {
                // Check if props.role is an array before calling join
                return Array.isArray(props.roles) && props.roles.length > 0
                  ? props.roles.join(", ")
                  : "None";
              }}
            />
            <ColumnDirective
              field="default_role"
              headerText="Default Role"
              editType="dropdownedit"
              edit={typeParams}
            />

            <ColumnDirective
              field="can_declare_disaster"
              headerText="Can Declare Disaster"
              template={(props) => (props.can_declare_disaster ? "Yes" : "No")}
              editType="booleanedit"
              visible={role === "center"}
            />
            <ColumnDirective
              field="can_approve_disaster"
              headerText="Can Approve Disaster"
              template={(props) => (props.can_approve_disaster ? "Yes" : "No")}
              editType="booleanedit"
              visible={role === "center"}
            />

            <ColumnDirective
              field="is_representative"
              headerText="Representative"
              template={(props) => (props.is_representative ? "Yes" : "No")}
              editType="booleanedit"
            />
            <ColumnDirective
              field="is_admin"
              headerText="Admin"
              template={(props) => (props.is_admin ? "Yes" : "No")}
              editType="booleanedit"
              width={100}
            />

            <ColumnDirective
              headerText="Actions"
              width={100}
              commands={[
                {
                  type: "Edit",
                  buttonOption: {
                    content: `<i class="fas fa-edit"></i>`,
                    cssClass: "e-outline custom-button",
                  },
                },
                {
                  type: "Delete",
                  buttonOption: {
                    content: '<i class="fas fa-trash-alt"></i>',
                    cssClass: "e-outline custom-button",
                  },
                },
              ]}
            />
          </ColumnsDirective>
          <Inject
            services={[Page, Sort, Filter, Edit, CommandColumn, ColumnMenu]}
          />
        </GridComponent>
      </div>
    </div>
  );
};

export default Employees;
