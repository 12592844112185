import React, { useState, useEffect } from "react";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import {
  getAuth,
  onAuthStateChanged,
  EmailAuthProvider,
  reauthenticateWithCredential,
} from "firebase/auth";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { useAuth } from "../../components/AuthContext";

const ShowPin = ({ isOpen, onClose }) => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPin, setNewPin] = useState("");
  const [confirmPin, setConfirmPin] = useState("");
  const [showPins, setShowPins] = useState({
    current: false,
    new: false,
    confirm: false,
  });
  const [error, setError] = useState("");
  const [userId, setUserId] = useState(null);
  const [hasCurrentPin, setHasCurrentPin] = useState(false);
  const { role } = useAuth();
  const [docId, setDocId] = useState(null);
  const [pinShown, setPinShown] = useState(false);
  const [displayPin, setDisplayPin] = useState("");
  const [isPasswordCorrect, setIsPasswordCorrect] = useState(false); // Added to track if the password is correct
  const [showChangePin, setShowChangePin] = useState(false); // Added to track if the change PIN form is shown

  const [showChangePinFields, setShowChangePinFields] = useState(false); // Added to track if the change PIN fields are shown

  const [showPinButton, setShowPinButton] = useState(true); // Added to track if the "Show Lockbox PIN" button is shown
  const [saveChangeClicked, setSaveChangeClicked] = useState(false); // Added to track if the "Save Changes" button is clicked

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userDocc = doc(db, "users", user.uid);
        const userDocSnap = await getDoc(userDocc);
        console.log("role", role);

        setUserId(user.uid);
        // Check if the user has a PIN set
        let docId = user.uid;
        let userDocRef;

        if (role === "partner") {
          docId = userDocSnap.data().partner_id;
          setDocId(docId);
          userDocRef = doc(db, "partners", docId);
        } else {
          setDocId(docId);
          userDocRef = doc(db, "users", docId);
        }
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          setNewPin(userDoc.data().pin);
          setDisplayPin(userDoc.data().pin);
          if (role === "partner") {
            let docId = user.uid;
            let userDocRef;
            userDocRef = doc(db, "users", docId);
            const userDoc = await getDoc(userDocRef);

            setCurrentPassword(userDoc.data().password);
          } else {
            setCurrentPassword(userDoc.data().password);
          }
          setHasCurrentPin(true);
        } else {
          setHasCurrentPin(false);
        }
      }
    });
    return () => unsubscribe();
  }, [role]);

  if (!isOpen) return null;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    if (showChangePinFields) {
      setShowChangePin(true);
    } else {
      // setShowChangePin(true);
    }

    if (showChangePin) {
      // PIN change logic
      if (newPin !== confirmPin) {
        setError("New PIN and confirm PIN don't match.");
        return;
      }

      if (newPin.length !== 4 || !/^\d+$/.test(newPin)) {
        setError("PIN must be 4 digits.");
        return;
      }
    }

    try {
      const auth = getAuth();
      const user = auth.currentUser;

      if (!user) {
        setError("User not logged in.");
        return;
      }

      if (!showChangePin && !saveChangeClicked) {
        // Reauthenticate the user
        try {
          const credential = EmailAuthProvider.credential(
            user.email,
            currentPassword
          );
          await reauthenticateWithCredential(user, credential);
          if (saveChangeClicked) {
            onClose();
          }
        } catch (error) {
          console.error("Error reauthenticating:", error);
          setError("Incorrect password. Please try again.");
          return;
        }
      }

      if (showChangePin && saveChangeClicked) {
        // Update PIN in database
        const collectionName = role === "partner" ? "partners" : "users";
        const userDocRef = doc(db, collectionName, docId);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          await updateDoc(userDocRef, { pin: newPin });
          console.log("PIN updated successfully");

          handlePinChangeClose();
          onClose(); // Close the modal after saving changes
        } else {
          setError("User data not found.");
        }
      } else {
        // Show PIN logic
        setPinShown(true);
        //setDisplayPin(newPin);
        setShowPins({
          current: false,
          new: false,
          confirm: false,
        });
        setShowPinButton(false); // Hide the "Show Lockbox PIN" button
        setShowChangePinFields(true); // Show the change PIN fields
        setNewPin(""); // Clear new PIN
        setConfirmPin(""); // Clear confirm PIN
      }
    } catch (error) {
      console.error("Error:", error);
      setError("An error occurred. Please try again.");
    }
  };

  const togglePinVisibility = (field) => {
    setShowPins((prev) => ({ ...prev, [field]: !prev[field] }));
  };

  const handleChangePinClick = () => {
    setPinShown(false);
    setShowChangePin(true);
    setShowPinButton(false); // Hide the "Show Lockbox PIN" button
    setShowChangePinFields(true); // Show the change PIN fields
  };

  const handlePinChangeClose = () => {
    setShowChangePin(false);
    setPinShown(false);
    setIsPasswordCorrect(false);
    setError("");
    // setCurrentPassword("");
    // setDisplayPin("");
    setShowPinButton(true); // Show the "Show Lockbox PIN" button
    setShowChangePinFields(false); // Hide the change PIN fields
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center overflow-auto bg-black bg-opacity-50">
      <div className="w-[480px] rounded-lg bg-white shadow-xl">
        <div className="bg-blue flex items-center justify-between rounded-t-lg p-4">
          <h2 className="text-[24px] font-semibold">
            {showChangePin ? "Change Lockbox PIN" : "Show PIN"}
          </h2>
          <button
            onClick={onClose}
            className="bg-blue cursor-pointer rounded-full text-[24px] text-white"
          >
            &times;
          </button>
        </div>
        {showChangePin ? (
          <form onSubmit={handleSubmit} className="p-4">
            {error && <div className="mb-4 text-red-500">{error}</div>}
            {!showChangePinFields && (
              <div className="mb-4 text-red-500">
                <strong>Note:</strong> Please ensure you remember your new PIN.
              </div>
            )}

            <div className="relative mb-4">
              {!showChangePinFields && (
                <>
                  <TextBoxComponent
                    placeholder="Password"
                    type={showPins.current ? "text" : "password"}
                    cssClass="e-outline"
                    floatLabelType="Auto"
                  />
                  <button
                    type="button"
                    onClick={() => togglePinVisibility("current")}
                    className="absolute right-3 top-1/2 -translate-y-1/2 transform cursor-pointer text-blue-500"
                  >
                    {showPins.current ? <FaEyeSlash /> : <FaEye />}
                  </button>
                </>
              )}
            </div>

            {pinShown && (
              <div className="mb-4">
                <h2 className="mb-4 text-[24px] font-semibold text-gray-800">
                  Your lockbox PIN is: <strong>{displayPin}</strong>
                </h2>
                {showChangePinFields && (
                  <>
                    <div className="relative mb-4">
                      <TextBoxComponent
                        placeholder="New PIN"
                        type={showPins.new ? "text" : "password"}
                        value={newPin}
                        onChange={(e) => setNewPin(e.target.value)}
                        cssClass="e-outline"
                        floatLabelType="Auto"
                        maxLength={4}
                        onPaste={(e) => e.preventDefault()}
                      />
                      <button
                        type="button"
                        onClick={() => togglePinVisibility("new")}
                        className="absolute right-3 top-1/2 -translate-y-1/2 transform cursor-pointer text-blue-500"
                      >
                        {showPins.new ? <FaEyeSlash /> : <FaEye />}
                      </button>
                    </div>

                    <div className="relative mb-6">
                      <TextBoxComponent
                        placeholder="Confirm New PIN"
                        type={showPins.confirm ? "text" : "password"}
                        value={confirmPin}
                        onChange={(e) => setConfirmPin(e.target.value)}
                        cssClass="e-outline"
                        floatLabelType="Auto"
                        maxLength={4}
                        onPaste={(e) => e.preventDefault()}
                      />
                      <button
                        type="button"
                        onClick={() => togglePinVisibility("confirm")}
                        className="absolute right-3 top-1/2 -translate-y-1/2 transform cursor-pointer text-blue-500"
                      >
                        {showPins.confirm ? <FaEyeSlash /> : <FaEye />}
                      </button>
                    </div>
                  </>
                )}
              </div>
            )}

            <div className="flex items-center justify-end space-x-4">
              <button
                type="button"
                onClick={handlePinChangeClose}
                className="btn-cancel cursor-pointer rounded-full border px-4 py-2 transition duration-300 hover:bg-blue-50"
              >
                CANCEL
              </button>
              <button
                type="submit"
                className="cursor-pointer rounded-full bg-blue-500 px-4 py-2 text-white transition duration-300 hover:bg-blue-600"
                onClick={() => {
                  // setShowChangePinFields(false);
                  setSaveChangeClicked(true);
                }}
              >
                SAVE CHANGES
              </button>
            </div>
          </form>
        ) : (
          <form onSubmit={handleSubmit} className="p-4">
            {error && <div className="mb-4 text-red-500">{error}</div>}

            <div className="relative mb-4">
              <TextBoxComponent
                placeholder="Password"
                type={showPins.current ? "text" : "password"}
                onChange={async (e) => {
                  if (e.value !== "" && e.value === currentPassword) {
                    const auth = getAuth();
                    const user = auth.currentUser;
                    try {
                      const credential = EmailAuthProvider.credential(
                        user.email,
                        e.value
                      );
                      await reauthenticateWithCredential(user, credential);
                      setIsPasswordCorrect(true);
                      setError("");
                      console.log("isPasswordCorrect");
                    } catch (error) {
                      console.error("Error reauthenticating:", error);
                      setError("Incorrect password. Please try again.");
                      setIsPasswordCorrect(false);
                    }
                  } else {
                    console.log("Incorrect password, ", currentPassword);
                    setIsPasswordCorrect(false);
                  }
                }}
                cssClass="e-outline"
                floatLabelType="Auto"
              />
              <button
                type="button"
                onClick={() => togglePinVisibility("current")}
                className="absolute right-3 top-1/2 -translate-y-1/2 transform cursor-pointer text-blue-500"
              >
                {showPins.current ? <FaEyeSlash /> : <FaEye />}
              </button>
            </div>

            {pinShown && (
              <div className="mb-4">
                <h2 className="text-[24px] font-semibold text-gray-800">
                  Your lockbox PIN is: <strong>{displayPin}</strong>
                </h2>
              </div>
            )}

            <div className="flex items-center justify-end space-x-4">
              <button
                type="button"
                onClick={onClose}
                className="btn-cancel cursor-pointer rounded-full px-4 py-2 transition duration-300 hover:bg-blue-50"
              >
                CANCEL
              </button>
              {showPinButton ? (
                <button
                  type="submit"
                  disabled={!isPasswordCorrect}
                  className={`cursor-pointer rounded-full ${
                    isPasswordCorrect ? "btn-default" : "btn-gray"
                  } px-4 py-2 text-white transition duration-300 ${
                    isPasswordCorrect
                      ? "hover:bg-blue-600"
                      : "cursor-not-allowed"
                  }`}
                >
                  SHOW LOCKBOX PIN
                </button>
              ) : (
                <button
                  type="submit"
                  className="cursor-pointer rounded-full bg-blue-500 px-4 py-2 text-white transition duration-300 hover:bg-blue-600"
                  onClick={() => {
                    setShowChangePinFields(true);
                  }}
                >
                  Change PIN
                </button>
              )}
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default ShowPin;
