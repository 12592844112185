import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import blueSkyLogo from "../../src/assets/bluesky-logo.svg";
import userService from "../service/UserService";
import { auth } from "../firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useAuth } from "../components/AuthContext";
import mailer from "../service/api/mailer";
import partnerProfileService from "../service/partner/PartnerProfileService";
import CenterService from "../service/center/CenterService";

const AccessAccount = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [step, setStep] = useState(1);
  const [reason, setReason] = useState("");
  const [employeeId, setEmployeeId] = useState(""); // Example value for Employee ID
  const [accessCode, setAccessCode] = useState(""); // Example value for Access Code
  const [masterAccessCode, setMasterAccessCode] = useState("");
  const [masterCode, setMasterCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [user, setUser] = useState({});
  const [employee, setEmployee] = useState("");
  const [orgType, setOrgType] = useState("");
  const [organization, setOrganization] = useState({});

  const { login, setRoleBasedRoute } = useAuth();
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleNext = async () => {
    if (reason.trim() === "") {
      setErrorMessage("Reason for Access is required.");
    } else {
      // Save to user
      const currentDate = new Date();
      const nextDay = new Date(currentDate);
      nextDay.setDate(nextDay.getDate() + 1);
      const accessAccount = {
        access_code: accessCode,
        master_access_code: masterAccessCode,
        employee_id: employeeId,
        employee_user_id: employee.id,
        reason_for_access: reason,
        org_type: orgType,
        created_date: currentDate,
        expiration_date: nextDay,
      };
      await userService.updateUser(user.id, { access_acount: accessAccount });

      // Email user
      const empName = employee.first_name + " " + employee.last_name;
      switch (orgType) {
        case "partner":
          await mailer.sendAccessEmail(
            user.email,
            user.first_name,
            masterAccessCode,
            accessCode,
            organization.name_of_org,
            empName,
            employeeId,
            employee.mobile_number,
            reason
          );
          break;
        case "center":
          await mailer.sendAccessEmail(
            user.email,
            user.first_name,
            masterAccessCode,
            accessCode,
            organization.name_of_hub,
            empName,
            employeeId,
            employee.mobile_number,
            reason
          );
          break;
        default:
          console.error("No organization type.");
      }
      
      setErrorMessage("");
      setStep(2);
    }
  };

  const handleContinue = async () => {
    if (masterCode !== masterAccessCode) {
      setErrorMessage("Incorrect Master Verification Code");
      alert("Incorrect Master Verification Code");
    } else {
      setErrorMessage("");
      const creds = await signInWithEmailAndPassword(
        auth,
        user.email,
        user.password
      );
      setRoleBasedRoute("citizen");
      login(creds.user.accessToken);
      navigate("/home");
    }
  };

  const generateOtp = () => {
    return Math.floor(100000 + Math.random() * 900000).toString();
  };

  const generateEmpId = () => {
    return Math.floor(100000 + Math.random() * 90000000).toString();
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const emailTmp = localStorage.getItem("accessAccountEmail");
        const orgUserIdTmp = localStorage.getItem("orgUserId");
        const orgTypeTmp = localStorage.getItem("orgType");
        setOrgType(orgTypeTmp);

        if (emailTmp) {
          const tmpUser1 = await userService.getUserByEmail(emailTmp);
          setUser(tmpUser1);
          
          const tmpUser2 = await userService.getUserById(orgUserIdTmp);
          setEmployee(tmpUser2);
          
          const accessOtp = generateOtp();
          setAccessCode(accessOtp);

          const masterOtp = generateOtp();
          setMasterAccessCode(masterOtp);

          if (tmpUser2.employee_id) {
            setEmployeeId(tmpUser2.employee_id);
          } else {
            const empId = generateEmpId();
            await userService.updateUser(tmpUser2.id, { employee_id: empId });
            setEmployeeId(empId);
          }

          switch (orgTypeTmp) {
            case "partner":
              const org = await partnerProfileService.getPartnerById(
                tmpUser2.partner_id
              );
              setOrganization(org);
              break;
            case "center":
              const center = await CenterService.getCenterById(tmpUser2.center_id)
              setOrganization(center);
              break;
            default:
              console.error("No organization type.");
          }
        } else {
          console.error("User not found.");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, []);

  const token = sessionStorage.getItem("accessToken");
  if (token) {
    sessionStorage.removeItem("accessToken");
    window.location.reload();
  }

  return (
    <div className="flex min-h-screen w-full items-center justify-center overflow-y-auto bg-zinc-50 px-8 !font-[Inter]">
      <div className="login-form relative flex h-auto w-[750px] justify-center overflow-hidden rounded-xl bg-white py-5 shadow-[0px_4px_9px_rgba(0,0,0,0.25)]">
        <div className="flex w-full flex-col items-center justify-center px-11 py-5 md:px-0">
          <header className="text-3xl text-gray-700 z-0 flex w-[483px] max-w-full flex-col items-center justify-center whitespace-nowrap bg-white px-2 py-1.5 text-center font-bold leading-none tracking-wide">
            <img
              loading="lazy"
              src={blueSkyLogo}
              className="aspect-[0.99] w-[90px] object-contain"
              alt="DARCI logo"
            />
            <label className="login-logo-label mt-2.5">DARCI</label>
          </header>
          <div className="z-0 mt-2.5 flex w-[483px] max-w-full flex-col items-center justify-center">
            <div className="text-gray-700 flex flex-col items-center text-center font-bold tracking-wide">
              <div className="text-xl leading-none">
                <span className="text-base">Powered by </span>
                <span className="text-base text-blue-500">BlueSky</span>
              </div>
            </div>
          </div>

          <div className="z-0 mt-2.5 flex w-[600px] max-w-full flex-col items-center justify-center">
            <div className="flex w-full flex-col gap-[5px] pt-3">
              {step === 1 && (
                <>
                  <p className="my-[20px] text-center text-[20px] font-bold text-[#3D435A]">
                    Reason for Access
                  </p>
                  <input
                    placeholder="Reason for Access"
                    type="text"
                    className="h-[40px] w-full border border-[#D5D7DB] p-[10px] pr-[40px] font-[Inter] focus:outline-none"
                    name="reason"
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                    required
                  />
                  {errorMessage && (
                    <p className="mt-2 text-sm text-red-500">{errorMessage}</p>
                  )}
                  <div className="flex w-full gap-2">
                    <button
                      onClick={() => window.close()}
                      className="mt-4 h-10 w-full btn-cancel py-[10px] font-[Inter] hover:cursor-pointer"
                    >
                      Back
                    </button>
                    <button
                      className="mt-4 h-10 w-full btn-default py-[10px] hover:cursor-pointer"
                      onClick={handleNext}
                    >
                      Next
                    </button>
                  </div>
                </>
              )}
              {step === 2 && (
                <>
                  <table className="mt-1 w-full text-left">
                    <tbody>
                      <tr>
                        <td className="text-gray-700 py-2 text-left font-medium w-40">
                          Reason for Access :
                        </td>
                        <td className="text-gray-600 py-2 text-left">
                          {reason}
                        </td>
                      </tr>
                      <tr>
                        <td className="text-gray-700 text-left py-2 font-medium w-40">
                          Employee ID :
                        </td>
                        <td className="text-gray-600 py-2 text-left">
                          {employeeId}
                        </td>
                      </tr>
                      <tr>
                        <td className="text-gray-700 text-left py-2 font-medium w-40">
                          Access Code :
                        </td>
                        <td className="text-gray-600 py-2 text-left">
                          {accessCode}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="relative mt-3">
                    <input
                      placeholder="Master Verification Code"
                      type={showPassword ? "text" : "password"}
                      className="h-[40px] w-full border border-[#D5D7DB] p-[10px] pr-[40px] font-[Inter] focus:outline-none"
                      name="master"
                      value={masterCode}
                      onChange={(e) => setMasterCode(e.target.value)}
                      required
                    />
                    <button
                      type="button"
                      onClick={togglePasswordVisibility}
                      className="absolute right-3 top-1/2 -translate-y-1/2 transform cursor-pointer text-blue-500"
                    >
                      {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </button>
                  </div>
                  {errorMessage && (
                    <p className="mt-2 text-sm text-red-500">{errorMessage}</p>
                  )}
                  <div className="flex w-full gap-2">
                    <button
                      onClick={() => window.close()}
                      className="mt-4 h-10 w-full rounded-[4px] border-2 border-[#348BFF] bg-white py-[10px] font-[Inter] text-base font-bold text-[#348BFF] hover:cursor-pointer"
                    >
                      Cancel
                    </button>
                    <button
                      className="mt-4 h-10 w-full rounded-[4px] bg-[#348BFF] py-[10px] font-[Inter] text-base font-bold text-white hover:cursor-pointer"
                      onClick={handleContinue}
                    >
                      Continue
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccessAccount;
