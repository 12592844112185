import React, { useState, useEffect, useRef } from "react";
import { FaPlus } from "react-icons/fa";
import { registerLicense } from "@syncfusion/ej2-base";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Inject,
  Edit,
  Toolbar,
  Filter,
  Sort,
  Page,
  CommandColumn,
  Search,
  ColumnMenu,
} from "@syncfusion/ej2-react-grids";
import { USStates } from "../datasource";
import { Query } from "@syncfusion/ej2-data";
import { db, auth } from "../../firebase"; // Import Firebase config
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  serverTimestamp,
  query,
  where,
  setDoc,
  getDoc,
} from "firebase/firestore";
import { onAuthStateChanged, signInWithEmailAndPassword } from "firebase/auth";
import { createUserWithEmailAndPassword } from "firebase/auth";
import "../style.css";
import dataService from "../../service/data/DataService";
import {
  CheckBoxSelection,
  MultiSelectComponent,
} from "@syncfusion/ej2-react-dropdowns";

// Syncfusion license key
registerLicense(
  "Ngo9BigBOggjHTQxAR8/V1NBaF5cXmZCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWXxceXRcQmZdV0R/XUM="
);

const sortSettings = {
  allowSorting: true,
};
const filterSettings = {
  type: "CheckBox",
};
const columnMenuItems = ["SortAscending", "SortDescending", "Filter"];

const pageSettings = { pageSize: 10 };

const validationRules = { required: true };

const preprocessData = (data) => {
  return data.map((item) => ({
    ...item,
    Name: `${item.first_name} ${item.last_name}`,
    Address: `${item.address1} ${item.address2} ${item.city} ${item.state} ${item.zip}`,
  }));
};

const formatLastEditedTime = (time) => {
  if (!time) return "Never";

  const now = new Date();
  const diffInSeconds = Math.floor((now - time) / 1000);

  if (diffInSeconds < 60) return "Just now";
  if (diffInSeconds < 3600)
    return `${Math.floor(diffInSeconds / 60)} minutes ago`;
  if (diffInSeconds < 86400)
    return `${Math.floor(diffInSeconds / 3600)} hours ago`;
  if (diffInSeconds < 604800)
    return `${Math.floor(diffInSeconds / 86400)} days ago`;

  return time.toLocaleDateString();
};

const generateRandomPassword = (length = 8) => {
  const charset =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()";
  let password = "";
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charset.length);
    password += charset[randomIndex];
  }
  return password;
};

const servicesProvided = [
  { value: "Funding", text: "Funding" },
  { value: "Debris Removal", text: "Debris Removal" },
  { value: "Case Management", text: "Case Management" },
  { value: "House Repair", text: "House Repair" },
  { value: "House Rebuilds", text: "House Rebuilds" },
  { value: "Volunteer Labor", text: "Volunteer Labor" },
  { value: "Spriritual/Mental Care", text: "Spriritual/Mental Care" },
  { value: "Donation of Goods", text: "Donation of Goods" },
];

const fields = { value: "value", text: "text" };

const Services = (props) => {
  const currentServices = props.servicesProvided || [];

  return (
    <div>
      <label
        htmlFor="servicesProvided"
        className="mb-2 block text-xs text-gray-500"
      >
        Primary Service Provided
      </label>
      <MultiSelectComponent
        id="servicesProvided"
        dataSource={servicesProvided}
        fields={fields}
        value={currentServices}
        mode="CheckBox"
        selectAllText="Select All"
        unSelectAllText="Unselect All"
        showSelectAll={true}
        change={(e) => {
          if (props.onChange) {
            props.onChange({ value: e.value });
          }
        }}
      >
        <Inject services={[CheckBoxSelection]} />
      </MultiSelectComponent>
    </div>
  );
};
const LocalNonProfitSearch = ({ className = "" }) => {
  const [centerLocalNPC, setCenterLocalNPC] = useState([]);
  const gridRef = useRef(null);
  const [lastEditedTime, setLastEditedTime] = useState(null);
  const [userId, setUserId] = useState(null);
  const [currentUserData, setCurrentUserData] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUserId(user.uid);
        console.log("User ID:", user.uid); // Check if user ID is retrieved

        const userData = await dataService.getUserProfile("users", user.uid);
        if (userData) {
          setCurrentUserData(userData);
          console.log("Data: ", userData);
          try {
            const userQuerySnapshot = await getDocs(
              query(
                collection(db, "users"),
                where("center_id", "array-contains", userData.center_id),
                where("user_role", "==", "partner")
              )
            );
            const drpPartnerData = userQuerySnapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
            console.log("DRP Partner Data:", drpPartnerData);

            const partnerIds = drpPartnerData.map(
              (partner) => partner.partner_id
            );
            const partnerData = await Promise.all(
              partnerIds.map(async (partnerId) => {
                const partnerDocRef = doc(db, "partners", partnerId);
                const partnerDocSnap = await getDoc(partnerDocRef);
                if (partnerDocSnap.exists()) {
                  const partnerData = partnerDocSnap.data();
                  if (partnerData.type === "local-non-profit") {
                    return { id: partnerDocSnap.id, ...partnerData };
                  } else {
                    console.log(
                      `Partner with id: ${partnerId} is not a local non-profit.`
                    );
                    return null;
                  }
                } else {
                  console.error(
                    `No partner document found with id: ${partnerId}`
                  );
                  return null;
                }
              })
            );

            console.log("Fetched partner data:", partnerData.filter(Boolean));
            setCenterLocalNPC(preprocessData(partnerData.filter(Boolean)));
            return preprocessData(partnerData.filter(Boolean));
          } catch (error) {
            console.error("Error fetching contacts:", error);
          }
        }
      } else {
        setUserId(null);
        setCenterLocalNPC([]); // Clear contacts if no user is logged in
      }
    });

    return () => unsubscribe(); // Clean up subscription
  }, [userId]); // Add userId as a dependency

  return (
    <>
       <div
          className={`m-0 box-border flex w-[1648px] max-w-full flex-col items-start justify-start gap-2.5 px-2.5 py-[15px] leading-[normal] tracking-[normal] ${className}`}
       >
          <div id="dialog-container"></div>
            <div className="flex flex-row items-start justify-start gap-1.5  text-xs text-darkslateblue">
              <a className="relative inline-block min-w-[40px] text-left  text-xs font-medium leading-[16px] text-dodgerblue [text-decoration:none]">
                Database
              </a>
              <div className="relative inline-block min-w-[5px] text-left font-mulish text-base font-semibold leading-[16px] text-gray-400">
                /
              </div>
              <a className="relative inline-block min-w-[77px] text-left  text-xs font-medium leading-[16px] text-dodgerblue [text-decoration:none]">
                Local Non-profit Coalition Search
              </a>
          </div>
          <div className="flex max-w-full flex-col items-start justify-start self-stretch bg-white">
              <div className="relative mb-2 inline-block max-w-full font-[Inter] font-medium leading-[21px] text-darkslategray-200">
                   <p>
                      The Local Nonprofit Coalition is a collaborative network of local
                      organizations working together to enhance disaster preparedness,
                      response, and recovery efforts in your community.
                   </p>
              </div>
          </div>
          <section className="flex max-w-full flex-col items-start justify-start self-stretch bg-white pb-4">
              <header className="mb-2 flex flex-row items-center justify-between self-stretch">
                  <div>
                    <h2 className="mb-1 text-sm font-bold text-blue-500">
                      LOCAL NON-PROFIT COALITION
                    </h2>
                  </div>
              </header>
              <div>
                <GridComponent
                  dataSource={centerLocalNPC.filter(
                    (contact) => contact.is_deleted !== true
                  )}
                  filterSettings={filterSettings}
                  allowFiltering={true}
                  allowSorting={true}
                  ref={gridRef}
                  toolbar={["Search"]}
                  sortSettings={sortSettings}
                  allowPaging={true}
                  pageSettings={pageSettings}
                  showColumnMenu={true}
                  columnMenuItems={columnMenuItems}
                >
                  <ColumnsDirective>
                    <ColumnDirective
                      field="county"
                      headerText="Primary County"
                      visible={true}
                      validationRules={validationRules}
                    />
                    <ColumnDirective
                      field="name_of_org"
                      headerText="Organization"
                      visible={true}
                      validationRules={validationRules}
                    />
                    <ColumnDirective
                      field="servicesProvided"
                      headerText="Primary Service Provided"
                      editTemplate={Services}
                      validationRules={validationRules}
                    />
                    <ColumnDirective field="Address" headerText="Address" />
                    <ColumnDirective
                      field="address1"
                      headerText="Address 1"
                      visible={false}
                      validationRules={validationRules}
                    />
                    <ColumnDirective
                      field="address2"
                      headerText="Address 2"
                      visible={false}
                      validationRules={validationRules}
                    />
                    <ColumnDirective
                      field="city"
                      headerText="City"
                      visible={false}
                      validationRules={validationRules}
                    />
                    <ColumnDirective
                      field="state"
                      headerText="State"
                      visible={false}
                      editType="dropdownedit"
                      edit={{
                        params: {
                          dataSource: USStates,
                          fields: { text: "abbreviation", value: "abbreviation" },
                          query: new Query(),
                        },
                      }}
                      validationRules={validationRules}
                    />
                    <ColumnDirective
                      field="zip"
                      headerText="Zip"
                      visible={false}
                      validationRules={validationRules}
                    />
                    <ColumnDirective
                      field="mobile_number"
                      headerText="Mobile Number"
                      validationRules={validationRules}
                    />
                    <ColumnDirective
                      field="email"
                      headerText="Email"
                      validationRules={validationRules}
                    />
                  </ColumnsDirective>
                  <Inject
                    services={[
                      Edit,
                      Filter,
                      Sort,
                      Page,
                      CommandColumn,
                      Search,
                      ColumnMenu,
                    ]}
                  />
                </GridComponent>
              </div>
          </section>
       </div>
    </>
  );
};

export default LocalNonProfitSearch;
