import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { auth, db } from "../firebase";
import {
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import {
  doc,
  setDoc,
  getDocs,
  collection,
  query,
  where,
  updateDoc,
  getDoc,
} from "firebase/firestore";
import { useAuth } from "../components/AuthContext";
import Login1 from "./Login1";
import Login2 from "./Login2";
import { useSearchParams } from "react-router-dom";
import { useGeneralContext } from "../context/GeneralContext";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLogin1, setIsLogin1] = useState(true);
  const navigate = useNavigate();
  const { login, setRoleBasedRoute, role } = useAuth();
  const [popupMessage, setPopupMessage] = useState("");
  const [searchParams] = useSearchParams();
  const verificationToken = searchParams.get("toklen");
  const { dupAddressData, clearDupAddress, setDupAddress, setEmailfunc } =
    useGeneralContext();
  const isDupAddress = searchParams.get("dupAddress") === "true";
  const encodedData = searchParams.get("data");
  const [attempts, setAttempts] = useState(3);
  const [dupLogin, setDupLogin] = useState(false);

  const getUserByEmail = async (email) => {
    try {
      const q = query(collection(db, "users"), where("email", "==", email));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        console.log("No matching documents.");
        return null;
      }

      const userData = [];
      querySnapshot.forEach((doc) => {
        userData.push({ id: doc.id, ...doc.data() });
      });

      return userData.length > 0 ? userData[0] : null;
    } catch (error) {
      console.error("Error getting user by email:", error);
      return null;
    }
  };

  useEffect(() => {
    if (isDupAddress && encodedData && !dupAddressData) {
      try {
        const decodedData = JSON.parse(atob(encodedData));
        console.log("Setting dupAddress data from URL:", decodedData);
        setDupAddress(decodedData);
      } catch (error) {
        console.error("Error decoding dupAddress data:", error);
      }
    }
  }, [isDupAddress, encodedData, dupAddressData, setDupAddress]);

  const handleDupAddressLogin = async () => {
    console.log("handleDupAddressLogin - Current state:", {
      isDupAddress,
      dupAddressData,
      email,
    });

    if (isDupAddress && dupAddressData && email) {
      try {
        console.log(
          "Processing duplicate address login with data:",
          dupAddressData
        );

        if (dupAddressData.original_email !== email) {
          console.log("User is not authorized to verify this address");
          setPopupMessage("You are not authorized to verify this address");
          return;
        }

        // Update original user
        const userQuery = query(
          collection(db, "users"),
          where("email", "==", email)
        );
        const userSnapshot = await getDocs(userQuery);

        if (!userSnapshot.empty) {
          const userDoc = userSnapshot.docs[0];
          await updateDoc(userDoc.ref, {
            dup_address: true,
            dup_address_owner: true,
            dup_address_token: dupAddressData.token,
          });
          console.log("Updated original user:", email);
          setDupLogin(true);
        }

        // Update fraud user if they exist
        const fraudUserQuery = query(
          collection(db, "users"),
          where("email", "==", dupAddressData.fraud_email)
        );

        const fraudUserSnapshot = await getDocs(fraudUserQuery);
        if (!fraudUserSnapshot.empty) {
          const fraudUserDoc = fraudUserSnapshot.docs[0];
          await updateDoc(fraudUserDoc.ref, {
            disabled: true,
            status: "inactive",
            dup_address: true,
            dup_address_owner: false,
            dup_address_token: dupAddressData.token,
            dup_address_otp: dupAddressData.otp,
          });
          console.log("Updated fraud user:", dupAddressData.fraud_email);
        }

        // Clear the context data after processing
        clearDupAddress();
      } catch (error) {
        console.error("Error in handleDupAddressLogin:", error);
        setPopupMessage("An error occurred during address verification");
      }
    } else {
      console.log("Missing required data:", {
        isDupAddress,
        hasDupAddressData: !!dupAddressData,
        hasEmail: !!email,
      });
    }
  };

  const handleLogin = async (event) => {
    try {
      if (password.length === 0) {
        setPopupMessage("Password cannot be blank");
        return;
      }
      // Decrement attempts
      if (attempts >= 0 && password.length > 0) {
        const userQuery = query(
          collection(db, "users"),
          where("email", "==", email)
        );
        const userSnapshot = await getDocs(userQuery);
        if (userSnapshot.empty) {
          setAttempts(attempts - 1);
        } else {
          const userData = userSnapshot.docs[0].data();
          console.log("User found, resetting attempts: ", userData);
          console.log("Attempts: ", attempts);
          if (userData.password != password) {
            setAttempts(attempts - 1);
            if (attempts === 1) {
              await signInWithEmailAndPassword(auth, email, password);
              // Changed from 0 to 1 since we want to show message on 4th attempt
              console.log("User disabled");
              const userRef = doc(db, "users", userSnapshot.docs[0].id);
              await updateDoc(userRef, {
                disabled: true,
              });
              setPopupMessage(
                "Account has been disabled due to too many failed attempts. Please contact support."
              );
              return;
            }
          }
        }
      }

      // First check if this email is a fraud email from URL parameters
      const isDupAddress = searchParams.get("dupAddress") === "true";
      const encodedData = searchParams.get("data");

      if (isDupAddress && encodedData) {
        const verificationData = JSON.parse(atob(encodedData));

        // Check if this is the fraud email trying to log in
        if (verificationData.fraud_email === email) {
          // Check if the original user has verified yet
          const originalUserQuery = query(
            collection(db, "users"),
            where("email", "==", verificationData.original_email),
            where("dup_address", "==", true),
            where("dup_address_owner", "==", true),
            where("dup_address_token", "==", verificationToken)
          );
          const originalUserSnapshot = await getDocs(originalUserQuery);

          if (originalUserSnapshot.empty) {
            // Original user hasn't verified yet
            setRoleBasedRoute(null);
            setEmailfunc(email);
            navigate("/addressverify");
            return;
          }
        }
      }

      if (dupAddressData) {
        try {
          const verificationData = dupAddressData.data;
          if (verificationData.fraud_email === email) {
            const userRef = doc(db, "users", user.id);
            await updateDoc(userRef, {
              disabled: true,
              status: "inactive",
            });

            setRoleBasedRoute(null);
            setEmailfunc(email);
            navigate("/addressverify");

            return;
          }
        } catch (error) {
          console.error("Error parsing verification data:", error);
        }
      }

      const userData = (
        await getDocs(
          query(collection(db, "users"), where("email", "==", email))
        )
      ).docs[0].data();
      if (userData.disabled && userData.status === "inactive") {
        console.log(
          "fraud user redirected to addressverify from disabled login"
        );
        setRoleBasedRoute(null);
        setEmailfunc(email);
        navigate("/addressverify");
        return;
      } else if (userData.disabled && userData.status === "active") {
        setPopupMessage(
          "Account has been disabled due to too many failed attempts. Please contact support."
        );
        return;
      }

      // Proceed with normal login if all checks pass
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = await getUserByEmail(email);
      setRoleBasedRoute(user.user_role);
      login(userCredential.user.accessToken);

      if (isDupAddress && encodedData) {
        const verificationData = JSON.parse(atob(encodedData));
        if (verificationData.original_email === email) {
          // Original user verifying the address
          await handleDupAddressLogin();
        } else if (
          verificationData.fraud_email === email &&
          user.dup_address_verified
        ) {
          // Fraud user with verification completed
          setEmailfunc(email);
          navigate("/addressverify");
          return;
        }
      }

      // Normal login flow
      if (user.profile_completed) {
        const isDupAddress = searchParams.get("dupAddress") === "true";
        if (isDupAddress) {
          console.log("dupLogin is true, navigating to home with dupAddress");
          navigate("/home?dupAddress=true");
        } else {
          console.log("dupLogin is false, navigating to home");
          navigate("/home");
        }
      } else {
        switch (user.user_role) {
          case "partner":
            navigate("/partnerprofile");
            break;
          case "citizen":
            navigate("/citizenprofile");
            break;
          case "center":
            navigate("/centerprofile");
            break;
          default:
            const isDupAddress = searchParams.get("dupAddress") === "true";
            if (isDupAddress) {
              console.log(
                "dupLogin is true, navigating to home with dupAddresss"
              );
              navigate("/home?dupAddress=true");
            } else {
              console.log("dupLogin is false, navigating to homee");
              navigate("/home");
            }
        }
      }
    } catch (err) {
      const attemptsLeft = attempts - 1;
      switch (err.code) {
        case "auth/invalid-email":
          setPopupMessage(
            `Invalid email format. You have ${attemptsLeft} attempts remaining before your account is disabled.`
          );
          break;
        case "auth/user-not-found":
          setPopupMessage(
            `User not found. You have ${attemptsLeft} attempts remaining before your account is disabled.`
          );
          break;
        case "auth/wrong-password":
          setPopupMessage(
            `Incorrect password. You have ${attemptsLeft} attempts remaining before your account is disabled.`
          );
          break;
        case "auth/too-many-requests":
          setPopupMessage("Too many login attempts. Please try again later.");
          break;
        case "auth/invalid-credential":
          setPopupMessage(
            `Invalid credentials provided. You have ${attemptsLeft} attempts remaining before your account is disabled.`
          );
          break;
        case "auth/missing-password":
          setPopupMessage("Password cannot be blank");
          break;
        default:
          setPopupMessage(err.message);
      }
    }
  };

  const handleGoogleLogin = async (event) => {
    event.preventDefault();
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({ prompt: "select_account" });
    try {
      const result = await signInWithPopup(auth, provider);
      console.log(result);
      if (!checkIfEmailExists(result.user.email)) {
        const userData = {
          first_name: result.user.displayName.split(" ")[0],
          last_name: result.user.displayName.split(" ").slice(-1).join(" "),
          email: result.user.email,
          mobile_number: result.user.phoneNumber || "",
          status: "active",
          user_role: "citizen",
        };

        await setDoc(doc(db, "users", result.user.uid), userData);
      }
      const user = await getUserByEmail(email);
      setRoleBasedRoute(user.role);
      login(result.user.accessToken);
      setTimeout(() => {
        navigate("/home");
      }, 1000);
    } catch (err) {
      alert(err.message);
      console.error("Error during Google Sign-in:", err);
    }
  };

  const checkIfEmailExists = async (email) => {
    const q = query(collection(db, "users"), where("email", "==", email));
    const querySnapshot = await getDocs(q);

    return !querySnapshot.empty; // Returns true if email exists
  };

  const onContinue = () => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (email && emailPattern.test(email)) {
      setIsLogin1(false);
    } else {
      setPopupMessage("Please enter a valid email address.");
      setIsLogin1(true);
    }
  };

  return (
    <div>
      {popupMessage && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="w-[350px] rounded-lg bg-white shadow-lg">
            <div className="text-lg bg-blue rounded-t-lg p-4">Notification</div>
            <div className="flex flex-col justify-between p-4">
              <p>{popupMessage}</p>
              <div className="mt-4 flex justify-end">
                <button
                  onClick={() => setPopupMessage("")}
                  className="btn-default mt-4 px-4 py-2 hover:cursor-pointer"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {isLogin1 ? (
        <Login1
          email={email}
          onEmailChange={setEmail}
          onContinue={onContinue}
          onLoginWithGoogle={handleGoogleLogin}
        />
      ) : (
        <Login2
          password={password}
          email={email}
          onPasswordChange={setPassword}
          onLogin={handleLogin}
        />
      )}
    </div>
  );
};

export default Login;
