import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const SurveyQuestion = ({ 
  question, 
  type, 
  options, 
  onResponseChange, 
  userResponse,
  followUp,
  section 
}) => {
  const [checkedItems, setCheckedItems] = useState({});
  const [inputValue, setInputValue] = useState("");
  const [showFollowUp, setShowFollowUp] = useState(false);
  const [followUpResponse, setFollowUpResponse] = useState("");

  useEffect(() => {
    // Initialize state based on userResponse
    if (type === 'checkbox') {
      setCheckedItems(userResponse?.answer || {});
    } else if (type === 'text') {
      setInputValue(userResponse?.answer || "");
    }
    
    // Initialize follow-up if exists
    if (followUp && userResponse?.followUp) {
      setFollowUpResponse(userResponse.followUp);
      setShowFollowUp(userResponse.answer === followUp.condition);
    }
  }, [userResponse, type, followUp]);

  const handleCheckboxChange = (option) => {
    const newCheckedItems = {
      ...checkedItems,
      [option]: !checkedItems[option]
    };
    setCheckedItems(newCheckedItems);
    onResponseChange(question, { answer: newCheckedItems });
  };

  const handleInputChange = (event) => {
    const value = event.target.value;
    setInputValue(value);
    onResponseChange(question, { answer: value });
  };

  const handleRadioChange = (option) => {
    onResponseChange(question, {
      answer: option,
      followUp: option === followUp?.condition ? followUpResponse : undefined
    });
    
    if (followUp && option === followUp.condition) {
      setShowFollowUp(true);
    } else {
      setShowFollowUp(false);
      setFollowUpResponse("");
    }
  };

  const handleFollowUpChange = (event) => {
    const value = event.target.value;
    setFollowUpResponse(value);
    onResponseChange(question, {
      answer: userResponse.answer,
      followUp: value
    });
  };

  const renderCheckboxes = () => {
    if (question === "Do you have insurance on your home or contents?") {
      return (
        <div className="flex flex-col gap-2">
          {options.map((option, index) => (
            <label key={index} className="flex items-center cursor-pointer">
              <input
                type="checkbox"
                className="form-checkbox h-5 w-5 min-w-[1.25rem] min-h-[1.25rem] text-blue-600 rounded border-gray-300 focus:ring-blue-500"
                checked={checkedItems[option] || false}
                onChange={() => handleCheckboxChange(option)}
              />
              <span className="ml-2 text-sm text-gray-700">{option}</span>
            </label>
          ))}
        </div>
      );
    } else if (question === "There is visible damage to the home's:") {
      return (
        <div className="grid grid-cols-3 gap-4">
          {options.map((option, index) => (
            <label key={index} className="flex items-center cursor-pointer">
              <input
                type="checkbox"
                className="form-checkbox h-5 w-5 min-w-[1.25rem] min-h-[1.25rem] text-blue-600 rounded border-gray-300 focus:ring-blue-500"
                checked={checkedItems[option] || false}
                onChange={() => handleCheckboxChange(option)}
              />
              <span className="ml-2 text-sm text-gray-700">{option}</span>
            </label>
          ))}
        </div>
      );
    }
    return (
      <div className="flex flex-col gap-2">
        {options.map((option, index) => (
          <label key={index} className="flex items-center cursor-pointer">
            <input
              type="checkbox"
              className="form-checkbox h-5 w-5 min-w-[1.25rem] min-h-[1.25rem] text-blue-600 rounded border-gray-300 focus:ring-blue-500"
              checked={checkedItems[option] || false}
              onChange={() => handleCheckboxChange(option)}
            />
            <span className="ml-2 text-sm text-gray-700">{option}</span>
          </label>
        ))}
      </div>
    );
  };
  
  const renderRadioButtons = () => {
    return options.map((option, index) => (
      <label key={index} className="flex items-start w-full cursor-pointer mb-2">
        <input
          className="form-radio h-5 w-5 min-w-[1.25rem] min-h-[1.25rem] text-blue-600 border-gray-300 appearance-none"
          type="radio"
          name={question.replace(/\s+/g, '-').toLowerCase()}
          value={option}
          checked={userResponse?.answer === option}
          onChange={() => handleRadioChange(option)}
        />
        <span className="ml-2 text-sm text-gray-800 break-words">{option}</span>
      </label>
    ));
  };

  return (
    <div className="w-full flex flex-col shadow-[0px_4px_4px_-1px_rgba(12,_12,_13,_0.1),_0px_4px_4px_-1px_rgba(12,_12,_13,_0.05)] rounded-mini bg-ghostwhite-100 border-gainsboro-300 border-[0.5px] border-solid box-border overflow-hidden">
      <div className="flex-shrink-0 self-stretch relative font-semibold text-sm text-darkslategray-100 break-words pl-6 pr-6 pt-6 pb-3 bg-gray-100">
        {section && (
          <h3 className="font-bold text-lg mb-4 text-blue-800">{section}</h3>
        )}
        {question}
      </div>
      
      <div className="mb-4 px-6">
        {type === "checkbox" && renderCheckboxes()}
        {type === "radio" && (
          <div className="space-y-2">
            {renderRadioButtons()}
          </div>
        )}
        {type === "text" && (
          <input
            type="text"
            value={inputValue}
            onChange={handleInputChange}
            className="w-full p-2 border rounded"
            placeholder={options[0]}
          />
        )}
  
        {showFollowUp && followUp && (
          <div className="ml-4 mt-2">
            <label className="block mb-2 font-medium">{followUp.question}</label>
            <input
              type="text"
              value={followUpResponse}
              placeholder={followUp.placeholder}
              onChange={handleFollowUpChange}
              className="w-full p-2 border rounded"
            />
          </div>
        )}
      </div>
    </div>
  );
};

SurveyQuestion.propTypes = {
  question: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['radio', 'checkbox', 'text']).isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  onResponseChange: PropTypes.func.isRequired,
  userResponse: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.shape({
      answer: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      followUp: PropTypes.string
    })
  ]),
  followUp: PropTypes.shape({
    condition: PropTypes.string,
    question: PropTypes.string,
    placeholder: PropTypes.string
  }),
  section: PropTypes.string
};

export default SurveyQuestion;