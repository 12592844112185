import React, { useState, useEffect, useRef, use } from "react";
import PropTypes from "prop-types";
import { db, auth } from "../firebase";
import {
  collection,
  getDocs,
  getDoc,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  serverTimestamp,
} from "firebase/firestore";
import { onAuthStateChanged, getAuth } from "firebase/auth";
import Menu from "../pages/Profile-Popup/Menu";
import { useAuth } from "./AuthContext";
import ContactSupport from "../pages/Profile-Popup/ContactSupport";
import Popup from "./Popup";
import { useSearchParams } from "react-router-dom";

const Navbar = () => {
  const [userId, setUserId] = useState(null);
  const [data, setData] = useState([]);
  const { role } = useAuth();
  const [searchParams] = useSearchParams();
  const isDupAddress = searchParams.get("dupAddress") === "true";
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const auth = getAuth();

    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUserId(currentUser.uid);
      } else {
        setData(null);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (userId) {
      fetchData();
      console.log("Rolee: " + role);
    }
  }, [userId]);

  const fetchData = async () => {
    try {
      const userDocRef = doc(db, "users", userId);
      const userDocSnap = await getDoc(userDocRef);

      if (userDocSnap.exists()) {
        const userData = userDocSnap.data();
        // if duplicate address, show the popup message
        if (isDupAddress) setShowPopup(true);

        if (userData.user_role === "center") {
          console.log("Center ID: " + userData.center_id);
          const centerDocRef = doc(db, "centers", userData.center_id);
          const centerDocSnap = await getDoc(centerDocRef);
          if (centerDocSnap.exists()) {
            setData({ id: centerDocSnap.id, ...centerDocSnap.data() });
          }
        } else if (userData.user_role === "partner") {
          const partnerDocRef = doc(db, "partners", userData.partner_id);
          const partnerDocSnap = await getDoc(partnerDocRef);
          if (partnerDocSnap.exists()) {
            setData({ id: partnerDocSnap.id, ...partnerDocSnap.data() });
          }
        } else {
          setData({ id: userDocSnap.id, ...userDocSnap.data() });
        }
      } else {
        console.error("No such document!");
      }
    } catch (error) {
      console.error("Error fetching user data: ", error);
    }
  };

  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const [showSupport, setShowSupport] = useState(false);

  const toggleSupport = () => {
    setShowSupport(!showSupport);
  };

  return (
    <nav
      className={`bg-header-panel h-[6vh] border-none text-white shadow-none`}
    >
      <div
        className={`bg-header-panel fixed left-0 right-0 top-0 z-10 flex h-[6vh] w-full flex-row items-center justify-between text-left font-poppins text-base leading-[normal] tracking-[normal] text-white shadow-[0px_4px_4px_rgba(0,_0,_0,_0.25)]`}
      >
        {/* Blue Sky Logo */}
        <div className="flex h-[6vh] flex-row items-center justify-center gap-[11px] pl-7">
          <svg
            width="41"
            height="45"
            viewBox="0 0 41 45"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M34.7906 30.3727C34.7537 30.4353 34.7156 30.5002 34.6775 30.5651C31.8621 35.3843 27.6715 40.1085 21.4472 43.7976H21.3901C21.3721 43.8092 21.3553 43.8197 21.3363 43.8312H21.3878L20.2041 44.5L19.0215 43.8312L18.9677 43.8011L18.9621 43.7976C14.3292 40.9696 10.8206 37.6606 8.16532 34.2195C8.14068 34.1882 8.11828 34.1557 8.09364 34.1244C0.54113 24.2692 -0.0456894 13.3478 0.00246576 9.56124V9.55777C0.0136646 8.67343 0.0606999 8.17853 0.0606999 8.17853L0.460499 7.7636C1.0014 7.20147 1.58934 6.66832 2.21872 6.16531C2.23216 6.15603 2.24448 6.14676 2.25792 6.13517C2.3867 6.03665 2.51885 5.93698 2.65772 5.83267C2.67339 5.82223 2.68795 5.81064 2.70475 5.7979C2.71371 5.7921 2.72491 5.78399 2.73387 5.77587C3.41924 5.26011 4.14941 4.77911 4.92213 4.33869C4.98372 4.30391 5.04531 4.26798 5.10803 4.23205C5.18418 4.18917 5.26257 4.14513 5.33984 4.10109C5.37232 4.0837 5.40256 4.06631 5.43392 4.04893C7.75096 2.79023 10.4029 1.84215 13.2507 1.25452C13.9137 1.12124 14.5643 1.00765 15.2038 0.911453C15.3192 0.894068 15.4334 0.879001 15.5476 0.862774C17.1703 0.634447 18.7135 0.519703 20.1772 0.5C21.6398 0.519703 23.1841 0.634447 24.8068 0.862774C24.9211 0.879001 25.0353 0.894068 25.1495 0.911453C25.7901 1.00765 26.4407 1.12124 27.1037 1.25452C29.9516 1.84215 32.6035 2.79023 34.9194 4.04893C34.9519 4.06516 34.9832 4.08254 35.0146 4.10109C35.0919 4.14513 35.1691 4.18917 35.2453 4.23205C35.308 4.26798 35.3707 4.30391 35.4312 4.33869C36.0012 4.67364 36.5208 5.00628 36.9912 5.32965C37.0483 5.36906 37.1054 5.40846 37.1614 5.44787C37.3216 5.5603 37.4739 5.66924 37.6206 5.77587C37.6295 5.78399 37.6396 5.7921 37.6486 5.7979C37.6654 5.81064 37.681 5.82223 37.6967 5.83267C37.8345 5.93698 37.9677 6.03665 38.0954 6.13517C38.1088 6.1456 38.1212 6.15487 38.1346 6.16531C38.764 6.66832 39.353 7.20147 39.8939 7.7636L40.2926 8.17853C40.2926 8.17853 40.3408 8.67343 40.3509 9.55777V9.56124C40.3934 12.8714 39.9499 21.6348 34.7906 30.3727Z"
              fill="white"
            />
            <path
              d="M13.6113 13.0117L15.9426 13.7895L14.3876 15.3474L13.6113 13.0117Z"
              fill="#003460"
            />
            <path
              d="M26.7326 13.0117L25.9552 15.3474L24.4014 13.7895L26.7326 13.0117Z"
              fill="#003460"
            />
            <path
              d="M26.7326 26.3564L24.4014 25.5775L25.9552 24.0195L26.7326 26.3564Z"
              fill="#003460"
            />
            <path
              d="M13.6113 26.3564L14.3876 24.0195L15.9426 25.5775L13.6113 26.3564Z"
              fill="#003460"
            />
            <path
              d="M7.25377 18.4651C6.73743 16.0147 6.59195 13.8427 6.58241 12.2609C9.40499 9.63606 13.9328 8.00287 18.9579 7.77468L19.1522 5.58594C13.2853 5.79501 7.94898 7.79379 4.72692 11.0279L4.4133 11.3421L4.40853 11.7866C4.39422 13.1689 4.48962 15.6276 5.10016 18.5846L7.25377 18.4651Z"
              fill="#86181A"
            />
            <path
              d="M21.3907 7.77468C26.4145 8.00287 30.9424 9.63606 33.7661 12.2609C33.7566 13.8427 33.6123 16.0147 33.0948 18.4651L35.2484 18.5846C35.8589 15.6276 35.9543 13.1689 35.94 11.7866L35.9352 11.3421L35.6216 11.0279C32.3996 7.79499 27.0621 5.79501 21.1963 5.58594L21.3907 7.77468Z"
              fill="#86181A"
            />
            <path
              d="M32.5035 20.8039C31.0165 25.8122 27.8589 31.5135 21.2848 35.707L21.1035 38.3509C29.4568 33.5756 33.1451 26.498 34.7454 20.6797L32.5035 20.8039Z"
              fill="#86181A"
            />
            <path
              d="M19.0671 35.7058C12.493 31.5135 9.3353 25.8122 7.84829 20.8039L5.60645 20.6797C7.20793 26.4992 10.8951 33.5756 19.2483 38.3509L19.0671 35.707V35.7058Z"
              fill="#86181A"
            />
            <path
              d="M21.8747 13.2155C24.2215 13.8416 26.0555 15.7257 26.6124 18.1032L27.434 18.1486C26.8592 15.2872 24.6341 13.022 21.8008 12.3828L21.8747 13.2155Z"
              fill="#FDB841"
            />
            <path
              d="M13.7367 18.1032C14.2935 15.7257 16.1276 13.8416 18.4743 13.2155L18.5483 12.3828C15.715 13.022 13.491 15.2872 12.915 18.1486L13.7367 18.1032Z"
              fill="#FDB841"
            />
            <path
              d="M18.3968 25.9948C16.087 25.3484 14.2876 23.4787 13.7367 21.1274L12.915 21.082C13.4838 23.9111 15.6661 26.1548 18.4541 26.8227L18.3968 25.9948Z"
              fill="#FDB841"
            />
            <path
              d="M26.611 21.1274C26.06 23.4787 24.2606 25.3484 21.9508 25.9948L21.8936 26.8227C24.6804 26.1548 26.8626 23.91 27.4314 21.082L26.6098 21.1274H26.611Z"
              fill="#FDB841"
            />
            <path
              d="M18.0697 17.8675L18.2987 15.2773H18.2879H15.6514V17.1387V18.0025L18.0697 17.8675Z"
              fill="#003460"
            />
            <path
              d="M22.292 17.8711L25.6488 18.0574C25.6119 17.9463 25.5749 17.8364 25.5296 17.7313C25.2923 17.1901 24.9405 16.7361 24.4718 16.3729C24.0032 16.0085 23.4165 15.7361 22.7118 15.5533C22.5102 15.5007 22.2968 15.4589 22.0762 15.4219L22.2932 17.8711H22.292Z"
              fill="#003460"
            />
            <path
              d="M18.0864 21.3772L15.6514 21.2422V22.1048V23.9662H18.2641L18.0864 21.3772Z"
              fill="#003460"
            />
            <path
              d="M22.2717 21.3739L22.1035 23.8159C22.3122 23.7801 22.5149 23.7382 22.7069 23.6893C23.4117 23.5077 23.9995 23.2341 24.467 22.8709C24.9356 22.5065 25.2874 22.0537 25.5247 21.5125C25.57 21.4085 25.607 21.2974 25.644 21.1875L22.2705 21.3751L22.2717 21.3739Z"
              fill="#003460"
            />
            <path
              d="M21.7252 21.8034L20.1738 20.1367L18.6271 21.8046C18.614 21.8177 18.5985 21.8237 18.583 21.8344L20.1761 40.385L21.7693 21.8332C21.755 21.8225 21.7383 21.8165 21.7252 21.8034Z"
              fill="#003460"
            />
            <path
              d="M18.6264 17.1163L20.1778 18.783L21.7244 17.1151C21.7244 17.1151 21.734 17.1103 21.7376 17.1056L20.1754 3.05078L18.6133 17.1056C18.6133 17.1056 18.6228 17.1115 18.6276 17.1151L18.6264 17.1163Z"
              fill="#003460"
            />
            <path
              d="M22.2623 18.1406L20.8516 19.4584L22.5163 21.008C22.5318 21.0235 22.5401 21.0426 22.552 21.0605L38.6921 19.6137L22.2623 18.1406Z"
              fill="#477EA6"
            />
            <path
              d="M17.8372 21.008L19.5007 19.4536L18.0912 18.1406L1.66016 19.6137L17.8015 21.0605C17.8134 21.0426 17.8217 21.0223 17.8372 21.0068V21.008Z"
              fill="#477EA6"
            />
          </svg>
          <div className="flex flex-col items-start font-semibold text-[inherit]">
            <div className="flex flex-col items-baseline text-white">
              <p className="header-logo-label">DARCI</p>
              <p className="header-powered-by -my-1 text-[8px] font-normal">
                Disaster Aware & Ready Communities
              </p>
            </div>
          </div>
        </div>

        {/* User Nav */}
        <div className="flex max-h-[6vh] flex-col items-center justify-center pr-7 text-right text-xs text-honeydew">
          <div className="flex flex-row items-center justify-center gap-[14px]">
            <svg
              width="26"
              height="27"
              viewBox="0 0 26 27"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={toggleSupport}
            >
              <path
                d="M19.6104 1H6.61035C3.29664 1 0.610352 3.68629 0.610352 7V20C0.610352 23.3137 3.29664 26 6.61035 26H19.6104C22.9241 26 25.6104 23.3137 25.6104 20V7C25.6104 3.68629 22.9241 1 19.6104 1Z"
                fill="#FBBB4C"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.86035 11.0208C7.86035 9.55658 8.41347 8.15235 9.39804 7.117C10.3826 6.08165 11.718 5.5 13.1104 5.5C14.5028 5.5 15.8381 6.08165 16.8227 7.117C17.8073 8.15235 18.3604 9.55658 18.3604 11.0208V11.8371C19.6324 12.0287 20.6104 13.1794 20.6104 14.5698V14.767C20.6103 15.109 20.5451 15.4475 20.4187 15.7626C20.2923 16.0777 20.1072 16.363 19.8743 16.6017C19.6415 16.8404 19.3655 17.0277 19.0627 17.1526C18.76 17.2774 18.4365 17.3373 18.1114 17.3287C17.5564 19.0007 16.1449 20.1277 14.6217 20.4132C14.2692 20.5489 13.8521 20.485 13.4854 20.485C13.187 20.485 12.9009 20.3603 12.6899 20.1385C12.4789 19.9166 12.3604 19.6157 12.3604 19.3019C12.3604 18.9882 12.4789 18.6873 12.6899 18.4654C12.9009 18.2436 13.187 18.1189 13.4854 18.1189H14.2354C14.4115 18.1189 14.5852 18.1623 14.7424 18.2457C14.8996 18.3292 15.036 18.4502 15.1406 18.5992C16.1126 18.1126 16.8604 17.085 16.8604 15.7529V11.0208C16.8604 9.97492 16.4653 8.9719 15.7621 8.23236C15.0588 7.49283 14.105 7.07737 13.1104 7.07737C12.1158 7.07737 11.162 7.49283 10.4587 8.23236C9.75544 8.9719 9.36035 9.97492 9.36035 11.0208V15.95C9.36035 16.3161 9.22207 16.6672 8.97593 16.926C8.72979 17.1848 8.39595 17.3302 8.04785 17.3302C7.40138 17.3302 6.7814 17.0602 6.32428 16.5795C5.86716 16.0988 5.61035 15.4468 5.61035 14.767V14.5698C5.61019 13.9059 5.83757 13.2642 6.25082 12.7623C6.66406 12.2604 7.23549 11.9319 7.86035 11.8371V11.0208ZM7.86035 13.4539C7.64092 13.5354 7.45095 13.6866 7.31662 13.8865C7.1823 14.0863 7.11023 14.3251 7.11035 14.5698V14.767C7.11035 15.2442 7.43285 15.6425 7.86035 15.7332V13.4539ZM18.3604 13.4539V15.7332C18.7879 15.6417 19.1104 15.2442 19.1104 14.767V14.5698C19.1105 14.3251 19.0385 14.0863 18.9041 13.8865C18.7698 13.6866 18.5798 13.5354 18.3604 13.4539Z"
                fill="#9C1717"
              />
            </svg>
            <div className="flex flex-col items-end justify-start">
              <p
                className="relative inline-block min-w-[86px] cursor-pointer font-semibold text-[inherit] [text-decoration:none]"
                onClick={toggleMenu}
              >
                {data.first_name
                  ? data.first_name
                  : // eslint-disable-next-line no-useless-concat
                  "First Name" + " " + data.last_name
                  ? data.last_name
                  : "Last Name"}
              </p>
              {/* <p className="relative inline-block min-w-[40px] font-light text-white [text-decoration:none]">
                {role === "admin"
                  ? "Admin"
                  : role === "citizen"
                  ? "Citizen"
                  : role === "center"
                  ? "Center"
                  : "Partner"}
              </p> */}
            </div>
            <div
              className="box-border flex h-[25px] w-[25px] cursor-pointer flex-col items-start justify-start px-0 pb-0  text-center"
              onClick={toggleMenu}
            >
              <div className="bg-gold flex h-[25px]  w-[25px] flex-row items-center justify-center self-stretch rounded-md">
                <div className="relative hidden h-[25px] w-[25px] rounded-md bg-white " />
                <p className="relative z-[1] font-medium text-[inherit] [text-decoration:none]">
                  {data.first_name
                    ? data.first_name.charAt(0)
                    : role === "partner"
                    ? "P"
                    : role === "center"
                    ? "C"
                    : role === "admin"
                    ? "A"
                    : ""}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Menu isOpen={showMenu} onClose={toggleMenu} data={data} />
      <ContactSupport
        isOpen={showSupport}
        onClose={toggleSupport}
        data={data}
      />
      {showPopup && (
        <Popup
          title="Duplicate Address Notification"
          message="The duplicate address registration has been recorded. Thank you for reporting this activity."
          onClose={() => setShowPopup(false)}
        />
      )}
    </nav>
  );
};

export default Navbar;
