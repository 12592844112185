import React, { useState, useEffect, useRef } from "react";
import { FaPlus, FaEye, FaEyeSlash } from "react-icons/fa";
import { registerLicense } from "@syncfusion/ej2-base";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Inject,
  Edit,
  Toolbar,
  Search,
  Filter,
  Sort,
  Page,
  CommandColumn,
  ColumnMenu,
} from "@syncfusion/ej2-react-grids";
import { USStates } from "../datasource";
import { Query } from "@syncfusion/ej2-data";
import { db, auth } from "../../firebase"; // Import Firebase config
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  serverTimestamp,
  query,
  where,
  setDoc,
  getDoc,
} from "firebase/firestore";
import { onAuthStateChanged, signInWithEmailAndPassword } from "firebase/auth";
import { createUserWithEmailAndPassword } from "firebase/auth";
import "../style.css";
import dataService from "../../service/data/DataService";
import {
  CheckBoxSelection,
  MultiSelectComponent,
} from "@syncfusion/ej2-react-dropdowns";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";

// Syncfusion license key
registerLicense(
  "Ngo9BigBOggjHTQxAR8/V1NBaF5cXmZCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWXxceXRcQmZdV0R/XUM="
);

const editOptions = {
  allowDeleting: true,
  allowAdding: true,
  allowEditing: true,
  showDeleteConfirmDialog: true,
  mode: "Dialog",
  dialog: { cssClass: "custom-dialog" },
};
const filterSettings = {
  type: "CheckBox",
};
const columnMenuItems = ["SortAscending", "SortDescending", "Filter"];

const pageSettings = { pageSize: 10 };

const validationRules = { required: true };

const preprocessData = (data) => {
  return data.map((item) => ({
    ...item,
    Address: `${item.address1} ${item.address2} ${item.city} ${item.state} ${item.zip}`,
  }));
};

const formatLastEditedTime = (time) => {
  if (!time) return "Never";

  const now = new Date();
  const diffInSeconds = Math.floor((now - time) / 1000);

  if (diffInSeconds < 60) return "Just now";
  if (diffInSeconds < 3600)
    return `${Math.floor(diffInSeconds / 60)} minutes ago`;
  if (diffInSeconds < 86400)
    return `${Math.floor(diffInSeconds / 3600)} hours ago`;
  if (diffInSeconds < 604800)
    return `${Math.floor(diffInSeconds / 86400)} days ago`;

  return time.toLocaleDateString();
};

const generateRandomPassword = (length = 8) => {
  const charset =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()";
  let password = "";
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charset.length);
    password += charset[randomIndex];
  }
  return password;
};

const servicesProvided = [
  { value: "Funding", text: "Funding" },
  { value: "Debris Removal", text: "Debris Removal" },
  { value: "Case Management", text: "Case Management" },
  { value: "House Repair", text: "House Repair" },
  { value: "House Rebuilds", text: "House Rebuilds" },
  { value: "Volunteer Labor", text: "Volunteer Labor" },
  { value: "Spriritual/Mental Care", text: "Spriritual/Mental Care" },
  { value: "Donation of Goods", text: "Donation of Goods" },
];

const fields = { value: "value", text: "text" };

const Services = (props) => {
  const currentServices = props.servicesProvided || [];

  return (
    <div>
      <label
        htmlFor="servicesProvided"
        className="mb-2 block text-xs text-gray-500"
      >
        Primary Service Provided
      </label>
      <MultiSelectComponent
        id="servicesProvided"
        dataSource={servicesProvided}
        fields={fields}
        value={currentServices}
        mode="CheckBox"
        selectAllText="Select All"
        unSelectAllText="Unselect All"
        showSelectAll={true}
        change={(e) => {
          if (props.onChange) {
            props.onChange({ value: e.value });
          }
        }}
      >
        <Inject services={[CheckBoxSelection]} />
      </MultiSelectComponent>
    </div>
  );
};

const disaster = [
  { value: "Fire", text: "1" },
  { value: "Flood", text: "2" },
  { value: "Earthquake", text: "3" },
];

const fieldsDisaster = { value: "value", text: "value" };

const DisasterTemplate = (props) => {
  const currentAccess = (props && props.disaster) || [];

  return (
    <div>
      <label htmlFor="disaster" className="mb-2 block text-xs text-gray-500">
        Disaster
      </label>
      <MultiSelectComponent
        id="disaster"
        dataSource={disaster}
        fields={fieldsDisaster}
        value={currentAccess}
        mode="CheckBox"
        selectAllText="Select All"
        unSelectAllText="Unselect All"
        showSelectAll={true}
      >
        <Inject services={[CheckBoxSelection]} />
      </MultiSelectComponent>
    </div>
  );
};

const PasswordTemplate = (props) => {
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState(props.password || "");

  useEffect(() => {
    setPassword(props.password || "");
  }, [props.password]);

  const handleChange = (e) => {
    setPassword(e.value);
    if (props.onChange) {
      props.onChange({ ...e, value: e.value });
    }
  };

  return (
    <div className="relative w-full">
      <TextBoxComponent
        placeholder="Password"
        floatLabelType="Always"
        type={showPassword ? "text" : "password"}
        value={password}
        change={handleChange}
        name="password" // Ensure the name attribute is set for validation
      />
      <button
        type="button"
        onClick={() => setShowPassword(!showPassword)}
        className="absolute right-3 top-1/2 -translate-y-1/2 transform cursor-pointer text-blue-500"
      >
        {showPassword ? <FaEyeSlash /> : <FaEye />}
      </button>
    </div>
  );
};

const CenterDisasterRecovery = ({ className = "" }) => {
  const [centerDisasterRecovery, setCenterDisasterRecovery] = useState([]);
  const gridRef = useRef(null);
  const [lastEditedTime, setLastEditedTime] = useState(null);
  const [userId, setUserId] = useState(null);
  const [currentUserData, setCurrentUserData] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUserId(user.uid);
        const userDocRef = doc(db, "users", user.uid);
        const userDocSnap = await getDoc(userDocRef);
        console.log("User Doc Snap:", userDocSnap.data());

        const contacts = await fetchContacts(userDocSnap.data().center_id);
        setCenterDisasterRecovery(contacts);
        const userData = await dataService.getUserProfile("users", user.uid);
        if (userData) {
          setCurrentUserData(userData);
          console.log("Data: ", userData);
        }
      } else {
        setUserId(null);
        setCenterDisasterRecovery([]);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleAdd = () => {
    if (gridRef.current) {
      gridRef.current.addRecord();
    }
  };

  const actionBegin = async (args) => {
    if (gridRef.current) {
      try {
        if (args.requestType === "beginEdit" || args.requestType === "add") {
          const cols = gridRef.current.columns;
          for (const col of cols) {
            if (
              [
                "address1",
                "address2",
                "city",
                "state",
                "zip",
                "email",
              ].includes(col.field)
            ) {
              col.visible = true;
            } else if (["Address"].includes(col.field)) {
              col.visible = false;
            } else if (col.field === "password") {
              col.visible = args.requestType === "add";
            }
          }
          if (args.requestType === "add") {
            args.data.password = generateRandomPassword();
          }
        }
        if (args.requestType === "save") {
          setLastEditedTime(new Date());
          const cols = gridRef.current.columns;
          for (const col of cols) {
            if (
              [
                "first_name",
                "last_name",
                "address1",
                "address2",
                "city",
                "state",
                "zip",
                "password",
              ].includes(col.field)
            ) {
              col.visible = false;
            } else if (["Address"].includes(col.field)) {
              col.visible = true;
            }
          }

          const data = args.data;
          data.Address = `${data.address1 || ""} ${data.address2 || ""} ${
            data.city || ""
          } ${data.state || ""} ${data.zip || ""}`.trim();

          Object.keys(data).forEach((key) => {
            if (data[key] === undefined) {
              delete data[key];
            }
          });

          if (args.action === "add" && (!data.email || !data.password)) {
            console.error("Email and password must be provided for new users.");
            args.cancel = true;
            return;
          }

          if (args.action === "add") {
            console.log("Adding");
            try {
              const userCredential = await createUserWithEmailAndPassword(
                auth,
                data.email,
                data.password
              );
              const user = userCredential.user;
              await setDoc(doc(db, "users", user.uid), {
                ...data,
                center_id: [userId], // Changed to array with userId as first item
                last_updated: serverTimestamp(),
                inserted_at: serverTimestamp(),
                id: user.uid,
                type: "local-non-profit",
                user_role: "partner",
                is_deleted: false,
              });
              console.log("Done adding");
              await fetchContacts(userId);
            } catch (error) {
              console.error("Error adding new user:", error);
              args.cancel = true;
            }
          } else if (args.action === "edit") {
            if (data.id) {
              try {
                const drpDoc = doc(db, "users", data.id);
                delete data.password; // Remove password field for edit action
                await updateDoc(drpDoc, {
                  ...data,
                  last_updated: serverTimestamp(),
                  is_deleted: false,
                });
                await fetchContacts(userId);
              } catch (error) {
                console.error("Error updating document:", error);
                args.cancel = true;
              }
            } else {
              console.error("Document ID is missing for update.");
              args.cancel = true;
            }
          }
        }
        if (args.requestType === "delete") {
          const cols = gridRef.current.columns;
          for (const col of cols) {
            if (
              ["address1", "address2", "city", "state", "zip"].includes(
                col.field
              )
            ) {
              col.visible = false;
            } else if (["Address"].includes(col.field)) {
              col.visible = true;
            }
          }
          const data = args.data[0];
          if (data && data.id) {
            try {
              const drpDoc = doc(db, "users", data.id);
              await updateDoc(drpDoc, {
                is_deleted: true,
                last_updated: serverTimestamp(),
              });
              await fetchContacts(userId);
            } catch (error) {
              console.error("Error deleting document:", error);
              args.cancel = true;
            }
          } else {
            console.error("Document ID is missing for delete.");
            args.cancel = true;
          }
        }
      } catch (error) {
        console.error("Error during actionBegin:", error);
        args.cancel = true;
      }
    }
  };

  return (
    <>
       <div
          className={`m-0 box-border flex w-[1648px] max-w-full flex-col items-start justify-start gap-2.5 px-2.5 py-[15px] leading-[normal] tracking-[normal] ${className}`}
       >
          <div id="dialog-container"></div>
          <div className="flex flex-row items-start justify-start gap-1.5  text-xs text-darkslateblue">
              <a className="relative inline-block min-w-[60px] text-left  text-xs font-medium leading-[16px] text-dodgerblue [text-decoration:none]">
                Recovery Plan
              </a>
              <div className="relative inline-block min-w-[5px] text-left font-mulish text-base font-semibold leading-[16px] text-gray-400">
                /
              </div>
              <a className="relative inline-block min-w-[77px] text-left  text-xs font-medium leading-[16px] text-dodgerblue [text-decoration:none]">
                Disaster Recovery Partners Search
              </a>
          </div>
          <div className="flex max-w-full flex-col items-start justify-start self-stretch bg-white">
              <div className="relative mb-2 inline-block max-w-full font-[Inter] font-medium leading-[21px] text-darkslategray-200">
                   <p>
                      Disaster Partners are organizations and agencies from various
                      regions that provide specialized support during disaster response
                      and recovery. While not active year-round, they are critical
                      resources during emergencies, offering expertise, personnel, and aid
                      when your community needs it most.
                   </p>
              </div>
          </div>
          <section className="flex max-w-full flex-col items-start justify-start self-stretch bg-white pb-4">
              <header className="mb-2 flex flex-row items-center justify-between self-stretch">
                <div>
                  <h2 className="mb-1 text-sm font-bold uppercase text-blue-500">
                    DISASTER RECOVERY PARTNERS
                  </h2>
                </div>
                <div className="flex items-center">
                  <button
                    type="button"
                    className="flex cursor-pointer gap-2 btn-default px-3 py-1.5"
                    onClick={handleAdd}
                  >
                    <FaPlus className="h-3 w-3" />
                    Add
                  </button>
                </div>
              </header>
              <div>
                <GridComponent
                  dataSource={centerDisasterRecovery.filter(
                    (contact) => contact.is_deleted !== true
                  )}
                  actionBegin={actionBegin}
                  editSettings={editOptions}
                  filterSettings={filterSettings}
                  allowFiltering={true}
                  allowSorting={true}
                  ref={gridRef}
                  toolbar={["Search"]}
                  allowPaging={true}
                  pageSettings={pageSettings}
                  showColumnMenu={true}
                  columnMenuItems={columnMenuItems}
                >
                  <ColumnsDirective>
                    <ColumnDirective
                      field="county"
                      headerText="Primary County"
                      visible={true}
                      validationRules={validationRules}
                    />
                    <ColumnDirective
                      field="name_of_org"
                      headerText="Organization"
                      visible={true}
                      validationRules={validationRules}
                    />
                    <ColumnDirective
                      field="servicesProvided"
                      headerText="Primary Service Provided"
                      editTemplate={Services}
                      validationRules={validationRules}
                    />
                    <ColumnDirective field="Address" headerText="Address" />
                    <ColumnDirective
                      field="address1"
                      headerText="Address 1"
                      visible={false}
                      validationRules={validationRules}
                    />
                    <ColumnDirective
                      field="address2"
                      headerText="Address 2"
                      visible={false}
                      validationRules={validationRules}
                    />
                    <ColumnDirective
                      field="city"
                      headerText="City"
                      visible={false}
                      validationRules={validationRules}
                    />
                    <ColumnDirective
                      field="state"
                      headerText="State"
                      visible={false}
                      editType="dropdownedit"
                      edit={{
                        params: {
                          dataSource: USStates,
                          fields: { text: "abbreviation", value: "abbreviation" },
                          query: new Query(),
                        },
                      }}
                      validationRules={validationRules}
                    />
                    <ColumnDirective
                      field="zip"
                      headerText="Zip"
                      visible={false}
                      validationRules={validationRules}
                    />
                    <ColumnDirective
                      field="mobile_number"
                      headerText="Mobile Number"
                      validationRules={validationRules}
                    />
                    <ColumnDirective
                      field="email"
                      headerText="Email"
                      validationRules={validationRules}
                    />
                    <ColumnDirective
                      field="password"
                      headerText="Password"
                      visible={false}
                      validationRules={validationRules}
                      editTemplate={PasswordTemplate}
                    />
                    <ColumnDirective
                      headerText="Commands"
                      width="120"
                      textAlign="Center"
                      commands={[
                        {
                          type: "Edit",
                          buttonOption: {
                            content: '<i class="fas fa-edit"></i>',
                            cssClass: "e-outline custom-button",
                          },
                        },
                        {
                          type: "Delete",
                          buttonOption: {
                            content: '<i class="fas fa-trash-alt"></i>',
                            cssClass: "e-outline custom-button",
                          },
                        },
                      ]}
                    />
                  </ColumnsDirective>
                  <Inject
                    services={[
                      Edit,
                      Filter,
                      Sort,
                      Page,
                      CommandColumn,
                      Search,
                      ColumnMenu,
                    ]}
                  />
                </GridComponent>
              </div>
          </section>
       </div>
    </>
  );
};

const fetchContacts = async (centerId) => {
  if (!centerId) {
    console.log("No user ID available, skipping fetch.");
    return;
  }

  try {
    const querySnapshot = await getDocs(
      query(
        collection(db, "users"),
        where("center_id", "array-contains", centerId),
        where("user_role", "==", "partner")
      )
    );
    const drpPartnerData = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    console.log("DRP Partner Data:", drpPartnerData);

    const partnerIds = drpPartnerData.map((partner) => partner.partner_id);
    const partnerData = await Promise.all(
      partnerIds.map(async (partnerId) => {
        const partnerDocRef = doc(db, "partners", partnerId);
        const partnerDocSnap = await getDoc(partnerDocRef);
        if (partnerDocSnap.exists()) {
          const partnerData = partnerDocSnap.data();
          if (partnerData.type === "disaster-recovery") {
            return { id: partnerDocSnap.id, ...partnerData };
          } else {
            console.log(
              `Partner with id: ${partnerId} is not a disaster-recovery partner.`
            );
            return null;
          }
        } else {
          console.error(`No partner document found with id: ${partnerId}`);
          return null;
        }
      })
    );

    console.log("Fetched partner data:", partnerData.filter(Boolean));
    return preprocessData(partnerData.filter(Boolean));
  } catch (error) {
    console.error("Error fetching contacts:", error);
    return [];
  }
};

export default CenterDisasterRecovery;
