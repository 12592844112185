import React from "react";
import PropTypes from "prop-types";

const Popup = ({ title, message, onClose }) => {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="w-[450px] rounded-lg bg-white shadow-lg">
        <div className="bg-blue flex items-center justify-between rounded-t-lg p-4 text-white">
          <h2 className="!important text-[24px]">{title}</h2>
          <button
            onClick={onClose}
            className="bg-blue cursor-pointer rounded-full text-[24px] text-white"
          >
            &times;
          </button>
        </div>
        <div className="flex flex-col justify-between p-4">
          <p className="text-base text-gray-800">{message}</p>
          <div className="mt-4 flex justify-end">
            <button
              onClick={onClose}
              className="btn-default mt-4 px-4 py-2 hover:cursor-pointer"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

Popup.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Popup;
