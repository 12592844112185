import React, { useState, useRef, useEffect } from "react";
import { DisasterProvider } from "./pages/Disasters/DisasterContext";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  useNavigate,
} from "react-router-dom";
import dataService from "./service/data/DataService";
import { onAuthStateChanged, getAuth } from "firebase/auth";
import LeftNavPane from "./components/LeftNavPane";
import Home from "./pages/Home";
import CitizenHome from "./pages/CitizenHome.js";
import Map from "./pages/Map";
import Library from "./pages/Library";
import CertificationVideos from "./pages/CertificationVideos";
import CommunityContacts from "./pages/CommunityContacts";
import LocalNonProfitCoalition from "./pages/LocalNonProfit";
import Opportunities from "./pages/Opportunities";
import JobOpportunities from "./pages/JobOpportunities";
import VolunteerOpportunities from "./pages/VolunteerOpportunities";
import MyLocalCommunity from "./pages/MyLocalCommunity";
import DrcCenter from "./pages/DrcCenter";
import MyRecoveryPlan from "./pages/MyRecoveryPlan";
import CitizenProfile from "./pages/CitizenProfile";
import Lockbox from "./pages/Lockbox";
import Disasters from "./pages/Disasters";
import Calendar from "./pages/Calendar";
import Communication from "./pages/Communication";
import MyHistory from "./pages/MyHistory";
import Navbar from "./components/Navbar";
import Login from "./pages/Login";
import AccessAccount from "./pages/AccessAccount";
import Privacy from "./pages/privacy";
import Terms from "./pages/terms";
import Register from "./pages/Register";
import Register2 from "./pages/Register2";
import RegisterV2 from "./pages/RegisterV2";
import ForgotPassword from "./pages/ForgotPassword";
import { AuthProvider, useAuth } from "./components/AuthContext";
import ImmediateResponseSurvey from "./pages/Disasters/ImmediateResponseSurvey";
import DisasterManagement from "./pages/Disasters/DisasterManagement";
import AssistanceLog from "./pages/Disasters/AssistanceLog";
import RecoveryPlanDisasters from "./pages/Disasters/RecoveryPlan";
import Journals from "./pages/Disasters/Journals";
import ContactsDisaster from "./pages/Disasters/ContactsDisaster";
import QuickInfo from "./pages/Library/QuickInfo";
import Preparedness from "./pages/Library/Preparedness";
import Response from "./pages/Library/Response";
import Recovery from "./pages/Library/Recovery";
import CitizenSearch from "./pages/center-pov/CitizenSearch";
import LocalNonProfitSearch from "./pages/center-pov/LocalNonProfitSearch";
import DisasterRecoverySearch from "./pages/center-pov/DisasterRecoverySearch";
import Contacts from "./pages/center-pov/Contacts";
import CommunityProfile from "./pages/center-pov/CommunityProfile";
import EmergencyMgtDocuments from "./pages/center-pov/EmergencyMgtDocuments";
import Demographics from "./pages/center-pov/Demographics";
import GrantTracker from "./pages/center-pov/GrantTracker";
import ReportGenerator from "./pages/center-pov/ReportGenerator";
import Settings from "./pages/center-pov/Settings";
import CenterDisasterRecovery from "./pages/center-pov/CenterDisasterRecovery";
import CenterLocalNonProfit from "./pages/center-pov/CenterLocalNonProfit";
import ContactProfilePopup from "./pages/Communication/ContactProfilePopup";
import PartnerOpportunities from "./pages/partner-pov/PartnerOpportunities";
import PartnerJobOpportunities from "./pages/partner-pov/PartnerJobOpportunities";
import PartnerVolunteerOpportunities from "./pages/partner-pov/PartnerVolunteerOpportunities";
import PartnerCitizenSearch from "./pages/partner-pov/PartnerCitizenSearch";
import PartnerGrantTracker from "./pages/partner-pov/PartnerGrantTracker";
import PartnerCalendar from "./pages/partner-pov/PartnerCalendar";
import PartnerHistory from "./pages/partner-pov/PartnerHistory";
import PartnerProfile from "./pages/partner-pov/PartnerProfile";
import PartnerSettings from "./pages/partner-pov/PartnerSettings";
import MyCenterPartnerships from "./pages/partner-pov/MyCenterPartnerships";
import PartnerLockBox from "./pages/partner-pov/PartnerLockbox";
import DRCCenterPartnerships from "./pages/partner-pov/DRCCenterPartnerships";
import PartnerAssistanceLog from "./pages/partner-pov/PartnerAssistanceLog";
import Citizen from "./pages/admin-pov/Citizen";
import Center from "./pages/admin-pov/Center";
import NPCPartner from "./pages/admin-pov/NPCPartner";
import DRPartner from "./pages/admin-pov/DRPartner";
import PartnerRegister from "./pages/PartnerRegister";
import CenterRegister from "./pages/CenterRegister";
import Members from "./pages/center-pov/Members";
import Partnerships from "./pages/center-pov/Partnerships";
import Employees from "./pages/Employees";
import CaseManagement from "./pages/center-pov/CaseManagement/CaseManagement";
import DisasterDeclaration from "./pages/center-pov/DisasterDeclaration.js";
import DisasterDetail from "./pages/center-pov/DisasterDetail.js";

import "./App.css";
import CenterAssistanceLog from "./pages/center-pov/CenterAssistanceLog.js";
import CenterDisasters from "./pages/center-pov/CaseManagement/CenterDisasters.js";
import AddressVerify from "./pages/AddressVerify";
import { GeneralProvider } from "./context/GeneralContext.js";

const AppContent = () => {
  const [userId, setUserId] = useState(null);
  const [data, setData] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const { role, setRoleBasedRoute } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const auth = getAuth();

    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        setUserId(currentUser.uid);
        // Check user status
        const userData = await dataService.getUserProfile(
          "users",
          currentUser.uid
        );
        if (userData) {
          if (userData.disabled && userData.status === "active") {
            // Clear all stored data
            localStorage.clear();
            sessionStorage.clear();

            // Reset auth context states if needed
            setData(null);
            setUserRole(null);
            setRoleBasedRoute(null);
          }
          if (userData.status === "inactive" && !userData.disabled) {
            // Clear all stored data
            localStorage.clear();
            sessionStorage.clear();

            // Reset auth context states if needed
            setData(null);
            setUserRole(null);
            setRoleBasedRoute(null);

            // Navigate to registration
            navigate("/register2");
          } else if (userData.disabled && userData.status === "inactive") {
            console.log("disabled in app js");
            setData(null);
            setUserRole(null);
            setRoleBasedRoute(null);
            navigate("/addressverify");
            return;
          } else {
            setData(userData);
            setUserRole(userData.user_role);
            setRoleBasedRoute(userData.user_role);
          }
        }
      } else {
        // Clear states when no user is logged in
        setData(null);
        setUserRole(null);
        localStorage.clear();
        sessionStorage.clear();
        console.log("No user is logged in");
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  useEffect(() => {
    if (userId) {
      fetchData();
    }
  }, [userId]);

  const fetchData = async () => {
    const userData = await dataService.getUserProfile("users", userId);
    if (userData) {
      setData(userData);
      setUserRole(userData.user_role);
      setRoleBasedRoute(userData.user_role);
    }
  };

  const handleNavigateBack = () => {
    navigate(-1);
  };

  const publicRoutes = [
    { path: "", element: <Login /> },
    { path: "/login", element: <Login /> },
    { path: "/register", element: <Register /> },
    { path: "/partnerregister", element: <PartnerRegister /> },
    { path: "/centerregister", element: <CenterRegister /> },
    { path: "/forgotpassword", element: <ForgotPassword /> },
    { path: "/registerv2", element: <RegisterV2 /> },
    { path: "/privacy", element: <Privacy /> },
    { path: "/terms", element: <Terms /> },
    { path: "/accessaccount", element: <AccessAccount /> },
    { path: "/addressverify", element: <AddressVerify /> },
  ];

  const citizenAuthenticatedRoutes = [
    { path: "/register2", element: <Register2 /> },
    // { path: "/home", element: <Home /> },
    { path: "/home", element: <CitizenHome /> },
    { path: "/map", element: <Map /> },
    { path: "/library", element: <Library /> },
    { path: "/library/certificationvideos", element: <CertificationVideos /> },
    { path: "/library/communitycontacts", element: <CommunityContacts /> },
    { path: "/library/localnonprofit", element: <LocalNonProfitCoalition /> },
    { path: "/opportunities", element: <Opportunities /> },
    { path: "/opportunities/jobopportunities", element: <JobOpportunities /> },
    {
      path: "/opportunities/volunteeropportunities",
      element: <VolunteerOpportunities />,
    },
    { path: "/mylocalcommunity", element: <DrcCenter /> },
    { path: "/mylocalcommunity/drccenter", element: <DrcCenter /> },
    {
      path: "/mylocalcommunity/communitycontacts",
      element: <CommunityContacts />,
    },
    {
      path: "/mylocalcommunity/localnonprofit",
      element: <LocalNonProfitCoalition />,
    },
    { path: "/cenmyrecoveryplan", element: <MyRecoveryPlan /> },
    { path: "/citizenprofile", element: <CitizenProfile /> },
    {
      path: "/lockbox",
      element: <Lockbox onNavigateBack={handleNavigateBack} />,
    },
    { path: "/citmyrecoveryplan/disasters", element: <Disasters /> },
    { path: "/communication", element: <Communication /> },
    { path: "/calendar", element: <Calendar /> },
    { path: "/myhistory", element: <MyHistory /> },
    {
      path: "/myrecoveryplan/disasters/immediateresponsesurvey",
      element: <ImmediateResponseSurvey />,
    },
    {
      path: "/myrecoveryplan/disasters/disastermanagement",
      element: <DisasterManagement />,
    },
    {
      path: "/myrecoveryplan/disasters/assistancelog",
      element: <AssistanceLog />,
    },
    {
      path: "/myrecoveryplan/disasters/recoveryplan",
      element: <RecoveryPlanDisasters />,
    },
    { path: "/myrecoveryplan/disasters/journals", element: <Journals /> },
    {
      path: "/myrecoveryplan/disasters/contactsdisaster",
      element: <ContactsDisaster />,
    },
    {
      path: "/library/quickInfo",
      element: <QuickInfo />,
    },
    {
      path: "/library/prepareness",
      element: <Preparedness />,
    },
    {
      path: "/library/response",
      element: <Response />,
    },
    {
      path: "/library/recovery",
      element: <Recovery />,
    },
  ];

  // Change to center routes
  const centerAuthenticatedRoutes = [
    { path: "/register2", element: <Register2 /> },
    { path: "/home", element: <Home /> },
    { path: "/members", element: <Members /> },
    { path: "/disasterdeclaration", element: <DisasterDeclaration /> },
    { path: "/disasterdetails/:id", element: <DisasterDetail /> },
    { path: "/map", element: <Map /> },
    { path: "/library", element: <Library /> },
    { path: "/partnerships", element: <Partnerships /> },
    { path: "/library/certificationvideos", element: <CertificationVideos /> },
    { path: "/library/communitycontacts", element: <CommunityContacts /> },
    { path: "/library/localnonprofit", element: <LocalNonProfitCoalition /> },
    { path: "/database/citizensearch", element: <CitizenSearch /> },
    { path: "/database/contacts", element: <Contacts /> },
    {
      path: "/database/localnonprofitsearch",
      element: <LocalNonProfitSearch />,
    },
    {
      path: "/database/disasterrecoverysearch",
      element: <DisasterRecoverySearch />,
    },
    {
      path: "/centerprofile",
      element: <CommunityProfile />,
    },
    {
      path: "/cenmyrecoveryplan/contacts",
      element: <Contacts />,
    },
    {
      path: "/cenmyrecoveryplan/emergencydocuments",
      element: <EmergencyMgtDocuments />,
    },
    {
      path: "/cenmyrecoveryplan/demographics",
      element: <Demographics />,
    },
    {
      path: "/cenmyrecoveryplan/localnonprofit",
      element: <CenterLocalNonProfit />,
    },
    {
      path: "/cenmyrecoveryplan/disasterrecovery",
      element: <CenterDisasterRecovery />,
    },
    { path: "/casemanagement", element: <CaseManagement /> },
    { path: "/granttracker", element: <GrantTracker /> },
    { path: "/communication", element: <Communication /> },
    { path: "/reportgenerator", element: <ReportGenerator /> },
    { path: "/calendar", element: <Calendar /> },
    { path: "/settings", element: <Settings /> },
    {
      path: "/centerassistancelog",
      element: <CenterAssistanceLog />,
    },
    {
      path: "/casemanagement/disastermanagement",
      element: <CenterDisasters />,
    },
  ];

  // Change to partner routes
  let partnerAuthenticatedRoutes = [
    { path: "/register2", element: <Register2 /> },
    { path: "/home", element: <Home /> },
    { path: "/map", element: <Map /> },
    { path: "/library", element: <Library /> },
    { path: "/library/certificationvideos", element: <CertificationVideos /> },
    { path: "/library/communitycontacts", element: <CommunityContacts /> },
    { path: "/library/localnonprofit", element: <LocalNonProfitCoalition /> },
    { path: "/partneropportunities", element: <PartnerOpportunities /> },
    {
      path: "/partneropportunities/partnerjobopportunities",
      element: <PartnerJobOpportunities />,
    },
    {
      path: "/partneropportunities/partnervolunteeropportunities",
      element: <PartnerVolunteerOpportunities />,
    },
    {
      path: "/centerpartnerships",
      element: <MyCenterPartnerships />,
    },
    { path: "/partnerprofile", element: <PartnerProfile /> },
    {
      path: "/centerpartnerships/drccenterpartnerships",
      element: <DRCCenterPartnerships />,
    },
    {
      path: "/partnerassistancelog",
      element: <PartnerAssistanceLog />,
    },
    {
      path: "/partnerlockbox",
      element: <PartnerLockBox onNavigateBack={handleNavigateBack} />,
    },
    {
      path: "/communication",
      element: <Communication />,
    },
    {
      path: "/citizensearch",
      element: <PartnerCitizenSearch />,
    },
    {
      path: "/partnergranttracker",
      element: <PartnerGrantTracker />,
    },
    {
      path: "/calendar",
      element: <Calendar />,
    },
    {
      path: "/partnerhistory",
      element: <PartnerHistory />,
    },
    {
      path: "/partnersettings",
      element: <PartnerSettings />,
    },
  ];

  if (role === "partner") {
    console.log("role", role);
    const storedUserData = sessionStorage.getItem("partner");
    const storedUser = storedUserData ? JSON.parse(storedUserData) : null; // Check for null
    if (storedUser) {
      // Use storedUser here
      partnerAuthenticatedRoutes.push({
        path: "/employees",
        element: (
          <Employees
            data={{
              org: storedUser,
              orgId: storedUser?.id,
              orgType: "partner",
            }}
          />
        ),
      });
    }
  } else if (role === "center") {
    const storedUserData = sessionStorage.getItem("center");
    const storedUser = storedUserData ? JSON.parse(storedUserData) : null; // Check for null
    if (storedUser) {
      centerAuthenticatedRoutes.push({
        path: "/employees",
        element: (
          <Employees
            data={{ org: storedUser, orgId: storedUser?.id, orgType: "center" }}
          />
        ),
      });
    }
  }

  // Change to admin routes
  const adminAuthenticatedRoutes = [
    { path: "/register2", element: <Register2 /> },
    { path: "/center", element: <Center /> },
    { path: "/npcpartner", element: <NPCPartner /> },
    { path: "/drpartner", element: <DRPartner /> },
    { path: "/citizen", element: <Citizen /> },
  ];

  // Uncomment this to logout.
  //sessionStorage.clear();

  const { isAuthenticated } = useAuth();
  const location = useLocation();
  const isVerify = location.pathname == "/register2";

  let roleBasedRoutes = [];
  //console.log(isAuthenticated);

  switch (role) {
    case "admin":
      roleBasedRoutes = adminAuthenticatedRoutes;
      break;
    case "center":
      roleBasedRoutes = centerAuthenticatedRoutes;
      break;
    case "partner":
      roleBasedRoutes = partnerAuthenticatedRoutes;
      break;
    default:
      roleBasedRoutes = citizenAuthenticatedRoutes;
      break;
  }

  return (
    <div>
      {isAuthenticated ? (
        <div className="h-full w-full">
          {isVerify ? (
            <Routes>
              <Route path="/register2" element={<Register2 />} />
            </Routes>
          ) : (
            <div className="flex h-full w-full">
              <LeftNavPane className="fixed z-10 h-full w-64 flex-shrink-0" />
              <div className="ml-64 flex flex-1 overflow-hidden">
                <main className="flex-1 overflow-x-auto overflow-y-auto">
                  <Navbar className="z-20" />
                  <div className="flex w-full flex-col">
                    <Routes>
                      {roleBasedRoutes.map((route) => (
                        <Route
                          key={route.path}
                          path={route.path}
                          element={route.element}
                        />
                      ))}
                      <Route
                        path="/citmyrecoveryplan/disasters"
                        element={<Disasters />}
                      />
                    </Routes>
                  </div>
                </main>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div>
          <Routes>
            {publicRoutes.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                element={route.element}
              />
            ))}
          </Routes>
        </div>
      )}
    </div>
  );
};

const App = () => {
  return (
    <AuthProvider>
      <GeneralProvider>
        <DisasterProvider>
          <Router>
            <AppContent />
          </Router>
        </DisasterProvider>
      </GeneralProvider>
    </AuthProvider>
  );
};
export default App;
