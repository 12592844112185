import { registerLicense } from "@syncfusion/ej2-base";
import React, { useState } from "react";
import { Inject } from "@syncfusion/ej2-react-grids";
import {
  ChartComponent,
  SeriesCollectionDirective,
  SeriesDirective,
  ColumnSeries,
  Category,
  Legend,
  DataLabel,
  Tooltip,
} from "@syncfusion/ej2-react-charts";

// Syncfusion license key
registerLicense(
  "Ngo9BigBOggjHTQxAR8/V1NBaF5cXmZCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWXxceXRcQmZdV0R/XUM="
);

const HomeDamageGraph = ({
  homeDamageData = {
    noDamage: 0,
    slightDamage: 0,
    limitedDamage: 0,
    severeDamage: 0,
    totalDestruction: 0,
  },
}) => {
  const chartColorPalette = [
    "#EF4444",
    "#F59E0B",
    "#3B82F6",
    "#10B981",
    "#F97316",
    "#8B5CF6",
  ];

  const ChartCard = ({ title, children }) => (
    <div className="min-w-[350px] max-w-full flex-1 rounded-md border border-solid border-gainsboro-200 bg-white p-5 shadow-lg">
      <h2 className="mb-4 flex items-center justify-between text-sm font-bold text-blue-500">
        {title}
        <span className="text-gray-400">⋮</span>
      </h2>
      {children}
    </div>
  );
  return (
    <div className="w-full">
      <ChartCard title="Home Damage">
        <ChartComponent
          id="home-damage"
          primaryXAxis={{ valueType: "Category" }}
          primaryYAxis={{ minimum: 0, maximum: 70, interval: 10 }}
          height="300px"
          style={{ zIndex: 0 }}
        >
          <Inject
            services={[ColumnSeries, Category, Legend, DataLabel, Tooltip]}
          />
          <SeriesCollectionDirective>
            <SeriesDirective
              dataSource={[
                { x: "No Damage", y: homeDamageData.noDamage },
                { x: "Slight Damage", y: homeDamageData.slightDamage },
                {
                  x: "Limited Damage",
                  y: homeDamageData.limitedDamage,
                },
                { x: "Severe Damage", y: homeDamageData.severeDamage },
                {
                  x: "Total Destruction",
                  y: homeDamageData.totalDestruction,
                },
              ]}
              xName="x"
              yName="y"
              type="Column"
              palettes={chartColorPalette}
            />
          </SeriesCollectionDirective>
        </ChartComponent>
      </ChartCard>
    </div>
  );
};
export default HomeDamageGraph;
